import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {perferred} from '../domain/perferred';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const getUrl = '/api/perferredservices';

const prefgetURL ='/api/schedulepreferredservices';

@Injectable()
export class perferredservice {
    
     baseUrl : any;
     constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
     }

     getAllPerferredService(): Observable<any> {
         return this.http.get<perferred[]>(this.baseUrl+getUrl);  
     }
     
     getPerferredService(perSerId:any): Observable<any> {
          return this.http.get(this.baseUrl+getUrl+'/'+ perSerId);
     } 
     
     getSchedulePerferredService(perSerId:any): Observable<any> {
        return this.http.get(this.baseUrl+prefgetURL+'/'+ perSerId);
     }
     
     update(data:any): Observable<any> {
          return this.http.put(this.baseUrl+prefgetURL, data);
     }
}