import { ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {Item, Period, Section, Events, NgxTimeSchedulerService} from 'ngx-time-scheduler';
import * as moment from 'moment';
import { Route } from '@angular/compiler/src/core';
import { Router, UrlSegment } from '@angular/router';
import { eventservice } from '../../service/eventservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../domain/user';
import { scheduleevent } from '../../domain/scheduleevent';
import { hubs } from '../../domain/hubs';
import { hubservice } from '../../service/hubservice';
import { Validators,FormBuilder} from '@angular/forms';
import { VisitService } from '../../service/visitservice';


@Component({
  selector: 'app-userevents',
  templateUrl: './userevents.component.html',
  styleUrls: ['./userevents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsereventsComponent implements OnInit {

  events: Events = new Events();
  periods: Period[];
  sections: Section[]=[];
  users:any[]=[];
  sec: any;
  items: Item[];
  officers: User[];
  officerstemp: User[];
  
  userevents: scheduleevent[];
  usereventstemp: scheduleevent[];

  hubs : hubs[];
  hubstemp : hubs [];
  progressSpin: boolean=false;
  selectedEvent: any;
  eventdisplayModal: boolean;
  _tempUserData: any = [];
  windowScrolled: boolean;
  progressbar: boolean;
  dataFrom: number=0;
  dataTo: number=18;
  _tempUserItems: any=[];
  currentPeriod: Period;
  event_displayModal: boolean;

  constructor(private visitservice:VisitService,private service: NgxTimeSchedulerService,public fb: FormBuilder,private hubservice:hubservice,private scheduleeventservice:scheduleeventservice,private ref:ChangeDetectorRef,
    private router: Router,private usereventsservice: eventservice,private userdetailsservice :UserDetailsService) {
      ref.detach();
      setInterval(() => { this.ref.detectChanges();},1000);

      
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 1) && !this.windowScrolled) {
      
      // this.progressbar=true;
      this.pushdatatoCal();
      this.service.refresh();
    }
  }
  searchhubform = this.fb.group({
    shub: ['REG0027', []],
    sofficer: ['', []],
  });

  ngOnInit() {
    this.getAllItems();
    this.gethubs();
    this.items = [];
    this.sections = [];
    // get all users and there events
    this.searchhub();

    this.events.SectionClickEvent = (section) => { 
      var secId=section.id.toString().substring(1);
      localStorage.setItem("USERID","USR"+secId);
      this.router.navigate(['/usercalendar']);
      
         
      
    };
    // onclick event
    this.events.ItemClicked = (item) => {
      var itemId=item.id.toString().substring(1);
      var eventID="EVT"+itemId;
      this.visitservice.getEventsById(eventID).subscribe(sedata=>{
        if(sedata){
          this.eventdisplayModal=true;
          this.selectedEvent = sedata;
        }else{
          this.selectedEvent = item;
          this.event_displayModal=true;
        }
      })
        
    };
    
    this.periods = [
      // {
      //   name: '3 days',
      //   timeFramePeriod: 1440,
      //   timeFrameOverall: (60 * 24 * 3),
      //   timeFrameHeaders: [
      //     'Do MMM',
      //     'ddd'
      //   ],
      //   classes: 'period-3day'
      // }, 
      {
        name: '1 week',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 7,
        timeFramePeriod: 1440,
      }, {
        name: '2 week',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 14,
        timeFramePeriod: 1440,
      }
      ,{
        name: '1 Month',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 30,
        timeFramePeriod: 1440,
      }
    ];
    this.currentPeriod = this.periods[0];
    console.log(this._tempUserItems);
    

  }
  searchhub(){
    this.sections = [];
    this.items=[];
    // if(this.searchhubform.get("shub").value=='all'){
    //   this.getdata();
    //   return;
    // }
    this.windowScrolled=true;
    if (!this.searchhubform.valid) {
      console.log(this.searchhubform.valid);
      return false;
    } else {
      let hub = this.searchhubform.get("shub").value;
      
      this.userdetailsservice.getActiveUsersByRegions(hub).subscribe(userData=>{
      
        if(userData!=null){
          let mylist ;
          mylist = userData.filter(el=>el.status=='Active');
          mylist.forEach(e=>{
  
            this.service.sectionPush({name:e.userFirstName+' '+e.userLastName+'-'+e.region,id: Number(e.userId.replace("USR", "1"))});
            this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
              if(ssedata!=null){
                this.userevents = ssedata;    
                let d = new Date();
            //    console.log("this.userevents size before"+ this.userevents.length)   
                this.userevents = this.userevents.filter(el=>(el.endDate>(d.setMonth(d.getMonth()-6))));         
             //   console.log("this.userevents size"+ this.userevents.length)   
                this.userevents.forEach(eledata=>{
                  if (eledata.startDate!=null){
                    if(eledata.title=="V"){
                      eledata.title="AR"
                    }
                    var startDate = new Date(eledata.startDate);
                    var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
                    var endDate = new Date(eledata.endDate);
                    var endOfDayDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate(),23,59,59);
                    var bgcolor=this.getbgcolor(eledata.eventType);
                   
                    this.service.itemPush({id:Number(eledata.eventId.replace("EVT", "1")),sectionID:Number(e.userId.replace("USR", "1")),name:eledata.title,start: moment(startOfDayDate, 'YYYY-MM-DD HH:mm'),end:moment(endOfDayDate, 'YYYY-MM-DD HH:mm'),classes: bgcolor});
                    
                  }
                }); 
              }
            });
          });
        }
      });
      this.refreshData();
      
    }
  }
  refreshData(){
    ;
    setTimeout(() => {
      this.service.refresh();
      window.dispatchEvent(new Event('resize'));
  }, 250);
 
  }
  popSection() {
    this.service.sectionPop();
  }
  getAllItems(){
    this.userdetailsservice.getActiveUsersByRegion().subscribe(userData=>{
      if(userData!=null){
        let mylist ;
        mylist = userData.filter(el=>el.status=='Active');
        this._tempUserData=mylist;
      }
    })
  }
  
  pushdatatoCal(){
    this.dataFrom = this.dataTo;
    this.dataTo = this.dataTo + 20;
    let mylist;
    mylist = this._tempUserData.filter(el=>el.status=='Active').slice(this.dataFrom,this.dataTo);
    if(this.dataFrom>this._tempUserData.length){
      this.windowScrolled =true;
    }
    mylist.forEach((e,i)=>{
      console.log(e.userId);
      this.service.sectionPush({name:e.userFirstName+' '+e.userLastName+'-'+e.region,id: Number(e.userId.replace("USR", "1"))});
      this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
        if(ssedata!=null){
          this.userevents = ssedata;                
          this.userevents.forEach(eledata=>{
            if (eledata.startDate!=null){
              if(eledata.title=="V"){
                eledata.title="AR"
              }
              var startDate = new Date(eledata.startDate);
              var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
              var endDate = new Date(eledata.endDate);
              var endOfDayDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate(),23,59,59);
              var bgcolor=this.getbgcolor(eledata.eventType);
             
              this.service.itemPush({id:Number(eledata.eventId.replace("EVT", "1")),sectionID:Number(e.userId.replace("USR", "1")),name:eledata.title,start: moment(startOfDayDate, 'YYYY-MM-DD HH:mm'),end:moment(endOfDayDate, 'YYYY-MM-DD HH:mm'),classes: bgcolor});
              this.service.refresh();
            }
          }); 
        }
      });
      
    })
  }
  getdata(){
    this.windowScrolled=false;
    this.userdetailsservice.getActiveUsersByRegion().subscribe(userData=>{
      
      if(userData!=null){
        let mylist ;
        mylist = userData.filter(el=>el.status=='Active');//18
        this._tempUserData.slice(this.dataFrom,this.dataTo).forEach(e=>{

          this.sections.push({name:e.userFirstName+' '+e.userLastName+'-'+e.region,id: Number(e.userId.replace("USR", "1"))});                  
          this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
            if(ssedata!=null){
              this.userevents = ssedata;                
              this.userevents.forEach(eledata=>{
                if (eledata.startDate!=null){
                  if(eledata.title=="V"){
                    eledata.title="AR"
                  }
                  var startDate = new Date(eledata.startDate);
                  var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
                  var endDate = new Date(eledata.endDate);
                  var endOfDayDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate(),23,59,59);
                  var bgcolor=this.getbgcolor(eledata.eventType);
                  console.log("eledata.daysofweek"+eledata.daysofweek)
                  if(eledata.daysofweek!=null)
                  {
                    const dates = this.getDates(new Date(eledata.startDateRecur), new Date(eledata.endDateRecur))
                    dates.forEach(function (date) {
                    console.log("date..."+date)
                  })

                  }
                  else
                  {

                  this.items.push({id:Number(eledata.eventId.replace("EVT", "1")),sectionID:Number(e.userId.replace("USR", "1")),name:eledata.title,start: moment(startOfDayDate, 'YYYY-MM-DD HH:mm'),end:moment(endOfDayDate, 'YYYY-MM-DD HH:mm'),classes: bgcolor});
                  this.service.refresh();
                  }
                }
              }); 
            }
          });
        });       
      }
    });
    this.service.refresh();
        setTimeout( function() {
          window.dispatchEvent(new Event('resize'))
      }, 1000)
  }


  getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }
  

  gethubs(){
    this.hubservice.getHubs().subscribe(hubData=>{
      if(hubData!=null){
        this.hubstemp = hubData;  
        // this.hubs = [{hubId: 'all', hubName: 'All', status: 'Active'}];
        this.hubs=[];
        this.hubstemp.forEach(eledata=>{
          // show only Status=='Active' events 
          //console.log(eledata.hubName);
          if(eledata.status =='Active'){
            this.hubs.push(eledata);            
          }
        }); 
      }
    });
  }
  getbgcolor(eventType: any) {
    /*
      leave and Flex #F03030
      Training and Conference - #3361FF
      Team meeting and Admin Day - #A569BD
      Report Writing and Desktop Audit - #ABEBC6
      Compliance and Investigation - #FCE32E
      Pre,Post approval and change of ownership - #C10AC3
      Travel - #F06630 
      Assessment and visit -  #1AA11A
      Secondment Visit - #EBEEEE
      Multiple -#9B9E9C
      others - #26C8F3
      School holidays watermark colorMultiple events - #D6F9F1
    */
    var bgcolor = '';
    if (eventType == 'L' || eventType == 'F') {
      bgcolor = 'time-sch-item-red';
    } else if (eventType == 'T' || eventType == 'CF') {
      bgcolor = 'time-sch-item-blue';
    } else if (eventType == 'M' || eventType == 'A') {
      bgcolor = 'time-sch-item-lightpurple';
    } else if (eventType == 'D' || eventType == 'R') {
      bgcolor = 'time-sch-item-lightgreen';
    } else if (eventType == 'COM' || eventType == 'I' || eventType == 'MAT') {
      bgcolor = 'time-sch-item-yellow';
    } else if (eventType == 'PA' || eventType == 'PR' || eventType == 'CofO') {
      bgcolor = 'time-sch-item-purple';
    } else if (eventType == 'H') {
      bgcolor = 'time-sch-item-orange';
    } else if (eventType == 'V') {
      bgcolor = 'time-sch-item-green';
    } else if (eventType == 'ME') {
      bgcolor = 'time-sch-item-gray';
    } else if (eventType == 'O') {
      bgcolor = 'time-sch-item-lightblue';
    } else if (eventType == 'SV') {
      bgcolor = 'time-sch-item-lightskyblue';
    } else if (eventType == 'W') {
      bgcolor = 'time-sch-item-lightgray';
    }else if(eventType == 'termHoliday'){
      bgcolor = 'time-sch-item-gray';
    }else if(eventType == 'publicHoliday')
    { 
      bgcolor = 'time-sch-item-lightred';
    }else if(eventType == 'LP'){  
      bgcolor = 'time-sch-item-babypink';
    }else {
      bgcolor = '';
    }

    return bgcolor;
  }
  addItem() {
    this.service.itemPush({
      id: 522,
      sectionID: 5,
      name: 'Item 4',
      start: moment().startOf('day'),
      end: moment().add(3, 'days').endOf('day'),
      classes: ''
    });
  }

  popItem() {
    this.service.itemPop();
  }

  removeItem() {
    this.service.itemRemove(4);
  }


}