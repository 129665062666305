import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {termholidays} from '../domain/termholidays';
import {publicholidays} from '../domain/publicholidays';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getTermHolidayUrl = '/api/scheduletermholidays';
const getPublicHolidayUrl = '/api/schedulepublicholidays';

@Injectable()
export class holidaysservices {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getTermHolidays() : Observable<any>{
         return this.http.get<termholidays[]>(this.baseUrl+getTermHolidayUrl);  
    }
 
    getPublicHolidays() : Observable<any> {
        return this.http.get<publicholidays[]>(this.baseUrl+getPublicHolidayUrl);  
   }
   createPublicHoliDays(data:any): Observable<any> {
     return this.http.post(this.baseUrl+getPublicHolidayUrl, data);
 }
 editPublicHoliDays(data:any): Observable<any> {
    return this.http.put(this.baseUrl+getPublicHolidayUrl, data);
}

deletePublicHoliDays(id:any): Observable<any> {
    return this.http.delete(this.baseUrl+getPublicHolidayUrl+"/"+id);
}

deleteTermHoliDays(id:any): Observable<any> {
    return this.http.delete(this.baseUrl+getTermHolidayUrl+"/"+id);
}

 createTermHoliDays(data:any): Observable<any> {
     return this.http.post(this.baseUrl+getTermHolidayUrl, data);
 }
 editTermHoliDays(data:any): Observable<any> {
    return this.http.put(this.baseUrl+getTermHolidayUrl, data);
}
}