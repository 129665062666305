import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IAssessmentVisit } from '../domain/assessmentvisit';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/assessment-visits';
const nqaservURL = '/api/asrdownload';
const romdataURL = '/romdata';
const acodataURL = '/acodata';

@Injectable({
  providedIn: 'root'
})
export class AssessmentVisitService {

    baseUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }

  getAll(): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(this.baseUrl+serviceUrl);
  }

  getForServiceASR(serviceId:any,visitId:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ serviceId+'/'+visitId);
  }
 getAllFDCAsr(visitId:any): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(this.baseUrl+serviceUrl+'/fdc/'+visitId);
  }

  getFDCAsrsParents(visitId:any): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(this.baseUrl+serviceUrl+'/allfdc/'+visitId);
  }

  getFinishedAllASR(): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(this.baseUrl+serviceUrl+'/completed');
  }

  getLatestCompletedASR(serviceId:any): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(this.baseUrl+serviceUrl+'/maxcomvisit/'+serviceId);
  }

  getLatestASR(serviceId:any): Observable<any> {
    return this.http.get<IAssessmentVisit[]>(`${this.baseUrl+serviceUrl}/service/${serviceId}/latest`);
  }


  get(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  create(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  createAsr(visitId:any,serviceId:any): Observable<any> {
    return this.http.get(this.baseUrl+nqaservURL+"/"+serviceId+"/"+visitId);
  }
createFDCSubAsr(data:any,premisesId:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl+"/subasr/"+premisesId, data);
  }
  updateNqaQip(data:any,userId:any,nqapass:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl+"/nqaqip/"+userId+"/"+nqapass, data);
  }

  updateNQAVisit(data:any,userId:any,nqapass:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl+"/nqavisit/"+userId+"/"+nqapass, data);
  }
  updatePR(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl+"/peerreview", data);
  }

  updateAssignR(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl+"/reviewer", data);
  }

 
  update(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }

}
