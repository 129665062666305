import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../domain/user';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';
const getUrl = '/api/appuserdetails';

@Injectable()
export class usermaintenanceservice {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getAllUsers() : Observable<any>{
         return this.http.get<User[]>(this.baseUrl+getUrl);  
    }

    createUserMaintenance(data: any): Observable<any> {
        return this.http.post(this.baseUrl+getUrl, data);
      }

      get(id:any): Observable<any> {
        //console.log("!!!!!!!"+id)
        return this.http.get(this.baseUrl+getUrl+'/'+ id);
    }

    update(data:any): Observable<any> {   
      return this.http.put(this.baseUrl+getUrl, data);
  }
 
    
}