import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {common} from '../domain/common';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/schedulecommons';

@Injectable()
export class commonservice {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getAll() : Observable<any> {
         return this.http.get<common[]>(this.baseUrl+getUrl);  
    }

    
}