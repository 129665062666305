import {NgModule,APP_INITIALIZER} from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, HashLocationStrategy, PathLocationStrategy, DatePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {CookieService} from 'ngx-cookie-service';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import { EditorModule } from 'primeng/editor';

import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {GMapModule} from 'primeng/gmap';
// import {NgxTimeSchedulerModule} from 'ngx-time-scheduler';
import {MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
//import { FullCalendarModule } from '@fullcalendar/angular'
//import {DxSchedulerModule} from 'devextreme-angular';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

import {AppCodeModule} from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent} from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {eventservice} from './admin/service/eventservice';
import {NodeService} from './admin/service/nodeservice';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import { SummaryComponent } from './admin/components/summary/summary.component';
import { PreferredservicesComponent } from './admin/components/preferredservices/preferredservices.component';
import { UsereventsComponent } from './admin/components/userevents/userevents.component';
import { UsereventsnewComponent } from './admin/components/usereventsnew/usereventsnew.component';
import { UsercalendarComponent } from './admin/components/usercalendar/usercalendar.component';
import { VisitService } from './admin/service/visitservice';
import { UsermaintenanceComponent } from './admin/components/usermaintenance/usermaintenance.component';
import { PastschedulesComponent } from './admin/components/pastschedules/pastschedules.component';
import { BulkoperationsComponent } from './admin/components/bulkoperations/bulkoperations.component';
import { HolidaysComponent } from './admin/components/holidays/holidays.component';
import { FlexperiodsComponent } from './admin/components/flexperiods/flexperiods.component';
import { TargettypesComponent } from './admin/components/targettypes/targettypes.component';
import { MappingprefservicesComponent } from './admin/components/mappingprefservices/mappingprefservices.component';
import { Oauth2Component } from './oauth2/oauth2.component';
import { AppConfigService } from './admin/service/AppConfigService';
import { LoginDataService } from './admin/service/loginData.service';
import { flexperiodsservice } from './admin/service/flexperiodsservice';
import { usermaintenanceservice } from './admin/service/usermaintenanceservice';
import { pastschedulesservices } from './admin/service/pastschedulesservices';
import { holidaysservices } from './admin/service/holidaysservices';
import { targettypesservice } from './admin/service/targettypesservice';
import { perferredservice } from './admin/service/perferredservice';
import { schedulepreferredservice } from './admin/service/schedulepreferredservice';
import { scheduleeventservice } from './admin/service/scheduleeventservice';
import { usereventsservice } from './admin/service/usereventsservice';
import { HomeComponent } from './admin/components/home/home.component';
import { HubcohomeComponent } from './admin/components/hubcohome/hubcohome.component';
import { hubservice } from './admin/service/hubservice';
import { locationservice } from './admin/service/locationservice';
import { commonservice } from './admin/service/commonservice';
import { HubusereventsComponent } from './admin/components/hubuserevents/hubuserevents.component';
import { TokenInterceptorService } from './admin/service/TokenInterceptorService';
import { UsereventstestComponent } from './admin/components/usereventstest/usereventstest.component';
import { NgxTimeSchedulerModule,DateAdapter,
  MOMENT, } from 'src/app/library/ngx-time-scheduler/src/lib/ngx-time-scheduler.module';
import { adapterFactory } from 'src/app/library/ngx-time-scheduler/src/lib/date-adapters/moment';
import * as moment from 'moment';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import { ScheduleListHubCoComponent } from './admin/components/schedulelisthubco/schedulelisthubco.component';
import { ScheduleListHubCoAdminComponent } from './admin/components/schedulelisthubcoadmin/schedulelisthubcoadmin.component';
import { ServiceProviderService } from './admin/service/serviceprovider.service';
import { LargeProviderComponent } from './admin/components/largeprovider/largeprovider.component';
import { LargeProviderAssignmentsComponent } from './admin/components/largeproviderassignments/largeproviderassignments.component';
import { LocationsComponent } from './admin/components/locations/locations.component';
import { EmailAdminComponent } from './admin/components/emailadmin/emailadmin.component';
import { EmailtemplateService } from './admin/service/emailtemplate.service';

export function momentAdapterFactory(){
  return adapterFactory(moment);
}

export function initConfig(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
  }
@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ConfirmPopupModule,
        ReactiveFormsModule,
        GMapModule,
        NgxTimeSchedulerModule.forRoot({
          provide: DateAdapter,
          useFactory: momentAdapterFactory,
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        AccordionModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        EditorModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        DragDropModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatGridListModule,
        
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        SummaryComponent,
        ScheduleListHubCoComponent,
        ScheduleListHubCoAdminComponent,
        PreferredservicesComponent,
        UsereventsnewComponent,
        UsercalendarComponent,
        UsermaintenanceComponent,
        PastschedulesComponent,
        BulkoperationsComponent,
        HolidaysComponent,
        FlexperiodsComponent,
        EmailAdminComponent,
        LargeProviderComponent,
        LocationsComponent,
        LargeProviderAssignmentsComponent,
        TargettypesComponent,
        MappingprefservicesComponent,        
        Oauth2Component, HomeComponent, HubcohomeComponent, HubusereventsComponent,
    ],
    providers: [
        DatePipe,HttpClientModule,TokenInterceptorService,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        eventservice, NodeService,VisitService, MenuService, EmailtemplateService, AppBreadcrumbService,CookieService,LoginDataService,flexperiodsservice,ServiceProviderService,usermaintenanceservice,
        pastschedulesservices,holidaysservices,hubservice,locationservice,commonservice,targettypesservice,perferredservice,schedulepreferredservice,MessageService,ConfirmationService,scheduleeventservice,usereventsservice,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true,
          },
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: TokenInterceptorService, 
            deps: [AppConfigService],
            multi: true
          }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
