import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {scheduleevent} from '../domain/scheduleevent';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/scheduleserviceevents';                
const getPerSerUrl ='/api/scheduleserviceevents/findbyperserid';
const getUserUrl ='/api/scheduleserviceevents/userid';
const getUserCalUrl ='/api/scheduleserviceevents/calendar';
@Injectable()
export class scheduleeventservice {
    getCurrentPeriod() {
        return this.http.get(this.baseUrl+"/api/getcurrentflexeperiodaspertodaysdate");
    }
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getschedulepreferredservices(): Observable<any> {
         return this.http.get<scheduleevent[]>(this.baseUrl+getUrl);  
    }

    getscheduleforperiodId(flexPeriod:string): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUrl+"/flexperiod/"+flexPeriod);  
   }
    

    updatescheduleForFlexPeriod(flexPeriod:string): Observable<any> {
        return this.http.put(this.baseUrl+getUrl+"/flexperiod/"+flexPeriod,null);  
   }
    
    get(id:any): Observable<any> {
        //console.log("!!!!!!!"+id)
        return this.http.get(this.baseUrl+getUrl+'/'+ id);
    }

    getUserEventsById(userId:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUserUrl+'/'+ userId);  
    }

    getUserEventsByIdFirstOrSecond(userId:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUserUrl+'/useridFirstOrSecond/'+ userId);  
    }

    getUserEventsByHub(hubname:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUrl+'/hubs/'+ hubname);  
    }

    getUserCalendarById(userId:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUserCalUrl+'/'+ userId);  
    }
    getSSEByPerSerId(perSerId:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getPerSerUrl+'/'+ perSerId);  
    }
    getSSEByvisitId(visitId:any): Observable<any> {
        return this.http.get<scheduleevent[]>(this.baseUrl+getUrl+'/vst/'+ visitId);  
    }
    create(data:any): Observable<any> {
        return this.http.post(this.baseUrl+getUrl, data);
    }
    
    sendemail(data:any): Observable<any> {   
        return this.http.post(this.baseUrl+getUrl+'/schedule/email', data);
    }
    update(data:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl, data);
    }
    updateEventsOnSchedule(data:any): Observable<any> {   
        return this.http.put(this.baseUrl+"/api/updateEventsOnSchedule", data);
    }
    updateOfficer(data:any,officerId,officer_type): Observable<any> {   
       
      /*  if(officerId!=null && officerId!=undefined){
            return this.update(data);
        }*/
        return this.http.put(this.baseUrl+"/api/scheduleserviceevent/"+officerId+'/'+officer_type, data);
    }

    updateserviceeventswithvdate(id,data:any): Observable<any> {   
        return this.http.put(this.baseUrl+"/api/updateserviceeventswithvdate/"+id, data);
    }


    updateByEventId(id:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl+"/"+id,null);
    }

    updateByPerserId(id:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl+"/perserId/"+id,null);
    }

    getEventId(sId:any,vId): Observable<any> {
        return this.http.get(this.baseUrl+getUrl+'/'+ sId+"/"+vId);
    }
    
    delete(id:any): Observable<any> {
        return this.http.delete(this.baseUrl+getUrl+'/'+id);
    }
    sendACOEmailandUpdateStatus(visitId:any,eventId:any): Observable<any> {
        return this.http.get(this.baseUrl+getUrl+'/vst/'+ visitId+"/"+eventId,{responseType:'text'});  
    }
    resendACOEmailandUpdateStatus(visitId:any,eventId:any){
        return this.http.get(this.baseUrl+getUrl+'/resendemail/'+ visitId+"/"+eventId,{responseType:'text'});  
    }

    overlappedFlexPeriod(leaveStartDate:any,leaveEndDate): Observable<any> {
        return this.http.get(this.baseUrl+"/api/leaveoverlappingscheduleperiod"+"/"+leaveStartDate+"/"+leaveEndDate);  
    }

}