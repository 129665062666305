import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../admin/service/AppConfigService';

@Injectable({
  providedIn: 'root'
})
export class SSOLogonService {
  uiurl: any;
  ssourl: any;
  ssosecret: any;
  verifier:any;
  clientId:any;
  constructor(private http: HttpClient, private config: AppConfigService) {

    this.uiurl = this.config.getConfig().uiurl;
    this.ssosecret = this.config.getConfig().ssosecret;
    this.ssourl = this.config.getConfig().ssoURL;
    this.clientId = this.config.getConfig().clientId;
  }

  cookieValue: any
  getLogin() {
    localStorage.clear();

    function getNonce(length = 10) {
      const possibleCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
      if (length < 6) {
        length = 10;
      }
      let nonce = '';
      for (let i = 0; i < length; i++) {
        // @ts-ignore
        nonce += possibleCharacters.charAt(Math.floor(Math.random() * (64 - 0)) + 0);
      }
      return nonce;
    }
   // const clientId = 'eschedule';
   // const scope = 'openid username roles firstName lastName';
   //const clientId = '6317501a-9651-453a-b336-d41b6612734a';
   const clientId = this.clientId;
   const scope = 'openid+profile+email';
    const responseType = 'code';
   // const redirectUri = this.uiurl + '/oauth2/redirect';
   // const oauthServerUrl = this.ssourl + "/oauth2/authorize";
   const redirectUri = this.uiurl + '/secure/aad';
   const oauthServerUrl = this.ssourl + "/authorize";
   const verifier = btoa(''+this.strRandom(32));
   console.log('verifier'+verifier)
  
   localStorage.setItem('verifier',verifier);
    const nonce = getNonce();
   // const url = `${oauthServerUrl}?response_type=${responseType}&client_id=${clientId}&scope=${scope}&nonce=${nonce}&redirect_uri=${redirectUri}`;
   const url = `${ oauthServerUrl }?response_type=${ responseType }&client_id=${ clientId }&code_challenge_method=plain&code_challenge=${ verifier }&scope=${ scope }&nonce=${ nonce }&redirect_uri=${ redirectUri }`; 
   console.log("this is return url =" + url);
    window.location.href = url;
  }


  getLogout() {
    localStorage.clear();
   // window.location.href = this.ssourl + '/logout?goto=' + this.uiurl + '/oauth2/logout'; //TODO:
   console.log(this.uiurl);
   let login_hint = localStorage.getItem("login_hint");
  // alert("called"+login_hint)

      // const responseType = { response: new } responseType = 'json';
  this.http.get<any>(this.ssourl+'/logout?logout_hint='+login_hint).subscribe(res=>{
    console.log("res"+res)
  },err=>{
    console.log("err"+JSON.stringify(err));
  })

  }

  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


  getLoggedSSOUserAttributes(cookieValue: string): Observable<JSON> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-API-Version': 'resource=3.1, protocol=1.0',
        'iPlanetDirectoryPro': cookieValue,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(this.ssourl + '/identity/attributes', null, httpOptions);

  }

  getOAuth2AccessToken(code: string): Observable<JSON> {
    this.verifier = localStorage.getItem('verifier')
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
      })
    };
console.log("this.ssosecret"+this.ssosecret);
    const body = new HttpParams()
      .set('code', code)
      .set('grant_type', 'authorization_code')
     // .set('client_id', 'eschedule')
     .set('client_id', this.clientId)
      // .set('client_secret', '0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0')
     // .set('client_secret', this.ssosecret)
      //.set('redirect_uri', this.uiurl + '/oauth2/redirect');
      .set('code_verifier', this.verifier)
      .set('redirect_uri', this.uiurl + '/secure/aad');
    return this.http.post<any>(this.ssourl + '/token', body.toString(), httpOptions);
   // return this.http.post<any>(this.ssourl + '/oauth2/access_token', body.toString(), httpOptions);
  }

}