import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';
import { ServiceProviderService } from '../../service/serviceprovider.service';
import { serviceprovider } from '../../domain/serviceprovider';
import { locations } from '../../domain/locations';
import { locationservice } from '../../service/locationservice';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  locationsList: locations[];
  largeProviderModal: boolean;
  isSubmitted: boolean;
  isEditSubmitted: boolean;
  addLocationModal: boolean;
  selected_Provider: serviceprovider;
  add_Location_Submitted: boolean;
  LargeProvider_type: any;
  progressbar:boolean=false;


  targetMessages: { severity: string; summary: string; detail: string; }[];
   constructor(private locationservice : locationservice,public fb: FormBuilder, private messageService:MessageService
    , private confirmationService: ConfirmationService) { 
   
   }


   registerForm: FormGroup;
   location: locations;
  addllocationsform = this.fb.group({
    fllocationName: ['', [Validators.required]],
    fllatitude: ['', [Validators.required]],
    fllongitude: ['', [Validators.required]],


  });   

  deleteLocation(location: locations)
  {
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete this Location?',
          accept: () => {
  
              location.updated_by = 'System';
              location.updated_date =  new Date();
              this.locationservice.delete(location).subscribe(data=>{
              
                   this.ngOnInit();
                   this.messageService.add({severity:'success', summary: 'Success', detail: 'Location deleted successfully', life: 3000});
                   },err=>{      
                       console.log(err);        
                       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Location delete failed' });
               
                   })
  
  
          }
      });
  }


  ngOnInit(): void {

    
    this.progressbar=true;
      this.locationservice.getLocations().subscribe(locationsdata =>{      
        this.locationsList = locationsdata;  
        this.progressbar=false;
      },err=>{
        console.log(err);
        this.progressbar=false;
 
      });

    this.registerForm = this.fb.group({

      /* for edit */
      addlocationgroup : this.fb.group({
        fllocationName:['', [Validators.required]],
        fllatitude:['', [Validators.required]],

        fllongitude:['', [Validators.required]],

      //  ftarget_status:['', [Validators.required]],
      }),
      
    });
  }
  get locations() { return (<FormGroup>this.registerForm.get('addlocationgroup')).controls; }


 
 



  addLocations(){

    
    this.isSubmitted = true;
    if (!this.addllocationsform.valid) {
      return false;
    } else {
     
      this.location = {};
      this.location.locationName = this.addllocationsform.get("fllocationName").value;
      this.location.latitude = this.addllocationsform.get("fllatitude").value;
      this.location.longitude = this.addllocationsform.get("fllongitude").value;
      this.location.created_by='System';
      this.location.created_date= new Date();

      this.locationservice.createLocation(this.location).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Location Created' });
        this.ngOnInit();
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Location failed.' });
      });
      this.addLocationModal = false;
    }
   
    
  }

  get addloc_valid() { return this.addllocationsform.controls; };


  selectedLocation:locations;
  spediting:boolean = false;
  onRowEditLOCInit(location: locations){
    this.selectedLocation = {...location};
    this.spediting = true;
}
onRowEditLOCCancel(location: locations, index: number) {
  this.location=this.locationsList.filter(el=>(el.locationId === location.locationId))[0];
  this.locationsList[this.locationsList.indexOf(this.location)] = this.selectedLocation;
  this.spediting = false;
}
onRowEditLOCSave(location: locations) {
  location.updated_by = localStorage.getItem("loginuserid");
  location.updated_date = new Date();
  this.spediting = false;

  this.locationservice.updateLocation(location).subscribe(res=>{           
      console.log(res);  
          
      this.messageService.add({severity:'success', summary: 'Success', detail:'Location updated successfully'});
  },err=>{      
      console.log(err);        
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Location update failed' });
  })
}


showLocationsmodal() {
  this.isSubmitted=false;
  this.addllocationsform.reset();
  this.addLocationModal = true;
}

  
  exportExcel() {
    let exp_data = this.locationsList.map(function(aUser){
      return {LocationName: aUser.locationName,
             Latitude: aUser.latitude,
             Longitude: aUser.longitude,
            };
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(exp_data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "locationsList");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  get fllocationName () {
    return this.addllocationsform.get('fllocationName');
  }

  get fllatitude () {
    return this.addllocationsform.get('fllatitude');
  }

  get fllongitude () {
    return this.addllocationsform.get('fllongitude');
  }
 
 

  get location_valid() { return this.addllocationsform.controls; };

}
