export class Userdomain {
    userId?:string;
    roleId?:string;
    userFirstName?:string;
    userLastName?:string;
    status?:string;
    statusreason?:string;
    intUserId?:string;
    intUserFlag?:string;
    assessorId?:string;
    region?:string;
    doeEmail?:string;
    title?:string;
    nqaUserId?:string;
    mobility?:string;
    senior?:string;
    notes?:string;
    preferredLocation?:string;
    addresslineOne?:string;
    addresslineTwo?:string;
    suburb?:string;
    state?:string;
    postcode?:string;
    createdBy?:string;
    createdDate?:Date;
    updatedBy?:string;
    updatedDate?:Date;
    latitude?:string;
    longitude?:string;
    prefLocationOne?:string;
    prefLocationTwo?:string;
    mobileNo?:string;
    prefLocation2?:string;
    wokingDaysWeek?:string;
    doeUser?:string;
    hub?:string;
    travelOutsideHUB?:string;
    email?:string;
}
