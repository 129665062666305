import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    loginuserrole: any;
    constructor(public app: AppMainComponent) {}

    ngOnInit() {
        this.loginuserrole =localStorage.getItem("loginuserrole");
        // officer login menu or redirect page
        if (this.loginuserrole == 'ACO') {
            this.model = [
                {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/home']},
                {label: 'Large Provider', icon: 'pi pi-fw pi-info-circle', routerLink: ['/largeproviderassignments']},
            ];
        }else if (this.loginuserrole == 'SCHD_ACO_READONLY'){
            this.model = [
                {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/home']},
                {label: 'View Only', icon: 'pi pi-fw pi-eye', routerLink: ['/summary']},
                {label: 'Large Provider', icon: 'pi pi-fw pi-info-circle', routerLink: ['/largeproviderassignments']},
            ];
        }
         else if (this.loginuserrole == 'SCHD_ADMIN'){
            // admin menu or redirect page
            this.model = [            
                {label: 'Summary', icon: 'pi pi-fw pi-home', routerLink: ['/summary']},
                {label: 'User Events', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/userevents']},
                //{label: 'User Events New', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/usereventsnew']},              
                {label: 'Preferred Services', icon: 'pi pi-fw pi-map-marker', routerLink: ['/preferredservices']},
                {
                    label: 'Admin Menu', icon: 'pi pi-fw pi-list', routerLink: ['/'], 
                    items: [                        
                        {label: 'User Maintenace',  routerLink: ['/usermaintenance']},
                        {label: 'Past Schedules',  routerLink: ['/pastschedules']},
                        {label: 'Bulk Operations',  routerLink: ['/bulkoperations']},
                        {label: 'Holidays',  routerLink: ['/holidays']},
                        {label: 'Locations',  routerLink: ['/locations']},
                        {label: 'Flex Periods',  routerLink: ['/flexperiods']},
                        {label: 'Target Types',  routerLink: ['/targettypes']},
                        {label: 'Mapping Preferred Services',  routerLink: ['/mappingprefservices']},
                        {label: 'Large Provider',  routerLink: ['/largeprovider']},
                        {label: 'Email Admin',  routerLink: ['/emailadmin']},



                    ]
                },
                {label: 'Large Provider', icon: 'pi pi-fw pi-info-circle', routerLink: ['/largeproviderassignments']},

              //  {label: 'Proposed schedule', icon: 'pi pi-fw pi-check-square', routerLink: ['/schedulelisthubcoadmin']},     
            ];
        } else if (this.loginuserrole == 'ROM'){
            // Hub co menu or redirect page
            this.model = [
                {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/hubcohome']},
                {label: 'User Events', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/hubuserevents']},
                {label: 'Large Provider', icon: 'pi pi-fw pi-info-circle', routerLink: ['/largeproviderassignments']},
             //   {label: 'Proposed schedule', icon: 'pi pi-fw pi-check-square', routerLink: ['/schedulelisthubco']}
            ];
        }    else if (this.loginuserrole == 'SCHD_ROM_READONLY'){
            // Hub co menu or redirect page
            this.model = [
                {label: 'Home', icon: 'pi pi-fw pi-home', routerLink: ['/hubcohome']},
                {label: 'User Events', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/hubuserevents']},
                {label: 'View Only', icon: 'pi pi-fw pi-eye', routerLink: ['/summary']},
                {label: 'Large Provider', icon: 'pi pi-fw pi-info-circle', routerLink: ['/largeproviderassignments']},
             //   {label: 'Proposed schedule', icon: 'pi pi-fw pi-check-square', routerLink: ['/schedulelisthubco']}
            ];
        } 
    }

    onMenuClick() {
        this.app.menuClick = true;
    }

    refresh(id:any): void {
        if(id==0 || id==1){
            window.location.reload();
        }
        
      }
}
