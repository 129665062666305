import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private config: any;
  constructor(private http: HttpClient) { }
  public loadConfig() {
    return this.http.get('./assets/config/config.json')
    .toPromise()
    .then((config: any) => {
      this.config = config;
     
      if(this.config.env==='dev')
      {
        this.config.ssosecret="password1";
      }
      else if(this.config.env==='test')
      {
        this.config.ssosecret="password1";
      }
      else if(this.config.env==='pre')
      {
        this.config.ssosecret="!qU1ISMSRax2usak$x";
      }
      else if(this.config.env==='prod')
      {
        this.config.ssosecret="lrK05qu266DXNjxUi7qG";
      }
      console.log(this.config);
    
    })
    .catch((err: any) => {
      console.error(err);
    });
  }
  getConfig() {
    return this.config;
  }
}