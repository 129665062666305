import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {hubs} from '../domain/hubs';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/hubs';

@Injectable()
export class hubservice {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getHubs() : Observable<any> {
         return this.http.get<hubs[]>(this.baseUrl+getUrl);  
    }


    getHubNameforId(hubId: string) : Observable<any> {
        return this.http.get<hubs>(this.baseUrl+getUrl+"/"+hubId);  
   }

    
}