import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {pastschedules} from '../domain/pastschedules';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/pastscheduleview';

@Injectable()
export class pastschedulesservices {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getPastSchedules()  : Observable<any>{
         return this.http.get<pastschedules[]>(this.baseUrl+getUrl);  
    }
 
    
}