import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';

import { DatePipe } from '@angular/common';
import { DateUtilService } from '../../service/date-util.service';
import { EmailAdmin } from '../../domain/email-admin';
import { EmailtemplateService } from '../../service/emailtemplate.service';

const datePipe = new DatePipe("en-AU");
@Component({
  selector: 'app-emailadmin',
  templateUrl: './emailadmin.component.html',
  styleUrls: ['./emailadmin.component.scss']
})
export class EmailAdminComponent implements OnInit {
  emailadmin: EmailAdmin[];
  emailadminModal: boolean; 
  isSubmitted: boolean;
  addTemplateModal: boolean;
  ea : EmailAdmin;
  update_email_admin_Modal: boolean;
  selectedemailtemplate: EmailAdmin;
  email_template: any;
  updateemailSubmitted: boolean;
  emailtemplateMessages: { severity: string; summary: string; detail: string; }[];

   constructor(private emailtemplateService: EmailtemplateService,private date_util : DateUtilService,
    public datepipe: DatePipe, private confirmationService: ConfirmationService,
    public fb: FormBuilder, private messageService:MessageService) { 
     
   }
   registerForm: FormGroup;
  addemailtemplateform = this.fb.group({

    femailFunctionName: ['', [Validators.required]],
    femailFrom: ['', [Validators.required]],
    femailTo: ['', [Validators.required]],
    femailSubject: ['', [Validators.required]],
    femailMessage: ['', [Validators.required]],
    femailCC: [''],

    femailBCC: [''],


   
  });

  emailadminbackup: EmailAdmin[];
  ngOnInit(): void {      
    this.emailtemplateService.getForEschedule().subscribe(data =>{
      console.log(data);
      this.emailadmin=data;
      });

      // register all forms groups in formbuilder 
    this.registerForm = this.fb.group({

     

      updateEmail_Template_group : this.fb.group({
        femailFrom:['', [Validators.required]],
        femailTo:['', [Validators.required]],
        femailMessage:['', [Validators.required]],
        femailSubject:['', [Validators.required]],
        femailCC:[''],
        femailBCC:[''],


       
      
      }),

    });
  }
  
  get emailtemplate_valid() { return (<FormGroup>this.registerForm.get('updateEmail_Template_group')).controls; }
  get validAddemailtemplateform() { return this.addemailtemplateform.controls; }




  addEmailTemplate(){
    this.isSubmitted = true;
    if (!this.addemailtemplateform.valid) {
      return false;
    } else {
      this.emailtemplate = {};
      this.emailtemplate.emailNotificationFunction = this.addemailtemplateform.get("femailFunctionName").value;

      this.emailtemplate.emailSubject = this.addemailtemplateform.get("femailSubject").value;
      this.emailtemplate.emailTo = this.addemailtemplateform.get("femailTo").value;

      this.emailtemplate.emailFrom = this.addemailtemplateform.get("femailFrom").value;
      this.emailtemplate.emailCc = this.addemailtemplateform.get("femailCC").value;

      this.emailtemplate.emailBcc = this.addemailtemplateform.get("femailBCC").value;


      this.emailtemplate.emailContent = this.addemailtemplateform.get("femailMessage").value;
      this.emailtemplate.appName = "ESCH";
      
      this.emailtemplateService.create(this.emailtemplate).subscribe(res=>{
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Added Email template period.'});
        this.ngOnInit();
      },err=>{
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Email template addition failed.'});
       
        console.log("-- new target creatation failed--");      
      });
      this.emailadminModal = false;
    }
  }


 

  //added to reset the form after submited
  formReset(){
    this.addemailtemplateform = this.fb.group({
      femailFunctionName:['', [Validators.required]],
      femailFrom:['', [Validators.required]],
      femailTo:['', [Validators.required]],
      femailMessage:['', [Validators.required]],
      femailSubject:['', [Validators.required]],
      femailCC:[''],
      femailBCC:[''],
    });
  }
  showemailadminmodel(){
    this.isSubmitted=false;
    this.emailadminModal = true;
    this.addemailtemplateform.reset();

  }

  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.emailadmin);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "emailtemplates");
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }


  open_email_template_Modal(email){ 
    this.updateemailSubmitted = false;
    this.update_email_admin_Modal = true;
    this.selectedemailtemplate= email;
    this.selectedEmailFunction();
  }

  showflexperiodmodel(){
    this.isSubmitted=false;
    this.addTemplateModal = true;
  }

  selectedEmailFunction(){
 // var flex_Startdate = new Date (this.selectedemailtemplate.perStartDate);
  //var flex_Enddate = new Date (this.selectedFlexPeriods.perEndDate);
  //var fLeaveCutoff = new Date (this.selectedFlexPeriods.leaveCutoffDate);

  this.updateEmail_Template_group.patchValue({
 
  femailFrom: this.selectedemailtemplate.emailFrom,
  femailTo: this.selectedemailtemplate.emailTo,
  femailMessage: this.selectedemailtemplate.emailContent,
  femailSubject: this.selectedemailtemplate.emailSubject,
  femailCC: this.selectedemailtemplate.emailCc,
  femailBCC: this.selectedemailtemplate.emailBcc,
  });
  }
  //updateemailtemplateSubmitted: boolean;
  emailtemplate: EmailAdmin;

  update_EmailTemplate(){
    this.updateemailSubmitted = true;
    if (!this.updateEmail_Template_group.valid) {
      return false;
    }else
    {
      this.emailtemplateService.get(this.selectedemailtemplate.emailFunctId).subscribe(ea=>{
      this.emailtemplate = ea;

      
      this.emailtemplate.emailSubject = this.updateEmail_Template_group.get("femailSubject").value;
      this.emailtemplate.emailTo = this.updateEmail_Template_group.get("femailTo").value;

      this.emailtemplate.emailFrom = this.updateEmail_Template_group.get("femailFrom").value;
      this.emailtemplate.emailCc = this.updateEmail_Template_group.get("femailCC").value;

      this.emailtemplate.emailBcc = this.updateEmail_Template_group.get("femailBCC").value;


      this.emailtemplate.emailContent = this.updateEmail_Template_group.get("femailMessage").value;

       

        this.emailtemplateService.update(this.emailtemplate).subscribe(res=>{
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Template updated for '+this.selectedemailtemplate.emailNotificationFunction});
          console.log("--update success--");  
          this.updateemailSubmitted = false;
          this.update_email_admin_Modal = false;    
          this.ngOnInit();
        },err=>{
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Template update failed for '+this.selectedemailtemplate.emailNotificationFunction});
          console.log("-- updates failed --");
          this.updateemailSubmitted = false;
          this.update_email_admin_Modal = false;
        });        
    });
  }
  }


  /* update */
 
  
  get femailFunctionName() {
    return this.addemailtemplateform.get('femailFunctionName');
  }

  get femailFrom() {
    return this.addemailtemplateform.get('femailFrom');
  }
  get femailTo() {
    return this.addemailtemplateform.get('femailTo');
  }
  get femailMessage() {
    return this.addemailtemplateform.get('femailMessage');
  }
  get femailSubject() {
    return this.addemailtemplateform.get('femailSubject');
  }
  get femailCC() {
    return this.addemailtemplateform.get('femailCC');
  }
  get femailBCC() {
    return this.addemailtemplateform.get('femailBCC');
  }

  get updateEmail_Template_group() {
    return this.registerForm.get('updateEmail_Template_group');
  }

  getCorrectedDate(d: string) {
    return this.date_util.getCorrectedDate(d);
  }



}


  