import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {flexperiods} from '../domain/flexperiods';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/scheduleperiods';
const getactiveUrl = '/api/activescheduleperiods';

@Injectable()
export class flexperiodsservice { 
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getFlexPeriodsByPerName() : Observable<any> {
        return this.http.get<flexperiods[]>(this.baseUrl+getUrl+"/nameasc");  
   }

    getFlexPeriods() : Observable<any> {
         return this.http.get<flexperiods[]>(this.baseUrl+getUrl);  
    }

    getActiveFlexPeriods() : Observable<any> {
        return this.http.get<flexperiods[]>(this.baseUrl+getactiveUrl);  
    }

    getCurrentFlexPeriods() : Observable<any> {
        return this.http.get<flexperiods[]>(this.baseUrl+getUrl+"/currentscheduleperiods");  
   }

    create(data:any): Observable<any> {
        return this.http.post(this.baseUrl+getUrl, data);
    }

    update(data:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl, data);
    }

    get(id:any): Observable<any> {
        //console.log("!!!!!!!"+id)
        return this.http.get(this.baseUrl+getUrl+'/'+ id);
    }

    getMockFlex() {
        return ['2021P1 (04/01/2021-26/03/2021)','2021P2 (29/03/2021-18/06/2021)','2021P3 (21/06/2021-10/09/2021)'];
      }
    
}