import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';
import {flexperiods} from '../../domain/flexperiods';
import { flexperiodsservice } from '../../service/flexperiodsservice';
import { DatePipe } from '@angular/common';
import { DateUtilService } from '../../service/date-util.service';

const datePipe = new DatePipe("en-AU");
@Component({
  selector: 'app-flexperiods',
  templateUrl: './flexperiods.component.html',
  styleUrls: ['./flexperiods.component.scss']
})
export class FlexperiodsComponent implements OnInit {
  flexperiods: flexperiods[];
  flexperiodModal: boolean; 
  isSubmitted: boolean;
  years: any[]; 
  selectedStatus:any=[{name: 'Active', value: 'Active'}] ;
  fperiods: any[];
  startdate: Date;
  enddate: Date;
  fp : flexperiods;
  update_Flex_period_Modal: boolean;
  selectedFlexPeriods: flexperiods;
  Flex_Period: any;
  updateFlexSubmitted: boolean;
  status: any;
  flexMessages: { severity: string; summary: string; detail: string; }[];

   constructor(private flexperiodsservice: flexperiodsservice,private date_util : DateUtilService,
    public datepipe: DatePipe,
    public fb: FormBuilder, private messageService:MessageService) { 
    this.years = [
      {name: '2020', code: '2020'},
      {name: '2021', code: '2021'},
      {name: '2022', code: '2022'},
      {name: '2023', code: '2023'},
      {name: '2024', code: '2024'},
      {name: '2025', code: '2025'},
      {name: '2026', code: '2026'}      
    ];
  
    this.fperiods = [
      {name: 'P1', code: 'P1'},
      {name: 'P2', code: 'P2'},
      {name: 'P3', code: 'P3'},
      {name: 'P4', code: 'P4'},
      {name: 'Summer Schedule', code: 'Summer Schedule'},
      
      
    ];

    this.status = [
      {name: 'Active', value: 'Active'},
      {name: 'Inactive', value: 'Inactive'},
    ];
   }
   registerForm: FormGroup;
  addflexperiodform = this.fb.group({

    fyear: ['2020', [Validators.required]],
    fperiod: ['P1', [Validators.required]],
    fstartdate: ['', [Validators.required]],
    fenddate: ['', [Validators.required]],
    fleavecutoffdate: ['', [Validators.required]],
    fstatus: ['Active', [Validators.required]],
    fdesc: ['', [Validators.required]]
  });

  flexperiodsbackup: flexperiods[];
  ngOnInit(): void {      
      this.flexperiodsservice.getFlexPeriodsByPerName().subscribe(flexdata =>{      
        this.flexperiodsbackup = flexdata;
        this.flexperiods = flexdata.filter(el=>el.status=='Active');

      });

      // register all forms groups in formbuilder 
    this.registerForm = this.fb.group({

      updateperiodgroup : this.fb.group({
        fperiodd: ['', [Validators.required]]
      }),

      updateperNamegroup : this.fb.group({
        fperName: ['', [Validators.required]]
      }),

      updateperDesgroup : this.fb.group({
        fperDes: ['', [Validators.required]]
      }),

      updateperStartgroup : this.fb.group({
        fperStart: ['', [Validators.required]]
      }),

      updateperEndDategroup : this.fb.group({
        fperEndDate: ['', [Validators.required]]
      }),

      updateFlex_period_group : this.fb.group({
        fstart_date:['', [Validators.required]],
        fend_date:['', [Validators.required]],
        fLeaveCutoff: ['', [Validators.required]],
        fdescription:['', [Validators.required]],
        f_Status:['', [Validators.required]],
      }),

    });
  }
  get uName() { return (<FormGroup>this.registerForm.get('updateperNamegroup')).controls; }
  get uDesc() { return (<FormGroup>this.registerForm.get('updateperDesgroup')).controls; }
  get uStartDate() { return (<FormGroup>this.registerForm.get('updateperStartgroup')).controls; }
  get uEndDate() { return (<FormGroup>this.registerForm.get('updateperEndDategroup')).controls; }
  get Flex_period_valid() { return (<FormGroup>this.registerForm.get('updateFlex_period_group')).controls; }
  get validAddFlexperiodform() { return this.addflexperiodform.controls; }

  dateLessThan(fstartdate: Date, fenddate: Date) {
    if (fenddate < fstartdate) {
      return true;
    }
    return false;
  }


  addflexperiod(){
    this.isSubmitted = true;
    if (!this.addflexperiodform.valid) {
      return false;
    } else {
      var re = /00:00:00/;
      // startDate : datePipe.transform(new Date(this.addflexperiodform.get("fleavecutoffdate").value), 'yyyy-MM-dd'),
      this.fp = new flexperiods;
      this.fp.perName = this.addflexperiodform.get("fyear").value+""+this.addflexperiodform.get("fperiod").value; 
      this.fp.perStartDate = datePipe.transform(new Date(this.addflexperiodform.get("fstartdate").value), 'yyyy-MM-dd');
      this.fp.perEndDate = datePipe.transform(new Date(this.addflexperiodform.get("fenddate").value), 'yyyy-MM-dd'); 
      this.fp.leaveCutoffDate = datePipe.transform(new Date(this.addflexperiodform.get("fleavecutoffdate").value), 'yyyy-MM-dd'); 
      this.fp.perDesc = this.addflexperiodform.get("fdesc").value; 
      this.fp.perYear = this.addflexperiodform.get("fyear").value;
      this.fp.period = this.addflexperiodform.get("fperiod").value;
      this.fp.status = this.addflexperiodform.get("fstatus").value;
      this.fp.createdBy = localStorage.getItem("loginuserid");
      if (this.dateLessThan(new Date(this.fp.perStartDate), new Date(this.fp.perEndDate))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      }
      this.flexperiodsservice.create(this.fp).subscribe(res=>{
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Added Flex period.'});
        this.ngOnInit();
      },err=>{
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Flex period addition failed.'});
        this.flexMessages = [
          {severity:'error', summary:'error', detail: 'Flex period addition failed.'}
      ];
        console.log("-- new target creatation failed--");      
      });
      this.flexperiodModal = false;
    }
  }

  //added to reset the form after submited
  formReset(){
    this.addflexperiodform = this.fb.group({
      fyear: ['2020', [Validators.required]],
      fperiod: ['P1', [Validators.required]],
      fstatus: ['Active', [Validators.required]],
    });
  }
  showflexperiodmodel(){
    this.isSubmitted=false;
    this.flexperiodModal = true;
  }

  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.flexperiods);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "flexperiods");
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }


  open_flex_Modal(flexperiods){ 
    this.updateFlexSubmitted = false;
    this.update_Flex_period_Modal = true;
    this.selectedFlexPeriods = flexperiods;
    this.selectedPeriod_Id();
  }

 
selectedPeriod_Id(){
  var flex_Startdate = new Date (this.selectedFlexPeriods.perStartDate);
  var flex_Enddate = new Date (this.selectedFlexPeriods.perEndDate);
  var fLeaveCutoff = new Date (this.selectedFlexPeriods.leaveCutoffDate);
  
  
  this.updateFlex_period_group.patchValue({
  fdescription: this.selectedFlexPeriods.perDesc,
  f_Status: this.selectedFlexPeriods.status,
  fstart_date: flex_Startdate,
  fend_date: flex_Enddate,
  fLeaveCutoff: fLeaveCutoff,
  });
  }

  update_FlexPeriod(){
    this.updateFlexSubmitted = true;
    if (!this.updateFlex_period_group.valid) {
      return false;
    }else
    {
      this.flexperiodsservice.get(this.selectedFlexPeriods.perId).subscribe(perLeavedata=>{
      this.Flex_Period = perLeavedata;
      var re = /00:00:00/;
        //new Date(this.addflexperiodform.get("fstartdate").value.toString().replace(re, "0:01:01"))
        // startDate : datePipe.transform(new Date(this.updateFlex_period_group.get("fLeaveCutoff").value).toLocaleString(), 'yyyy-MM-dd'), 
        this.Flex_Period.perStartDate =datePipe.transform(new Date(this.updateFlex_period_group.get("fstart_date").value), 'yyyy-MM-dd');
        this.Flex_Period.perEndDate =datePipe.transform(new Date(this.updateFlex_period_group.get("fend_date").value), 'yyyy-MM-dd');
        this.Flex_Period.leaveCutoffDate =datePipe.transform(new Date(this.updateFlex_period_group.get("fLeaveCutoff").value), 'yyyy-MM-dd');
        this.Flex_Period.status = this.updateFlex_period_group.get("f_Status").value;
        this.Flex_Period.perDesc = this.updateFlex_period_group.get("fdescription").value;
        this.Flex_Period.updatedBy = localStorage.getItem("loginuserid");
        
        if (this.dateLessThan(new Date(this.Flex_Period.perStartDate), new Date(this.Flex_Period.perEndDate))) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
          return;
        }

        this.flexperiodsservice.update(this.Flex_Period).subscribe(res=>{
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Status updated for '+this.selectedFlexPeriods.perId});
          console.log("--update success--");  
          this.updateFlexSubmitted = false;
          this.update_Flex_period_Modal = false;    
          this.ngOnInit();
        },err=>{
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Status update failed for '+this.selectedFlexPeriods.perId});
          console.log("-- updates failed --");
          this.updateFlexSubmitted = false;
          this.update_Flex_period_Modal = false;
        });        
    });
  }
  }


  /* update */
  get fyear() {
    return this.addflexperiodform.get('fyear');
  }
  get fperiod() {
    return this.addflexperiodform.get('fperiod');
  }
  get fstartdate() {
    return this.addflexperiodform.get('fstartdate');
  }
  get fenddate() {
    return this.addflexperiodform.get('fenddate');
  }
  get fleavecutoffdate() {
    return this.addflexperiodform.get('fleavecutoffdate');
  }
  get fdesc() {
    return this.addflexperiodform.get('fdesc');
  }

  get updateFlex_period_group() {
    return this.registerForm.get('updateFlex_period_group');
  }

  getCorrectedDate(d: string) {
    return this.date_util.getCorrectedDate(d);
  }


  async statusChanged()
  {
    let selectedUsers=[];

    this.flexperiods=[...this.flexperiodsbackup];

   
    console.log(this.selectedStatus.length);

    if(this.selectedStatus.length===1)
    {
      console.log(this.selectedStatus[0].value)
      this.flexperiods = this.flexperiods.filter(e=>e.status===this.selectedStatus[0].value);
    }
    else if(this.selectedStatus.length===2)
    {
      console.log(this.selectedStatus[0].value)
      console.log(this.selectedStatus[1].value)



      this.flexperiods = this.flexperiods.filter(e=>(e.status===this.selectedStatus[0].value  || e.status===this.selectedStatus[1].value));

    }
   
      
  }

}


  