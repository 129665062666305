import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Item, Period, Section, Events } from 'src/app/library/ngx-time-scheduler/src/lib/ngx-time-scheduler.model';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { NgxTimeSchedulerService } from 'src/app/library/ngx-time-scheduler/src/lib/ngx-time-scheduler.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserDetailsService } from '../../service/userDetails.service';
import {scheduleeventservice} from '../../service/scheduleeventservice';
import { scheduleevent } from '../../domain/scheduleevent';
import { Router } from '@angular/router';
import { VisitService } from '../../service/visitservice';
import { hubservice } from '../../service/hubservice';
import { environment } from 'src/environments/environment';
import { DateUtilService } from '../../service/date-util.service';
import { ChangeDetectionStrategy } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MessageService, SelectItem } from 'primeng/api';
import * as xlsx from "xlsx";

@Component({
  selector: 'app-usereventsnew',
  templateUrl: './usereventsnew.component.html',
  styleUrls: ['./usereventsnew.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UsereventsnewComponent implements OnInit {
  // periods: Period[];
  // currentPeriod: Period;
  // end = moment().endOf('day');
  // start = moment().startOf('day');
  // showToday = true;
  // headerFormat = 'Do MMM YYYY';
  // locale = 'en-AU';
  inputDate;
  userevents: scheduleevent[];
  alluserevents: scheduleevent[];
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  eventOutput = '';

  events: Events = new Events();
  periods: Period[];
  sections: Section[];
  backupsections: Section[];
  sectionUsers: Section[];
  allSections: Section[];
  allItems: Item[];
  items: Item[];
  itemUsers;
  text = new Text();
  itemCount = 3;
  sectionCount = 10;
  showGoto = true;
  showToday = true;
  headerFormat = 'Do MMM YYYY';
  locale = 'en-AU';
  switchSelection: FormControl;
  start = moment().startOf('day');
  end = moment().endOf('day');
  currentPeriod: Period;
  ids: Observable<any>;
  _tempdata: any[];
  eventdisplayModal: boolean;
  event_displayModal: boolean;
  selectedEvent: any;
  searchhubform: any;
  hubstemp: any;
  hubs: any;
  date3:Date=new Date();

  startdateexport:Date;
  enddateexport:Date;

  selectedhub: any='REG0027';
  clicked:any='mat-button-toggle-checked';
  userdatatemp:any=[];
  months: SelectItem[];

  constructor(private dateUtil:DateUtilService, private  ref : ChangeDetectorRef, private hubservice:hubservice, private messageService: MessageService,public fb: FormBuilder,private visitservice:VisitService,private router: Router,private userdetailsservice :UserDetailsService,private http: HttpClient,private service: NgxTimeSchedulerService,private scheduleeventservice:scheduleeventservice) {
    this.events.SectionClickEvent = (section) => {
      this.eventOutput += '\n' + JSON.stringify(section);
      var secId=section.id.toString().substring(1);
      localStorage.setItem("USERID","USR"+secId);
      this.router.navigate(['/usercalendar']);
    };
    this.events.SectionContextMenuEvent = (section, {x, y}: MouseEvent) => {
      this.eventOutput += '\n' + JSON.stringify(section) + ',' + JSON.stringify({x, y});
    };
    this.events.ItemClicked = (item) => {
      this.eventOutput += '\n' + JSON.stringify(item);
      var itemId=item.id.toString().substring(1);
      var eventID="EVT"+itemId;
      this.visitservice.getEventsById(eventID).subscribe(sedata=>{
        if(sedata){
          this.eventdisplayModal=true;
          this.selectedEvent = sedata;
          if(this.selectedEvent!=undefined && this.selectedEvent!=null )
          {
          this.selectedEvent.startDate=this.getyyddmm(sedata.startDate);
          this.selectedEvent.endDate=this.getyyddmm(sedata.endDate);
          console.log(this.selectedEvent);
          }
          
        }else{
          this.selectedEvent = item;
          if(this.selectedEvent!=undefined && this.selectedEvent!=null )
          {
          this.selectedEvent.startDate=this.getyyddmm(this.selectedEvent.startDate);
          this.event_displayModal=true;
          console.log(this.selectedEvent);
          }
          
        }
      })
    };
    this.events.ItemContextMenu = (item, {x, y}: MouseEvent) => {
      this.eventOutput += '\n' + JSON.stringify(item) + ',' + JSON.stringify({x, y});
    };
    this.events.ItemDropped = (item) => {
      this.eventOutput += '\n' + JSON.stringify(item);
    };
    this.events.PeriodChange = (start, end) => {
      this.eventOutput += '\n' + JSON.stringify(start) + ',' + JSON.stringify(end);
    };

    this.periods = [
      {
        name: '1 week',
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        timeFrameHeadersTooltip: ['MMM yyyy', 'dd(EEE)'],
        classes: 'mat-button-toggle-checked',
        timeFrameOverall: 1440 * 7,
        timeFramePeriod: 1440,
      },
      {
        name: '2 week',
        timeFramePeriod: 1440,
        timeFrameOverall: 1440*14,
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        classes: 'period-3day',
      }, {
        name: '1 month',
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 43200,
        timeFramePeriod: 1440,
      }];

    this.sectionUsers = [{
      name: 'Resource 1',
      id: 1
    }, {
      name: 'Client 1',
      id: 2
    }, {
      name: 'Supplier 2',
      id: 3
    }, {
      name: 'Venue 2',
      id: 4
    }, {
      name: 'Resource 2',
      id: 5
    }, {
      name: 'Resource 3',
      id: 6
    }, {
      name: 'Supplier 4',
      id: 7
    }, {
      name: 'Client 3',
      id: 8
    }, {
      name: 'Supplier 1',
      id: 9
    },
      {
        name: 'Venue 1',
        id: 10
      }];

      this.sections = [
      ];
     
    this.itemUsers = [{
      id: 1,
      sectionID: 1,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      resource: 'Resource 1',
      supplier: 'Supplier 2',
      client: '',
      venue: '',
      classes: ''
    }, {
      id: 2,
      sectionID: 3,
      name: 'Item 2',
      start: moment().startOf('day').toDate(),
      end: moment().add(4, 'days').endOf('day').toDate(),
      classes: '',
      resource: '',
      supplier: 'Supplier 2',
      client: '',
      venue: 'Venue 1',
    }, {
      id: 3,
      sectionID: 1,
      name: 'Item 3',
      start: moment().add(1, 'days').startOf('day').toDate(),
      end: moment().add(3, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: '',
      venue: '',
    }, {
      id: 4,
      sectionID: 3,
      name: 'Item 4',
      start: moment().add(1, 'days').startOf('day').toDate(),
      end: moment().add(3, 'days').endOf('day').toDate(),
      classes: '',
      resource: '',
      supplier: 'Supplier 2',
      client: '',
      venue: 'Venue 2',
    }, {
      id: 5,
      sectionID: 1,
      name: 'Item 5',
      start: moment().add(7, 'days').startOf('day').toDate(),
      end: moment().add(8, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: 'Supplier 2',
      client: '',
      venue: '',
    }, {
      id: 6,
      sectionID: 1,
      name: 'Item 6',
      start: moment().subtract(3, 'days').startOf('day').toDate(),
      end: moment().subtract(1, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 3',
      supplier: '',
      client: '',
      venue: 'Venue 1',
    }, {
      id: 7,
      sectionID: 1,
      name: 'Item 7',
      start: moment().subtract(2, 'days').startOf('day').toDate(),
      end: moment().add(2, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 3',
      supplier: '',
      client: 'Client 1',
      venue: '',
    }, {
      id: 8,
      sectionID: 1,
      name: 'Item 8',
      start: moment().add(3, 'days').startOf('day').toDate(),
      end: moment().add(7, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: 'Client 3',
      venue: '',
    }, {
      id: 9,
      sectionID: 1,
      name: 'Item 9',
      start: moment().subtract(2, 'days').startOf('day').toDate(),
      end: moment().add(7, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: '',
      venue: '',
    }];

    this.items = [{
      id: 1,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 1,
      sectionID: 100100,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 2,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    }];
    this.switchSelection = new FormControl('1');
    this.currentPeriod = this.periods[0];
    this.resetEnd();
  }

  selectedmonth:string;
  ngOnInit(): void {
   

    this.months = [
      { label: 'Jan', value: '1'},
      { label: 'Feb', value: '2'},
      { label: 'Mar', value: '3'},
      { label: 'Apr', value: '4'},
      { label: 'May', value: '5'},
      { label: 'Jun', value: '6'},
      { label: 'Jul', value: '7'},
      { label: 'Aug', value: '8'},
      { label: 'Sep', value: '9'},
      { label: 'Oct', value: '10'},
      { label: 'Nov', value: '11'},
      { label: 'Dec', value: '12'},
    
  ];

    this.searchhubform = this.fb.group({
      shub: ['REG0027', []],
      sofficer: ['', []],
    });
    this.searchhub();
    this.gethubs();

    this.getAllusers();
  }
  
  progressbar:boolean =false;
 
  gethubs(){
    this.hubservice.getHubs().subscribe(hubData=>{
      if(hubData!=null){
        this.hubstemp = hubData;
        this.hubs=[];
        this.hubstemp.forEach(eledata=>{
          if(eledata.status =='Active'){
            this.hubs.push(eledata);            
          }
        }); 
      }
    });
  }
  listofevents:any[]=[];
  searchhub(){
    console.log(this.selectedhub);
    this.sections = [];
    this.items = [{
      id: 1,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 1,
      sectionID: 100100,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 2,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    }];

   
    this.userdetailsservice.getActiveUsersByRegions(this.selectedhub).subscribe(userData=>{
     
      //console.log("userdata"+this.selectedhub+"data... "+userData);
      
      // section = userData;
      userData=userData.filter(e=>(e.status=='Active' && (e.roleId=='ACO' || e.roleId=='SCHD_ACO_READONLY' || e.roleId=='ROM' || e.roleId=='SCHD_ROM_READONLY')));
    //  console.log("UserData........................")
    /*  console.log(JSON.stringify(userData))
      const grouped = userData.reduce((res, curr) => {
        res[curr.roleId] = res[curr.roleId] || [];
        res[curr.roleId].push(curr);
        return res;
      }, {});

      Object.values(grouped).forEach((group:any[])=>{
        group.sort((a,b)=> a.userFirstName < b.userFirstName ? -1:1);
      })
      
      console.log("grouped........................")
      console.log(JSON.stringify(grouped))
     
      const userdata = [Object.entries(grouped)]
     
      console.log("afetr........................")
      console.log(JSON.stringify(userData))*/
    
      let romlist = userData.filter(el=> (el.roleId=='ROM'|| el.roleId=='SCHD_ROM_READONLY')).sort((a,b)=>a.userFirstName < b.userFirstName ? -1:1)
      let acolist = userData.filter(el=> (el.roleId=='ACO'|| el.roleId=='SCHD_ACO_READONLY')).sort((a,b)=>a.userFirstName < b.userFirstName ? -1:1)
      //console.log("romlist........................"+JSON.stringify(romlist))
      //console.log("acolist........................"+JSON.stringify(acolist))
      userData=romlist.concat(acolist);

      this._tempdata=userData;

      if(userData!=undefined && userData!=null && userData.length!=0)
      {
        this.progressbar=true;
      }
   
      let mypromise = new Promise((resolve, reject) => {userData.forEach((e,index,array)=>{

        let title=e.title;
        if(title == "SFORD" || title == "FORD" || title == "FSORD" || title === "HUBMRD" || title === "HUBCRD") 
        {
          title=title.replace("RD","")
        }
        let varname = e.userFirstName+' '+e.userLastName+'-'+e.region +'\n'+ title ;

        this.sections.push({name:varname,id: Number(e.userId.replace("USR", "1")),tooltip:e.userFirstName+' '+e.userLastName+'-'+e.region});
        // this.refresh();
               
        this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
          if(ssedata!=null){
            this.userevents = ssedata; 
            let d = new Date();
            d.setMonth(d.getMonth() - 6);

        
            this.userevents = this.userevents.filter(el=>((new Date(el.endDate)).getTime()>=d.getTime()))        
          //  console.log("this.userevents size"+ this.userevents.length)                          
            this.userevents.forEach(eledata=>{
              if (eledata.startDate!=null){
                console.log(eledata.endDate)
             
                if(eledata.title=="V"){
                  eledata.title="AR"
                }
                var startDate = new Date(eledata.startDate);
                var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
                var endDate = new Date(eledata.endDate);
                              
                //var endOfDayDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate(),23,59,59);
              //  endOfDayDate.setDate(endOfDayDate.getDate()-1);
              var endOfDayDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
         
                var bgcolor=this.getbgcolor(eledata.eventType);
                console.log("event ttype"+eledata.eventType)


                if(eledata.daysofweek!=null)
                {
                  const dates = this.getDates(new Date(eledata.startDateRecur), new Date(eledata.endDateRecur))
                  dates.forEach(date => {
                    let day  = ""+date.getDay();

               //   console.log("date..."+eledata.daysofweek.includes(day))
                  if(eledata.daysofweek.includes(day))
                  {
                    startOfDayDate = new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,1);
                    endOfDayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                    console.log("startOfDayDate"+startOfDayDate)
                    console.log("endOfDayDate"+endOfDayDate)
                    if(startOfDayDate!=null && endOfDayDate!=null && startOfDayDate!=undefined && endOfDayDate!=undefined)
                    {
                       this.service.itemPush({
                      id: Number(eledata.eventId.replace("EVT", "1")),
                      sectionID: Number(e.userId.replace("USR", "1")),
                      name: eledata.title,
                      start: moment(startOfDayDate).toDate(),
                      end: moment(endOfDayDate).toDate(),
                      classes: '',
                      cstyles: this.getbgcolor(eledata.eventType),

                     tooltip:eledata.title + ' '+ this.getCorrectedDate(eledata.startDate)+'/'+  this.getCorrectedDate(eledata.endDate),
                });
                    }
  
                  }

                 
                })
               }
               else{
              
                this.service.itemPush({
                  id: Number(eledata.eventId.replace("EVT", "1")),
                  sectionID: Number(e.userId.replace("USR", "1")),
                  name: eledata.title,
                  start: moment(startOfDayDate).toDate(),
                  end: moment(endOfDayDate).toDate(),
                  classes: '',
                  cstyles: this.getbgcolor(eledata.eventType),

                  tooltip:eledata.title + ' '+ this.getCorrectedDate(eledata.startDate)+'/'+  this.getCorrectedDate(eledata.endDate),
                });
              }
            
               
                // this.service.itemPush({id:Number(eledata.eventId.replace("EVT", "1")),sectionID:Number(e.userId.replace("USR", "1")),name:eledata.title,start: moment(startOfDayDate, 'YYYY-MM-DD HH:mm'),end:moment(endOfDayDate, 'YYYY-MM-DD HH:mm'),classes: bgcolor});
              
              }
            }); 
         }
         if(index == array.length-1)
               {
                console.log("-------------------------------------")
      
                  resolve(null);
               }
      
       });

       
      })

    })

    mypromise.then(() => {
      this.progressbar=false;
      this.backupsections=this.sections;
      console.log('All done!');
  });

    })
  
  }

  getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }
  loadCalendar(searchval:string)
  {
   if(searchval.trim()=='')
   {
    this.sections=[...this.backupsections]
   }
   else
   {
   this.sections= this.sections.filter(el=>el.name.includes(searchval));
   
   }

  }

  getAllusers()
  {
    this.userdetailsservice.getActiveUsersByRegion().subscribe(userData=>{
     
      //console.log("All users"+userData);
      
      // section = userData;
      userData=userData.filter(e=>(e.status=='Active'));
      userData=userData.sort( (a,b)=> a.userFirstName < b.userFirstName ? -1:1 );
    

     userData.forEach((e)=>{
        let offname = e.userFirstName+' '+e.userLastName
        let region = e.region
        let role = e.title?e.title.replace("RD",""):'';
       
        this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
          if(ssedata!=null){
            this.alluserevents = ssedata; 
            this.alluserevents.forEach(ev=>{
              ev.name=offname
              ev.role=role
              ev.region=region
            })

            this.listofevents.push(  this.alluserevents ) 
          }
        })
      })

    })
  }
 
  exportExcelforMonth() {

    var myevents =  [].concat.apply([], this.listofevents);

    let eventsDetailsExcelarr: any[]=[];
    // let qipDetailsExcel={'Id':'','Standard Id':'', 'Element Id':'', 'Visit Id':'','QIP Details':'','Notes Type':'', 'Remarks':'','QIP Id':''};
     for (let eve of myevents)
     {
      let evestartDate = environment.datePipe.transform(new Date(eve.startDate), 'dd-MM-yyyy');
     
      let eveendDate = environment.datePipe.transform(new Date(eve.endDate), 'dd-MM-yyyy');
      eventsDetailsExcelarr.push({'name':eve.name,'Standard ID':eve.standardId, 'Visit ID':eve.visitId, 'Region':eve.region, 'Title':eve.title,'Event Tyoe':eve.eventType, 'StartDate':evestartDate,'EndDate':eveendDate,'Future Visit':eve.futureVisit});
     }
  
     let todaysdatemon = new Date().getMonth();
     let selectedyear = new Date().getFullYear();
    
    /* if(this.selectedmonth!=null && +this.selectedmonth > todaysdatemon)
     {
      selectedyear=selectedyear-1;

     }*/
    
      eventsDetailsExcelarr = eventsDetailsExcelarr.filter(el=>(''+((new Date(el.StartDate)).getMonth()+1)===this.selectedmonth  &&  ((new Date(el.StartDate)).getFullYear()===selectedyear)))
 
  


    if(eventsDetailsExcelarr!=null && eventsDetailsExcelarr.length>0)
   {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(eventsDetailsExcelarr);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Events");
    });
  }
else{
  this.messageService.add({ severity: 'error', summary: 'error', detail: ' No data found'});
      
 }
}


 

exportExcelforDates() {
  let eventsDetailsExcelarr: any[]=[];

   if(this.startdateexport ==null || this.startdateexport ==undefined || this.enddateexport==null || this.enddateexport==undefined)
   {
    this.messageService.add({ severity: 'error', summary: 'error', detail: 'Start Date and End Date must be selected'});
    return;
 
   }
   if(this.enddateexport<this.startdateexport)
   {
    this.messageService.add({ severity: 'error', summary: 'error', detail: 'Start Date cannot be after the End Date'});
    return;
   }

   var myevents =  [].concat.apply([], this.listofevents);
   myevents = myevents.filter(el=>(new Date(el.startDate) >= this.startdateexport && new Date(el.endDate) <= this.enddateexport))
  
   for (let eve of myevents)
   {
    let evestartDate = environment.datePipe.transform(new Date(eve.startDate), 'dd-MM-yyyy');
   
    let eveendDate = environment.datePipe.transform(new Date(eve.endDate), 'dd-MM-yyyy');
    eventsDetailsExcelarr.push({'Name':eve.name,'Region':eve.region,'Role':eve.role,'Event Name':eve.eventName, 'Event Type':eve.eventType, 'Start Date':evestartDate,'End Date':eveendDate});
   }

  
   if(eventsDetailsExcelarr!=null && eventsDetailsExcelarr.length>0)
   {
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(eventsDetailsExcelarr);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Events");
  });
 }else{
  this.messageService.add({ severity: 'error', summary: 'error', detail: ' No data found'});
      
 }
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  let EXCEL_EXTENSION = '.xlsx';
  const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
  });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}


  getCorrectedDate(d: string) {
    return this.dateUtil.getCorrectedDate(d);
  }
  getyyddmm(d){
    return this.dateUtil.getCorrectedDefaultDate(d);
  }
  changePeriod(period: any) {
    this.currentPeriod = period;
    this.resetEnd();
  }
  addItem() {
    this._tempdata.forEach(e=>{
      this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
        ssedata.forEach(eledata=>{
          this.service.itemPush({
            id: Number(eledata.eventId.replace("EVT", "1")),
            sectionID: Number(e.userId.replace("USR", "1")),
            name: 'Item ',
            start: moment().startOf('day').toDate(),
            end: moment().add(5, 'days').endOf('day').toDate(),
            classes: ''
          });
        })
      })
    })
    
  }
  resetEnd() {
    this.end = moment(this.start).add(this.currentPeriod.timeFrameOverall, 'minutes').endOf('day');
  }
  nextPeriod() {
    this.preDisable=false;
    this.start = moment(this.start).add(this.currentPeriod.timeFrameOverall, 'minutes').startOf('day');
    this.resetEnd();
  }
  preDisable=false
  previousPeriod() {
       
    if(moment().diff(moment(this.start),'months')>=6)
    {
     
      this.preDisable=true;
    
    }
    else{
      this.start = moment(this.start).subtract(this.currentPeriod.timeFrameOverall, 'minutes');

    }
  
    this.resetEnd();
  }
  gotoToday() {
    this.preDisable=false;
    this.start = moment().startOf('day');
    this.resetEnd();
  }
  gotoDate() {
    this.preDisable=false;
    this.start = moment(this.date3).startOf('day');
    this.resetEnd();
  }
  showSectionId(e) {
    console.log(e);
    let arrayUnique = [];
    this.items.forEach((item, i) => {
      if (!arrayUnique.includes(item[e])) {
        arrayUnique.push(item[e])
        console.log(item[e]);
        this.sectionUsers[i][e] = item[e];
        console.log(this.sectionUsers);
      }
    });
    console.log(arrayUnique);
    console.log(this.sectionUsers);
    
    
    this.sections = this.sectionUsers;
    console.log(this.sections);
    
    this.refresh();
  }
  refresh() {
    this.service.refresh();
  }

  getbgcolor(eventType: any) {
    /*
      leave and Flex #F03030
      Training and Conference - #3361FF
      Team meeting and Admin Day - #A569BD
      Report Writing and Desktop Audit - #ABEBC6
      Compliance and Investigation - #FCE32E
      Pre,Post approval and change of ownership - #C10AC3
      Travel - #F06630 
      Assessment and visit -  #1AA11A
      Secondment Visit - #EBEEEE
      Multiple -#9B9E9C
      others - #26C8F3
      School holidays watermark colorMultiple events - #D6F9F1
    */
    var bgcolor={'background-color':''};
    if (eventType == 'L' || eventType == 'F') {
      bgcolor = bgcolor = {'background-color':'red'};
    } else if (eventType == 'T' || eventType == 'CF') {
      bgcolor = bgcolor = {'background-color':'#6699CC'};
    } else if (eventType == 'M' || eventType == 'A') {
      console.log("getting lightpurple")
      bgcolor = bgcolor = {'background-color':'lightpurple'};
    } else if (eventType == 'D' || eventType == 'R') {
      bgcolor = bgcolor = {'background-color':'lightgreen'};
    } else if (eventType == 'COM' || eventType == 'I' || eventType == 'MAT') {
      bgcolor = bgcolor = {'background-color':'yellow'};
    } else if (eventType == 'PA' || eventType == 'PR' || eventType == 'CofO') {
      bgcolor = bgcolor = {'background-color':'purple'};
    } else if (eventType == 'H') {
      bgcolor = bgcolor = {'background-color':'#F06630'};
    } else if (eventType == 'V') {
      bgcolor = bgcolor = {'background-color':'green'};
    } else if (eventType == 'ME') {
      bgcolor = bgcolor = {'background-color':'gray'};
    } else if (eventType == 'O') {
      bgcolor = bgcolor = {'background-color':'lightblue'};
    } else if (eventType == 'SV') {
      bgcolor = {'background-color':'lightskyblue'};
    } else if (eventType == 'W') {
      bgcolor = {'background-color':'lightgray'};
    }else if(eventType == 'termHoliday'){
      bgcolor = {'background-color':'#D3D3D3'};
    }else if(eventType == 'publicHoliday'){ 
      bgcolor = {'background-color':'#ffcccb'};
    }else if(eventType == 'LP'){ 
      bgcolor = {'background-color':'#F4C2C2'};
    }else if(eventType == 'LWOP'){ 
      bgcolor = {'background-color':'#de7c7c'};
    }else {
      bgcolor = {'background-color':''};
    }

    return bgcolor;
  }

}


