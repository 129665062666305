import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../service/AppConfigService';
import { LoginDataService } from './loginData.service';
import { User } from '../domain/user';

const getUrl = '/users/all';
const getforUserId='/api/appuserdetails';
const getAccessDetails='/users/access';
const getActiveUsers='/api/appuserdetails';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  baseUrl :any;

  constructor( private http: HttpClient,private config: AppConfigService,private loginService: LoginDataService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  getActiveUsersByRegion(): Observable<any> {
    return this.http.get<User[]>(this.baseUrl + getActiveUsers);
  }

  getActiveUsersByRegions(region: any): Observable<any> {
    return this.http.get<User[]>(this.baseUrl + "/api/appuserdetailsbyregion/"+region);
  }

  getAllUsers() : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };

    return this.http.get<any>(this.baseUrl + getUrl, httpOptions).pipe(
      catchError(this.handleError('getAllUsers', null))
    );
  }

  getActiveUsers() : Observable<any> {
     return this.http.get<User[]>(this.baseUrl + getActiveUsers);
  }

  getAllActiveUsers() : Observable<any> {
    return this.http.get<User[]>(this.baseUrl + getActiveUsers+"/active");
 }

  getUserforId(userId: string) : Observable<any> {
    // alert("userid"+userId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
    return this.http.get<any>(this.baseUrl + getforUserId + "/" + userId, httpOptions).pipe(
      catchError(this.handleError('getUserforId', userId))
    );
  }

  getUserforIntUserId(userId: string) : Observable<any> {
    // alert("userid"+userId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
    return this.http.get<any>(this.baseUrl + getforUserId + "/int/" + userId, httpOptions).pipe(
      catchError(this.handleError('getUserforId', userId))
    );
  }

  getAccessDetails(roleId: string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
    //  alert("in access details"+roleId) ;
    return this.http.get<any>(this.baseUrl + getAccessDetails + "/" + roleId, httpOptions).pipe(
      catchError(this.handleError('getAccessDetails', roleId))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      //alert("error message" + JSON.stringify(error));
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}