export class targettypes {
    targetId?:string;
    targetName?:string;
    targetDesc?:string;
    status?:string;
    createdBy?:string;
    createdDate?:Date;
    updatedBy?:string;
    updatedDate?:Date;    
}

