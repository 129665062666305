export class HoliDay {
    pubHolId?:string;
    holDesc?:string;
    startDate?:any;
    endDate?:any;
    month?:string;
    createdBy?:string;
    createdDate?:Date;
    updatedBy?:Date;
    updatedDate?:Date;
    termDesc?:string;
    termHolId?:string;
}