import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {schedulepreferredservices} from '../domain/schedulepreferredservices';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/schedulepreferredservices';

@Injectable()
export class schedulepreferredservice {
    getschedulepreferredservicesbyid(id:any): Observable<any> {
        return this.http.get<schedulepreferredservices[]>(this.baseUrl+getUrl+"/"+id);  
   }
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getschedulepreferredservices(): Observable<any> {
         return this.http.get<schedulepreferredservices[]>(this.baseUrl+getUrl);  
    }
 
    create(data:any): Observable<any> {
        return this.http.post(this.baseUrl+getUrl, data);
    }

    // create(data:any): Observable<any> {
    //     return this.http.post(this.baseUrl+getUrl+"/serviceids", data);
    // }
    
    update(data:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl, data);
    }
    
    delete(id:any): Observable<any> {
        return this.http.delete(this.baseUrl+getUrl+'/'+id);
    }
    

}