import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <span class="footer-text-right" style="font-size:12px;">eSchedule Version:0.0.1</span>
           <!-- <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>All Rights Reserved</span>
            </span>-->
        </div>
    `
})
export class AppFooterComponent {

}
