import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {locations} from '../domain/locations';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/locations';

@Injectable()
export class locationservice {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getLocations() : Observable<any> {
         return this.http.get<locations[]>(this.baseUrl+getUrl);  
    }


    getLocationsforId(locId) : Observable<any> {
      return this.http.get<locations[]>(this.baseUrl+getUrl+'/'+locId);  
 }

 

   createLocation(data:any): Observable<any> {
    return this.http.post(this.baseUrl+getUrl, data);
   }


   updateLocation(data:any): Observable<any> {
    return this.http.put(this.baseUrl+getUrl, data);
  }


  delete(data:any): Observable<any> {
    return this.http.delete(this.baseUrl+getUrl+'/'+data.locationId);
  }
    
}