import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './AppConfigService';
import { LoginDataService } from './loginData.service';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';


const getBulkEmailUrl = '/api/bulkemail';
const getBulkReleaseUrl = '/api/bulkvisitsrelease';
const getBulkReleasetoHubsUrl = '/api/bulkvisitsreleasetoHubs';
const getHubReminderTypeUrl = '/api/sendhubmanagerreminder'


@Injectable({
  providedIn: 'root'
})
export class BulkoperationService {


  baseUrl : any;
  constructor(private http: HttpClient,private config: AppConfigService, private loginService: LoginDataService) {
    this.baseUrl = this.config.getConfig().baseUrl;
   }
  
  releaseVisitsToOfficers(bulkemailVisitAO:any): Observable<any> {
    
    return this.http.get(this.baseUrl+getBulkReleaseUrl+'/'+ bulkemailVisitAO.loggedInUserId + '/'+ bulkemailVisitAO.releasePeriodType);
  }


  releaseVisitsToHubs(bulkemailVisitAO:any,managingRegion:any, commsDueDate:string): Observable<any> {
    return this.http.get(this.baseUrl+getBulkReleasetoHubsUrl+'/'+ bulkemailVisitAO.loggedInUserId + '/'+ bulkemailVisitAO.releasePeriodType+'/'+managingRegion + '/'+commsDueDate);
  }


  sendHubManagerReminderEmail(reminder:any): Observable<any> {
    
    return this.http.post(this.baseUrl+getHubReminderTypeUrl,reminder);
  }

  sendBulkemail(bulkemail: any): Observable<any> {
    console.log("-- ptype-" + bulkemail.periodType, + "yp"+ bulkemail.yearPeriod + "bt" + bulkemail.bulkType + "evttype "+ bulkemail.eventType);   
    return this.http.post(this.baseUrl+getBulkEmailUrl , bulkemail);
  }


  
}
