import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

const datePipe = new DatePipe("en-AU");

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  constructor() { }

  getCorrectedDate(d: string) {
    if(d){
      return datePipe.transform(d.substring(0,10), 'dd/MM/yyyy');
    }
    return null;
  }
  getCorrectedDefaultDate(d: string) {
    if(d){
      return datePipe.transform(d.substring(0,10), 'yyyy/MM/dd');
    }
    return null;
  }
}
