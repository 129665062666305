import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from './AppConfigService';
import { LoginDataService } from './loginData.service';
import { User } from '../domain/user';
import { Visit } from '../domain/visit';

const getUrl = '/api/servicedetails';


@Injectable({
  providedIn: 'root'
})
export class ServiceDetailsService {
  baseUrl :any;

  constructor( private http: HttpClient,private config: AppConfigService,private loginService: LoginDataService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }

  deleteServiceDetails(id:any): Observable<any> {
    return this.http.delete(this.baseUrl+getUrl+'/'+id);
  }

  getServicesById(serviceId:any): Observable<any> {
    return this.http.get<Visit>(this.baseUrl+getUrl+'/'+ serviceId);
  }

  getAllServices(): Observable<any> {
    return this.http.get<Visit>(this.baseUrl+getUrl);
  }

  getAllServicesForProviderId(providerId:any): Observable<any> {
    return this.http.get<Visit>(this.baseUrl+getUrl+'/provider/'+providerId);
  }
  updateService(serviceId:any,data:any): Observable<any> {
    return this.http.put(this.baseUrl+getUrl+'/'+serviceId, data);
  }

  updateServices(data:any): Observable<any> {
    return this.http.put(this.baseUrl+getUrl, data);
  }

  
  
}