export class flexperiods {
    perId?:string;
    perName?:string;
    perDesc?:string;
    perStartDate?:string;
    perEndDate?:string;
    perYear?:string;
    period?:string;
    status?:string;
    createdBy?:string;
    createdDate?:Date;
    updatedBy?:string;
    updatedDate?:Date;
    leaveCutoffDate?:any;
}

export class flexDom{
    name?:any;
    value:any;
}

