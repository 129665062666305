import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';

import { SummaryComponent } from './admin/components/summary/summary.component';
import { PreferredservicesComponent } from './admin/components/preferredservices/preferredservices.component';
import { UsereventsComponent } from './admin/components/userevents/userevents.component';
import { UsercalendarComponent } from './admin/components/usercalendar/usercalendar.component';
import { UsermaintenanceComponent } from './admin/components/usermaintenance/usermaintenance.component';
import { PastschedulesComponent } from './admin/components/pastschedules/pastschedules.component';
import { BulkoperationsComponent } from './admin/components/bulkoperations/bulkoperations.component';
import { HolidaysComponent } from './admin/components/holidays/holidays.component';
import { FlexperiodsComponent } from './admin/components/flexperiods/flexperiods.component';
import { TargettypesComponent } from './admin/components/targettypes/targettypes.component';
import { MappingprefservicesComponent } from './admin/components/mappingprefservices/mappingprefservices.component';
import { Oauth2Component } from './oauth2/oauth2.component';
import { UsereventsnewComponent } from './admin/components/usereventsnew/usereventsnew.component';
import { HomeComponent } from './admin/components/home/home.component';
import { HubcohomeComponent } from './admin/components/hubcohome/hubcohome.component';
import { HubusereventsComponent } from './admin/components/hubuserevents/hubuserevents.component';
import { UsereventstestComponent } from './admin/components/usereventstest/usereventstest.component';
import { ScheduleListHubCoComponent } from './admin/components/schedulelisthubco/schedulelisthubco.component';
import { ScheduleListHubCoAdminComponent } from './admin/components/schedulelisthubcoadmin/schedulelisthubcoadmin.component';
import { LargeProviderComponent } from './admin/components/largeprovider/largeprovider.component';
import { LargeProviderAssignmentsComponent } from './admin/components/largeproviderassignments/largeproviderassignments.component';
import { LocationsComponent } from './admin/components/locations/locations.component';
import { EmailAdminComponent } from './admin/components/emailadmin/emailadmin.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', redirectTo: 'login',   pathMatch: 'full'},      
            {path: 'secure/aad', component: Oauth2Component},      
       //     {path: 'oauth2/redirect', component: Oauth2Component},
            {path: 'oauth2/logout', redirectTo: 'login'},
            {
                path: '', component: AppMainComponent,
                children: [                   
                    {path: 'summary', component: SummaryComponent},                
                    {path: 'preferredservices', component: PreferredservicesComponent},
                    {path: 'usereventsnew', component: UsereventsComponent},
                    {path: 'userevents', component: UsereventsnewComponent},
                    {path: 'usercalendar', component: UsercalendarComponent},
                    {path: 'usermaintenance', component: UsermaintenanceComponent},
                    {path: 'pastschedules', component: PastschedulesComponent},
                    {path: 'bulkoperations', component: BulkoperationsComponent},
                    {path: 'holidays', component: HolidaysComponent},
                    {path: 'flexperiods', component: FlexperiodsComponent},
                    {path: 'emailadmin', component: EmailAdminComponent},
                    {path: 'locations', component: LocationsComponent},
                    {path: 'largeprovider', component: LargeProviderComponent},
                    {path: 'largeproviderassignments', component: LargeProviderAssignmentsComponent},     
                    {path: 'targettypes', component: TargettypesComponent},
                    {path: 'mappingprefservices', component: MappingprefservicesComponent},
                    {path: 'home', component: HomeComponent},
                    {path: 'hubcohome', component: HubcohomeComponent},
                    {path: 'hubuserevents', component: HubusereventsComponent},
                    {path: 'schedulelisthubco', component: ScheduleListHubCoComponent},
                    {path: 'schedulelisthubcoadmin', component: ScheduleListHubCoAdminComponent},
                    {
                        path: 'usereventstest',
                        loadChildren: () => import('./admin/components/usereventstest/userevents/userevents.module')
                          .then(m => m.UsereventsModule),
                      },
                ]
            },
            
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            
            
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
