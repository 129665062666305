import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';
import {targettypes} from '../../domain/targettypes';
import { targettypesservice } from '../../service/targettypesservice';

@Component({
  selector: 'app-targettypes',
  templateUrl: './targettypes.component.html',
  styleUrls: ['./targettypes.component.scss']
})
export class TargettypesComponent implements OnInit {
  targettypes: targettypes[];
  backuptargettypes: targettypes[];
  targetModal: boolean;
  isSubmitted: boolean;
  isEditSubmitted: boolean;
  tt : targettypes;
  updateTargetModal: boolean;
  selected_Target: targettypes;
  update_Target_Submitted: boolean;
  Target_type: any;
  progressbar:boolean=false;
  status: { name: string; value: string; }[];
  selectedStatus:any=[{name: 'Active', value: 'Active'}] ;

  targetMessages: { severity: string; summary: string; detail: string; }[];
   constructor(private targettypesservice: targettypesservice,public fb: FormBuilder, private messageService:MessageService) { 
    this.status = [
      {name: 'Active', value: 'Active'},
      {name: 'Inactive', value: 'Inactive'},
    ];
   }


   registerForm: FormGroup;
   
  addtargetform = this.fb.group({
    ftargetname: ['', [Validators.required]],
    ftargetdesc: ['', [Validators.required]],
  });   

  edittargetform = this.fb.group({
    fstatus: ['', [Validators.required]]    
  });

  ngOnInit(): void {
    this.progressbar=true;
      this.targettypesservice.getTargetTypes().subscribe(targetdata =>{  
        this.backuptargettypes   =  targetdata;
        this.targettypes = targetdata.filter(el=>el.status == 'Active');  
        this.progressbar=false;
      });

    this.registerForm = this.fb.group({

      /* for edit */
      updatetargetTypegroup : this.fb.group({
        fTargetname:['', [Validators.required]],
        ftarget_des:['', [Validators.required]],
        ftarget_status:['', [Validators.required]],
      }),
      
    });
  }
  get target_types() { return (<FormGroup>this.registerForm.get('updatetargetTypegroup')).controls; }

  
  addtarget(){
    this.progressbar=true;
    this.isSubmitted = true;
    if (!this.addtargetform.valid) {
      this.progressbar=false;
      return false;
    } else {
      console.log(JSON.stringify(this.addtargetform.value));
      this.tt = new targettypes;
      this.tt.targetName = this.addtargetform.get("ftargetname").value; 
      this.tt.targetDesc = this.addtargetform.get("ftargetdesc").value; 
      this.tt.status = 'Active';
      this.tt.createdBy = localStorage.getItem("loginuserid");

      this.targettypesservice.create(this.tt).subscribe(res=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Target Type created.'});
        this.ngOnInit();
      },err=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Target Type failed to save! '});
             
      });
      this.targetModal = false;
    }
    this.formReset();
  }

  //added to reset the form after submited
  formReset(){
    this.addtargetform = this.fb.group({
      ftargetname: ['', [Validators.required]],
      ftargetdesc: ['', [Validators.required]],
    });
  }

  updateTarget_Type(){
    this.update_Target_Submitted = true;
    if (!this.updatetargetTypegroup.valid){
      return false;
      }
      else{
        this.targettypesservice.getTargetbyId(this.selected_Target.targetId).subscribe(targetdata=>{
          this.Target_type = targetdata;

          this.Target_type.targetName = this.updatetargetTypegroup.get("fTargetname").value;
          this.Target_type.targetDesc = this.updatetargetTypegroup.get("ftarget_des").value;
          this.Target_type.status = this.updatetargetTypegroup.get("ftarget_status").value;
          this.Target_type.updatedBy = localStorage.getItem("loginuserid");
          
          
          this.targettypesservice.update(this.Target_type).subscribe(res=>{
          //   this.targetMessages = [
          //     {severity:'success', summary:'Success', detail:'Target Type updated -'+ this.selected_Target.targetId}
          // ];
          this.messageService.add({ severity: 'success', summary: 'Sucess', detail: 'Target Type updated -'+ this.selected_Target.targetId});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'Error', detail:'Target Type update failed for '+ this.selected_Target.targetId});
          //   this.targetMessages = [
          //     {severity:'error', summary:'error', detail:'Target Type update failed for '+ this.selected_Target.targetId}
          // ];
          });        
      });
    }
    
    this.update_Target_Submitted = false;
    this.updateTargetModal = false;
    
  }


  openLeaveCutoffModal(targettypes){
    this.update_Target_Submitted = false;
    this.updateTargetModal = true;
    this.selected_Target = targettypes;
    this.selectedPeriod_Id();
  }
  selectedPeriod_Id() {
    this.updatetargetTypegroup.patchValue({
      fTargetname: this.selected_Target.targetName,
      ftarget_des: this.selected_Target.targetDesc,
      ftarget_status: this.selected_Target.status,
    });
  }


  
  showtargetmodel(){
    this.isSubmitted=false;
    this.targetModal = true;
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.targettypes);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "targettypes");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  get ftargetname() {
    return this.addtargetform.get('ftargetname');
  }
  get ftargetdesc() {
    return this.addtargetform.get('ftargetdesc');
  }
  get updatetargetTypegroup() {
    return this.registerForm.get('updatetargetTypegroup');
  }


  async statusChanged()
  {
    let selectedUsers=[];

    this.targettypes=[...this.backuptargettypes];

   
    console.log(this.selectedStatus.length);

    if(this.selectedStatus.length===1)
    {
      console.log(this.selectedStatus[0].value)
      this.targettypes = this.targettypes.filter(e=>e.status===this.selectedStatus[0].value);
    }
    else if(this.selectedStatus.length===2)
    {
      console.log(this.selectedStatus[0].value)
      console.log(this.selectedStatus[1].value)



      this.targettypes = this.targettypes.filter(e=>(e.status===this.selectedStatus[0].value  || e.status===this.selectedStatus[1].value));

    }
   
      
  }


}
