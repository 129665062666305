import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';
import { ServiceProviderService } from '../../service/serviceprovider.service';
import { serviceprovider } from '../../domain/serviceprovider';

@Component({
  selector: 'app-largeprovider',
  templateUrl: './largeprovider.component.html',
  styleUrls: ['./largeprovider.component.scss']
})
export class LargeProviderComponent implements OnInit {
  serviceproviderlist: serviceprovider[];
  largeProviderModal: boolean;
  isSubmitted: boolean;
  isEditSubmitted: boolean;
  updateLargeProviderModal: boolean;
  selected_Provider: serviceprovider;
  update_LargeProvider_Submitted: boolean;
  LargeProvider_type: any;
  progressbar:boolean=false;
  serviceproviders: { name: string; value: string; }[]=[];


  targetMessages: { severity: string; summary: string; detail: string; }[];
   constructor(private serviceprovidersservice : ServiceProviderService,public fb: FormBuilder, private messageService:MessageService) { 
   //get all service providers
   this.serviceprovidersservice.getAllSPDetails().subscribe(spdata =>{      
    console.log(spdata)
    spdata.forEach(element => {
      this.serviceproviders.push({'name':element.serviceProviderId, 'value':element.serviceProviderId})
      
    });
    
  });
   }


   registerForm: FormGroup;
   
  addlargeproviderform = this.fb.group({
    flprovidername: ['', [Validators.required]],
  });   

 

  ngOnInit(): void {

    

    this.progressbar=true;
      this.serviceprovidersservice.getAllSPDetails().subscribe(providerdata =>{      
        this.serviceproviderlist = providerdata;  
        this.progressbar=false;
      },err=>{
        console.log(err);
        this.progressbar=false;
 
      });

    this.registerForm = this.fb.group({

      /* for edit */
      updatelargeprovidergroup : this.fb.group({
        flprovidername:['', [Validators.required]],
      //  ftarget_status:['', [Validators.required]],
      }),
      
    });
  }
  get largeproviders() { return (<FormGroup>this.registerForm.get('updatelargeprovidergroup')).controls; }

  

  //added to reset the form after submited
  formReset(){
    this.addlargeproviderform = this.fb.group({
      fflprovidername: ['', [Validators.required]],
    });
  }


  openProviderModal(providertypes){
    this.update_LargeProvider_Submitted = false;
    this.updateLargeProviderModal = true;
    this.selected_Provider= providertypes;
    this.selected_Id();
  }
 
  selected_Id() {

    this.updatelargeprovidergroup.patchValue({
      flargeProviderName: this.selected_Provider.largeProviderName,

    });
  }



  updateTarget_LargeProvider(){
    this.update_LargeProvider_Submitted = true;
    if (!this.updatelargeprovidergroup.valid){
      return false;
      }
      else{
        this.serviceprovidersservice.getSPDetail(this.selected_Provider.serviceProviderId).subscribe(providerdata=>{
          this.LargeProvider_type = providerdata;

          this.LargeProvider_type.largeProviderName = this.updatelargeprovidergroup.get("flprovidername").value;
         
          this.LargeProvider_type.updatedBy = localStorage.getItem("loginuserid");
          this.LargeProvider_type.updatedDate = new Date();
          
          
          this.serviceprovidersservice.updateSPDetail(this.LargeProvider_type).subscribe(res=>{
          //   this.targetMessages = [
          //     {severity:'success', summary:'Success', detail:'Target Type updated -'+ this.selected_Target.targetId}
          // ];
          this.messageService.add({ severity: 'success', summary: 'Sucess', detail: 'Large Provider updated -'+ this.LargeProvider_type.targetId});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'Error', detail:'Large Provider update failed for '+ this.LargeProvider_type.targetId});
          //   this.targetMessages = [
          //     {severity:'error', summary:'error', detail:'Target Type update failed for '+ this.selected_Target.targetId}
          // ];
          });        
      });
    }
    
    this.update_LargeProvider_Submitted = false;
    this.updateLargeProviderModal = false;
    
  }

  selectedServiceProvider:serviceprovider;
  spediting:boolean = false;
  onRowEditSPInit(serviceProvider: serviceprovider){
    this.selectedServiceProvider = {...serviceProvider};
    this.spediting = true;
}
serviceProvider:serviceprovider;
onRowEditSPCancel(serviceProvider: serviceprovider, index: number) {
         
  this.serviceProvider=this.serviceproviderlist.filter(el=>(el.serviceProviderId === serviceProvider.serviceProviderId))[0];
  this.serviceproviderlist[this.serviceproviderlist.indexOf(this.serviceProvider)] = this.selectedServiceProvider;
  this.spediting = false;
}
onRowEditSPSave(serviceProvider: serviceprovider) {
  serviceProvider.updatedBy = localStorage.getItem("loginuserid");
  serviceProvider.updatedDate = new Date();
  this.spediting = false;

  this.serviceprovidersservice.updateSPDetail(serviceProvider).subscribe(res=>{           
      console.log(res);  
          
      this.messageService.add({severity:'success', summary: 'Success', detail:'Service Provider detail updated successfully'});
  },err=>{      
      console.log(err);        
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Service Provider detail update failed' });
  })
}


  
  showlargeprovidermodel(){
    this.isSubmitted=false;
    this.largeProviderModal = true;
  }
  exportExcel() {
    let exp_data = this.serviceproviderlist.map(function(aUser){
      return {ProviderId: aUser.serviceProviderId,
             ProviderName: aUser.serviceProviderName,
             ProviderType: aUser.providerType,
             LargeProviderName: aUser.largeProviderName           
            };
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(exp_data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "serviceproviderlist");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  get flprovidername () {
    return this.addlargeproviderform.get('flprovidername');
  }
 
  get updatelargeprovidergroup() {
    return this.registerForm.get('updatelargeprovidergroup');
  }
}
