import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EmailAdmin } from '../domain/email-admin';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/email-admins';

@Injectable({
  providedIn: 'root'
})
export class EmailtemplateService {
  baseUrl:any
    
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }

  getAll(): Observable<any> {
    return this.http.get<EmailAdmin[]>(this.baseUrl+serviceUrl);
  }


  getForEschedule(): Observable<any> {
    return this.http.get<EmailAdmin[]>(this.baseUrl+serviceUrl+"/app/ESCH");
  }

  getesamTemplates(userId:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/emtemplate/'+ userId);
  }


  get(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/emtemplate/'+ id);
  }

  create(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  update(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }








}
