import { ChangeDetectionStrategy, Component, OnInit,ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import {perferred} from '../../domain/perferred';
import { targettypes } from '../../domain/targettypes';
import { events } from '../../domain/events';
import { schedulepreferredservices } from '../../domain/schedulepreferredservices'; 
import { perferredservice } from '../../service/perferredservice';
import { targettypesservice } from '../../service/targettypesservice';
import { eventservice } from '../../service/eventservice';
import { schedulepreferredservice } from '../../service/schedulepreferredservice';
import { Validators,FormBuilder} from '@angular/forms';
import { Table } from 'primeng/table';
import { ServiceDetailsService } from '../../service/serviceDetails.service';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { flexperiodsservice } from '../../service/flexperiodsservice';
import { flexDom } from '../../domain/flexperiods';
import { DateUtilService } from '../../service/date-util.service';
import { scheduleevent } from '../../domain/scheduleevent';
import { LoginDataService } from '../../service/loginData.service';
import { AssessmentVisitService } from '../../service/assessmentvisitservice';
import { UserDetailsService } from '../../service/userDetails.service';



@Component({
  selector: 'app-mappingprefservices',
  templateUrl: './mappingprefservices.component.html',
  styleUrls: ['./mappingprefservices.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MappingprefservicesComponent implements OnInit {
  perferred: any;
  perfer : any = {};

  targettypes: targettypes[];
  targettypestemp: targettypes[];
  
  events: events[];
  eventstemp: events[];
  
  spsdata : any[];
  isSubmitted = false;
  sps: schedulepreferredservices;
  @ViewChild('dt') table: Table;
  mappingpreferedMessages: { severity: string; summary: string; detail: string; }[];
  serviceidmatched: boolean;
  allServices: any;
  addpreferredService: boolean;
  nonValidateServiceId: any;
  flexPeriodDom:flexDom[]=[];
  constructor(private confirmationService:ConfirmationService,
    private assessmentVisitService: AssessmentVisitService,private userdetailsservice: UserDetailsService,
    private dateutil:DateUtilService,private loginService:LoginDataService,private serviceDetailsService:ServiceDetailsService,private flexperiodService: flexperiodsservice, private perferredservice: perferredservice,private targettypesservice :targettypesservice,private ConfirmationService:ConfirmationService, private messageService: MessageService,private eventservice:eventservice,private schedulepreferredservice :schedulepreferredservice,public fb: FormBuilder, private scheduleeventservice: scheduleeventservice) { }

  addpreferredform = this.fb.group({
    targetname: ['', [Validators.required]],
    visittype: ['', [Validators.required]],
    serviceid: ['', [Validators.required]],
    flexPeriod: ['', [Validators.required]]
  });

  ngOnInit(): void {

    this.getAllServices();
    this.perferred=[];
    this.perferredservice.getAllPerferredService().subscribe(preferreddata =>{      
      this.perferred = preferreddata; 
      this.table.reset();        
    });

    this.targettypesservice.getTargetTypes().subscribe(targetData=>{
      if(targetData!=null){
        this.targettypestemp = targetData;  
        this.targettypes = [];
        this.targettypestemp.forEach(eledata=>{
          // show only Status=='Active' events 
          if(eledata.status =='Active'){
            this.targettypes.push(eledata);
          }
        }); 
      }
    });


    this.flexperiodService.getActiveFlexPeriods().subscribe(flexdata => {
      flexdata=flexdata.sort( (a,b)=> a.perStartDate > b.perStartDate ? -1:1 );
      flexdata.forEach((element,i) => {
      
        var flexData = new flexDom();
        flexData.name=element.perName + " ("+this.dateutil.getCorrectedDate(element.perStartDate) + "-" + this.dateutil.getCorrectedDate(element.perEndDate)+")";
        flexData.value = element.perName;
        this.flexPeriodDom.push(flexData);
      });
    }
  );
  
    this.eventservice.getEvents().subscribe(eventdata =>{     
      if(eventdata!=null){
        this.eventstemp = eventdata;  
        this.events = [];
        this.eventstemp.forEach(eledata=>{
          // show only Visit='Y' events 
          if(eledata.visit =='Y'){
            this.events.push(eledata);
          }
        }); 
      }
    });
  }

  removePreferredDialog(perferred,serviceid) {
    this.confirmationService.confirm({
        message: 'Are you sure that you want to delete ?',
        accept: () => {
          this.schedulepreferredservice.delete(perferred).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: serviceid + ' Removed successfully'});
            console.log("--update success--");      
            this.table.reset();
            //delete the record in service events if present
            this.scheduleeventservice.updateByPerserId(perferred).subscribe(res2=>{
                console.log("--update success on schedule event service--");  
            },err=>{
              console.log("-- updates failed  schedule event service--");
            })
           
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service removal failed'});
            console.log("-- updates failed --");
          });
        }
    });
  }


  get flexPeriod() {
    return this.addpreferredform.get('flexPeriod');
  }
  onSubmit(){
    var newServiceIds=this.checkServiceId();
    console.log(newServiceIds);
    this.isSubmitted = true;
    if (!this.addpreferredform.valid) {
      return false;
    } else {

        var sps = newServiceIds;
    
      for (let i = 0; i < sps.length; i++) {

        if(sps[i] !==""){
          this.schedulepreferredservice.create(sps[i]).subscribe(res=>{
           
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Preferred service added' });

            //create an event and update the event Id in schedulepreferredservice
            let sse = new scheduleevent;
            sse.serviceId = sps[i].serviceId;
            let obj = this.events.find(el=>el.eventFunId==sps[i].eventKey);
            if(obj!=null)
            {
              sse.eventType = obj.eventKey;        

            }

            sse.futureVisit = 'Y';
            sse.createdBy = "ITD";
            sse.perSerId = res.preSerId;
            sse.periodId = sps[i].periodName;
            

          
            sse.createdDate = new Date();
            sse.title = "AR"+"-"+sps[i].serviceId;
            if(sse.eventType=="V"){
              sse.title = "AR"+"-"+sps[i].serviceId;
            }else{
              sse.title =sps[i].eventKey+"-"+sps[i].serviceId;
            }
            //get visitId and Officer name from the topmost ASR from esam for the service ID
            this.assessmentVisitService.getLatestASR(sps[i].serviceId).subscribe(data => {
              if ( data != null && data.arStatus =='Commencement letter to be sent' ){
                console.log("data.assessor2"+data.assessor2)
                sse.visitId = data.visitId;
                if(data.assessor2!=null && data.assessor2!=undefined)
                {
                  let userid = data.assessor2.toUpperCase().replaceAll(" ",".")
                  console.log("userid"+userid)
                  this.userdetailsservice.getUserforIntUserId(userid).subscribe(ud=>{
                    if(ud!=null && ud!=undefined)
                    {
                    console.log(JSON.stringify(ud))
                    sse.userId= ud.userId;
                    }
                   
                    this.scheduleeventservice.create(sse).subscribe(res2=>{
                      console.log("schedule event creates"+res2.eventId);
                     
                      res.eventId=res2.eventId;
                      this.schedulepreferredservice.update(res).subscribe(data=>{
                        console.log("schedule event updated in preferred service"+res2.eventId);
                    
                        console.log("create success"); 
          
                      })
          
                      },err=>{
                        this.messageService.add({ severity: 'info', summary: 'info', detail: 'Create schedule failed'+ sps[i].serviceId});

                      });
                    
                  },err=>{
                    this.messageService.add({ severity: 'info', summary: 'info', detail: 'Create schedule failed'+ sps[i].serviceId});

                  })

                }
                else{
                  this.messageService.add({ severity: 'info', summary: 'info', detail: 'Officer assignment not found for the service'+ sps[i].serviceId});

                }

          
              this.ngOnInit();
            }else{
              this.messageService.add({ severity: 'info', summary: 'info', detail: 'Visit Id not found for the service'+ sps[i].serviceId});

            }},err=>{
              this.messageService.add({ severity: 'info', summary: 'info', detail: 'Visit Id not found for the service'+ sps[i].serviceId});

            })
            
          
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'success', detail: 'Preferred service failed' });
            console.log("create failed");
          });
        }else{
          this.messageService.add({ severity: 'error', summary: 'success', detail: 'Preferred service failed' });
        }
    }

      this.ngOnInit();
      this.perferredservice.getAllPerferredService().subscribe(preferreddata =>{
        this.perferred = preferreddata; 
        this.table.reset();
      });
    }
  }

  get targetname() {
    return this.addpreferredform.get('targetname');
  }
  get serviceid() {
    return this.addpreferredform.get('serviceid');
  }
  get visittype() {
    return this.addpreferredform.get('visittype');
  }




  exportExcel() {
    let expPrefer = this.perferred.map(function(aPerfer){
    let addLineTwo =  (aPerfer.addresslineTwo==null)?'':aPerfer.addresslineTwo;

    return {
       
              Service_ID : aPerfer.serviceId,
              Service_Name : aPerfer.serviceName,
              Visit_Type : aPerfer.visitType,
              Flex_Period : aPerfer.periodId,
              Service_Type: aPerfer.serviceType,
              Hub : aPerfer.managingRegion,                 
              Address : aPerfer.addresslineOne + " " + addLineTwo
         };
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(expPrefer);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "prefferedServices");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
 ///validation to add a valid service ID
  getAllServices(){
    this.serviceDetailsService.getAllServices().subscribe(servicesdata =>{      
       this.allServices = servicesdata;
    });
    
  }

  onKeydown(event) {
    if (event.keyCode === 32 ) {
      return false;
    }
  }

checkServiceId(){
  
  var services = this.allServices;
  var services_Array = services.map(t=>t.serviceId);

  var input_ServiceId=this.addpreferredform.get("serviceid").value;
  var array_Input_ServiceId = input_ServiceId.split(',');
  
  let nonValidate_ServiceId = array_Input_ServiceId.filter(o1 => ! services_Array.some(o2 => o1 === o2));
  this.nonValidateServiceId= nonValidate_ServiceId;

  let finalValid_ServiceId = services_Array.filter(o1 => array_Input_ServiceId.some(o2 => o1 === o2));
  var _finalValid_ServiceIds_Array = finalValid_ServiceId.map((serviceId) => ({
    targetId: this.addpreferredform.get("targetname").value,
    eventKey: this.addpreferredform.get("visittype").value,
    periodName: this.addpreferredform.get('flexPeriod').value.value,
    serviceId
}));

let uniqueSelectedServiceArrays = _finalValid_ServiceIds_Array.filter(o1 => ! this.perferred.some(o2 => o1.serviceId === o2.serviceId
  && o1.targetId === o2.targetId && o1.eventKey === o2.eventFunId && o1.periodName===o2.periodName));
  console.log("input + prefered service"+finalValid_ServiceId);

  return uniqueSelectedServiceArrays;
  
}

}
