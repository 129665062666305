import { Component, OnInit } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { SSOLogonService } from '../admin/service/ssoLogon.service';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
  cookieValue = 'UNKNOWN';
  constructor(private cookieService: CookieService, private ssologonservice: SSOLogonService) {
  }

  getLoggedSSOUserAttributes(cookieValue: string) {
    this.ssologonservice.getLoggedSSOUserAttributes(cookieValue).subscribe(
      result => {
        console.log('RESULT identity/attributes: ' + result);

      }, error => { console.log('ERROR' + error); });

  }

  getLogin() {
    this.ssologonservice.getLogin(); //(
    this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    console.log('COOKIE ' + this.cookieValue);
  }

  ngOnInit() {

  }

}