import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Validators,FormBuilder, FormGroup} from '@angular/forms';
import { ServiceProviderService } from '../../service/serviceprovider.service';
import { serviceprovider } from '../../domain/serviceprovider';
import { UserDetailsService } from '../../service/userDetails.service';
import { User } from '../../domain/user';
import { hubservice } from '../../service/hubservice';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { scheduleevent } from '../../domain/scheduleevent';
import { ServiceDetailsService } from '../../service/serviceDetails.service';
import { VisitService } from '../../service/visitservice';
import { Visit } from '../../domain/visit';
import { DateUtilService } from '../../service/date-util.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-largeproviderassignments',
  templateUrl: './largeproviderassignments.component.html',
  styleUrls: ['./largeproviderassignments.component.scss']
})
export class LargeProviderAssignmentsComponent implements OnInit {
 

  progressbar=false;
   constructor(private UserDetailsService : UserDetailsService, private dateUtil:DateUtilService,private serviceproviderservice:  ServiceProviderService,
    private scheduleeventservice: scheduleeventservice, private visitservice: VisitService, private hubservice: hubservice,private servicedetails: ServiceDetailsService,private messageService:MessageService) { 
   //get all service providers
  
   }


   selectedUserId: any;
   selecteduserdetails:User;
   futureassignments: scheduleevent[];
   futureassignmentstemp: scheduleevent[];
   serviceproviders : serviceprovider[];
   largeproviderslist: serviceprovider[];
   uniqueLargeProviders:string[];
   uniqueServicesForLP:string[];
   largeserviceproviders: { name: string; value: string; }[]=[];
   largeserviceprovidersforOfficers: { name: string; value: string; }[]=[];
   largeserviceprovidersforHubs: { name: string; value: string; }[]=[];
   selectedLP:string;
   visits: Visit[];
   loginuserrole: string;

  ngOnInit(): void {
    this.selectedUserId =localStorage.getItem("loginuserid");
    this.progressbar=true;
    console.log("this.selectedUserId "+this.selectedUserId );

    this.loginuserrole =localStorage.getItem("loginuserrole");
    console.log("this.loginuserrole "+this.loginuserrole );

      
    this.UserDetailsService.getUserforId(this.selectedUserId).subscribe(userdata =>{   
      this.selecteduserdetails = userdata;
    });

    this.serviceproviderservice.getAllSPDetails().subscribe(data=>{
      this.largeproviderslist = data.filter(el=>el.largeProviderName!=null);
      this.uniqueLargeProviders = [...new Set( this.largeproviderslist.map(item => item.largeProviderName))]; 
      this.uniqueLargeProviders = this.uniqueLargeProviders.sort( (a,b)=> a < b ? -1:1 )
      this.uniqueLargeProviders.forEach(el=>{
        this.largeserviceproviders.push({name:el,value:el});
        
      })

     
      
      this.servicedetails.getAllServices().subscribe(sd=>{
        let serviceslist = sd;
        let backupservicelist = sd;

        console.log(serviceslist.length)
        let servicelist = serviceslist.filter(x => 
          this.largeproviderslist.some(y=> y.serviceProviderId == x.serviceProviderId)
        );

        let hubname = this.selecteduserdetails.region;
        console.log("hubname...."+hubname)
        console.log("selecteduserdetails..."+JSON.stringify(this.selecteduserdetails));
        if(this.loginuserrole == 'ROM' || this.loginuserrole== 'SCHD_ROM_READONLY')
        {
        this.scheduleeventservice.getUserEventsByHub(hubname).subscribe(dt=>{
          console.log("usershublist "+dt.length)
          if(dt!=null)
          {

            dt = dt.sort( (a,b)=> a.largeProviderName< b.largeProviderName ? -1:1 )
           

            dt.forEach(eledata1=>{


              let datafound = servicelist.filter(e=>e.serviceId===eledata1.serviceId);

              //if its a large provider serviceId
              if(datafound && datafound.length>0)
              {
                let serviceProviderName = this.largeproviderslist.find(p=>p.serviceProviderId==datafound[0].serviceProviderId);
                console.log("serviceProviderName"+JSON.stringify(serviceProviderName))
                eledata1.largeProviderName = serviceProviderName.largeProviderName;

                if(eledata1.largeProviderName!=null && eledata1.largeProviderName!="" && this.largeserviceprovidersforHubs.findIndex(el=>el.name===eledata1.largeProviderName)<0)
                {
                  this.largeserviceprovidersforHubs.push({name: eledata1.largeProviderName,value: eledata1.largeProviderName});
                  console.log(" this.largeserviceprovidersforHubs."+ this.largeserviceprovidersforHubs.length)
                }
               
              }


           
            }
              )

          }
        })

      }
     
       
        console.log("length"+servicelist.length)
        this.scheduleeventservice.getUserEventsById(this.selectedUserId).subscribe(ssedata =>{     
          if(ssedata!=null){
            ssedata = ssedata.sort( (a,b)=> a.largeProviderName< b.largeProviderName ? -1:1 )
            this.futureassignmentstemp = ssedata;  
            this.futureassignments = [];
            ssedata.forEach(eledata=>{
              if(eledata.eventType=='V'){
                eledata.eventType="AR"
              }
              //get the selected service details from service details
              let datafound = servicelist.filter(e=>e.serviceId===eledata.serviceId);

              //if its a large provider serviceId
              if(datafound && datafound.length>0)
              {
                eledata.markit=true;
                let serviceProviderName = this.largeproviderslist.find(p=>p.serviceProviderId==datafound[0].serviceProviderId);
                eledata.largeProviderName = serviceProviderName.largeProviderName;
                if(eledata.largeProviderName!=null && eledata.largeProviderName!="" && eledata.futureVisit=='N' && eledata.archived==null && this.largeserviceprovidersforOfficers.findIndex(el=>el.name===eledata.largeProviderName)<0)
                {
                  this.largeserviceprovidersforOfficers.push({name: eledata.largeProviderName,value: eledata.largeProviderName});
  
                }

                if(eledata.futureVisit=='N' && eledata.archived==null ){
                  this.futureassignments.push(eledata)


                }

               
              }
            


            /*  if(eledata.futureVisit=='N' && eledata.archived==null ){
               
                let servicedet = backupservicelist.filter(e=>e.serviceId===eledata.serviceId);
                eledata.providerId=servicedet[0]?.serviceProviderId;

              
                this.futureassignments.push(eledata)
      


              }*/
              
             
            });
            this.progressbar=false;

          }
          else{
            this.progressbar=false;

          }
        });



      },err=>{
        this.progressbar=false;

      })

      


    },err=>{
      this.progressbar=false;
    })


   


   


  }


  exportExcel() {
    let exp_data = this.visits.map(function(aUser){

     
  let datePipe = new DatePipe("en-AU");
  

      return {ServiceId: aUser.serviceId,
             ServiceName: aUser.serviceName,
             Hub: aUser.managingRegion,
             Officer1: aUser.firstOfficerFirstName + ' '+ aUser.firstOfficerLastName,
             Officer2: aUser.secondOfficerFirstName + ' '+ aUser.secondOfficerLastName,
             visitDates: (aUser.startDate!=null? datePipe.transform(aUser.startDate.toString().substring(0,10), 'dd/MM/yyyy'): '' )+ "  "+(aUser.endDate!=null? datePipe.transform(aUser.endDate.toString().substring(0,10), 'dd/MM/yyyy'): '')
            };
    })
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(exp_data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "serviceproviderlist");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}

searchedData: any;
onTableFiltered(event,data){
  this.searchedData=data;
}
  servicesforlp : Visit[];
  getAllServicesforLP()
  {
 
    this.progressbar=true;

    this.servicedetails.getAllServices().subscribe(res=>{
      console.log(this.largeproviderslist.length)
      let providerslist = this.largeproviderslist.filter(prov=>prov.largeProviderName===this.selectedLP);

      let uniqueProvidersForLP = [...new Set( providerslist.map(item => item.serviceProviderId))]; 
      console.log("uniqueProvidersForLP"+uniqueProvidersForLP);


     // console.log("filtered list"+res.filter(item => item.serviceProviderId == 'PR-00005308'));

      this.servicesforlp= res.filter(item => uniqueProvidersForLP.includes(item.serviceProviderId));

      this.uniqueServicesForLP = [...new Set( this.servicesforlp.map(item => item.serviceId))]; 
      console.log("uniqueServicesForLP"+this.uniqueServicesForLP);

      this.visitservice.getVisitsPreferredServices().subscribe(data =>{     
        this.visits = data;

       this.visits= data.filter(item => (this.uniqueServicesForLP.includes(item.serviceId) && item.archived!=='Y'));
        //get only scheduled visits
       this.visits= this.visits.filter(item => item.futurVisit==='N');

       console.log("after.."+this.visits.length)

       console.log("this.loginuserrole"+this.loginuserrole)
        console.log("this.selecteduserdetails.hub"+this.selecteduserdetails.region)
      /* if(this.loginuserrole === 'ROM' || this.loginuserrole === 'SCHD_ROM_READONLY')
       {

        this.visits= this.visits.filter(el => ((el.managingRegion === this.selecteduserdetails.region) || (el.firstOfficerHub === this.selecteduserdetails.region) || (el.secondOfficerHub === this.selecteduserdetails.hub)) );
      }*/

      
      //  this.visits= this.visits.filter(it => it.firstOfficerFirstName!=null || it.secondOfficerFirstName!=null);
      this.progressbar=false;

        
      },err=>{
        this.progressbar=false;

      });

      this.progressbar=false;


    },err=>{
      this.progressbar=false;

    })

  }
  getCorrectedDate(d: string) {
    return this.dateUtil.getCorrectedDate(d);
  }
}