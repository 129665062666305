import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Visit} from '../domain/visit';
import { AppConfigService } from './AppConfigService';

const getUrl = '/api/scheduleview';
const getUrlPending = '/api/scheduleview/pending';
const geteventUrl = '/api/scheduleview/findbyeventid';
@Injectable()
export class VisitService {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getVisits(): Observable<any>{
         return this.http.get<Visit[]>(this.baseUrl+getUrl);  
    }

    getPendingVisits(): Observable<any>{
        return this.http.get<Visit[]>(this.baseUrl+getUrlPending);  
   }

   getPendingVisitsForRegion(regionName:any): Observable<any>{
    return this.http.get<Visit[]>(this.baseUrl+getUrlPending+'/'+regionName);  
   }

    getVisitsPreferredServices(): Observable<any>{
        return this.http.get<Visit[]>(this.baseUrl+getUrl+"/preferredservices");  
   }

  
    getEventsById(eventId:any): Observable<any> {
        return this.http.get<Visit>(this.baseUrl+geteventUrl+'/'+ eventId);  
    } 
    
    getPeriodsById(perName:any): Observable<any> {
        return this.http.get(this.baseUrl+'/api/scheduleperiodsname/'+ perName);  
    } 

    getCurrentPeriod(): Observable<any> {
        return this.http.get(this.baseUrl+'/api/getcurrentflexeperiodaspertodaysdate');  
    }

    getFilteredSchedule(data): Observable<any> {
        return this.http.post<Visit[]>(this.baseUrl + getUrl+"/findfilterSchedule",data);
    }
}