import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute, Router } from "@angular/router";
import { SSOLogonService } from '../admin/service/ssoLogon.service';
import { LoginDataService } from '../admin/service/loginData.service';
import { UserDetailsService } from '../admin/service/userDetails.service';
import { AppConfigService } from '../admin/service/AppConfigService';
import { User } from '../admin/domain/user';

@Component({
  selector: 'app-oauth2',
  templateUrl: './oauth2.component.html',
  styleUrls: ['./oauth2.component.scss']
})
export class Oauth2Component implements OnInit {


  code: string;
  id_token: any;
  access_token: string;
  refresh_token: string;
  myRawToken: any;
  firstName: string;
  username: string;
  surname: string;
  displayLoginName: string;
  login_hint :string;
  email: string;
  status: string;
  roleId: string;
  cookieValue = 'UNKNOWN';
  baseUrl: any;
  ssourl: any;
  uiurl: any;
  clientId:any;
  ssosecret: any;
  loginuserrole: any;
  officers: User[];
  
  constructor(private ssologonService: SSOLogonService,
    private cookieService: CookieService,
    private route: ActivatedRoute, private config: AppConfigService,
    private router: Router, private loginService: LoginDataService, private userDetailsService: UserDetailsService) {

    this.baseUrl = this.config.getConfig().baseUrl;
    this.uiurl = this.config.getConfig().uiurl;
    this.ssourl = this.config.getConfig().ssoURL;
    this.ssosecret=this.config.getConfig().ssosecret;
    this.clientId=this.config.getConfig().clientId;

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params) => {
        this.code = params['code'];

        console.log('SSO code: ' + params['code']);
      }
    )
    this.ssologonService.getOAuth2AccessToken(this.code).subscribe(
      result => {
        var stringifiedData = JSON.stringify(result);
        var parsedJson = JSON.parse(stringifiedData);
        //console.log('getOAuth2AccessToken RESULT: ' + parsedJson['id_token']);
        this.id_token = parsedJson['id_token'];
        //console.log('SSO id_token: ' + this.id_token);
        this.access_token = parsedJson['access_token'];
        console.log('SSO access_token: ' + this.access_token);
        
        this.refresh_token = parsedJson['refresh_token'];
        //console.log('SSO refresh_token: ' + this.refresh_token);
        this.myRawToken = this.id_token.toString();
        localStorage.setItem('access_token', this.access_token);
        localStorage.setItem('id_token',this.id_token);
        localStorage.setItem('baseUrl', this.baseUrl);
        localStorage.setItem('uiurl', this.uiurl);
        localStorage.setItem('ssourl', this.ssourl);
        localStorage.setItem("ssosecret",this.ssosecret);
        localStorage.setItem("refresh_token",this.refresh_token);
        localStorage.setItem('clientId',this.clientId);
            //set this to service object
        this.loginService.setToken(this.myRawToken);
        const helper = new JwtHelperService();
        const rawToken = helper.decodeToken(this.myRawToken);
        const expirationDate = helper.getTokenExpirationDate(this.myRawToken);
        const isExpired = helper.isTokenExpired(this.myRawToken);
        this.firstName = rawToken.givenName;        
        this.surname = rawToken.sn;
        this.username = rawToken.uid;
        this.displayLoginName = this.firstName + ' ' + this.surname;
        this.login_hint  = rawToken.login_hint ;
        localStorage.setItem("login_hint",this.login_hint);
        console.log("login_hint"+this.login_hint)
        
        console.log("----rawToken------"+JSON.stringify(rawToken));
                
        console.log("DECODED username: " + this.username);
        console.log("DECODED TOKEN name toString(): " + this.displayLoginName);
        console.log("DECODED TOKEN expirationDate: " + expirationDate);
        console.log("DECODED TOKEN isExpired: " + isExpired);
        localStorage.setItem('expirationDate', ''+expirationDate);
        localStorage.setItem('displayLoginName', this.displayLoginName);
        localStorage.setItem('firstName', this.firstName);
        localStorage.setItem('surname', this.surname);
        localStorage.setItem('status', this.status);        
        localStorage.setItem('username', this.username);
        // validate user based on username received from oauth 
        this.userDetailsService.getActiveUsers().subscribe(userData=>{
          if(userData!=null){
            this.officers =userData;                   
            this.officers.forEach(eledata=>{
              // show only active users              
              if(eledata!=null && eledata.status =='Active' && eledata.intUserId!=null && eledata.intUserId.toLowerCase() == this.username.toLowerCase()){                
              console.log("---intid/role-----"+eledata.status+"--"+eledata.intUserId+"--"+eledata.roleId);  
                localStorage.setItem('loginuserrole', eledata.roleId); 
                localStorage.setItem('loginuserid', eledata.userId);
                this.loginuserrole =localStorage.getItem("loginuserrole");        
                if (isExpired) {
                   localStorage.setItem('isExpired', "expired");
                } else if (this.loginuserrole == "ACO"){          
                  this.router.navigate(['/home']);
                }else if (this.loginuserrole == "SCHD_ACO_READONLY"){          
                  this.router.navigate(['/home']);
                }
                 else if (this.loginuserrole == "ROM"){          
                  this.router.navigate(['/hubcohome']);          
                }  else if (this.loginuserrole == "SCHD_ROM_READONLY"){          
                  this.router.navigate(['/hubcohome']);          
                }  else if (this.loginuserrole == "SCHD_ADMIN"){
                  this.router.navigate(['/summary']);
                } else {
                  this.router.navigate(['/access']);
                }
              }                                  
            });         
          }             
        });        

        //role is from DB SCHD_ADMIN,ACO,ROM
        this.loginuserrole =localStorage.getItem("loginuserrole");        
        if (isExpired) {
           localStorage.setItem('isExpired', "expired");
        } 
      }, error => {
        localStorage.removeItem('displayLoginName');
        console.log('getOAuth2AccessToken ERROR' + JSON.stringify(error));
      });

  }

  getLogin() {
    this.ssologonService.getLogin(); //(
    this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    console.log('COOKIE ' + this.cookieValue);
  }

}
