import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { forwardRef, Inject, Injector, SkipSelf } from '@angular/core';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VisitService } from './visitservice';


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  ssourl:any="";
  uiurl:any="";
  baseUrl:any="";
  clientId:any="";
  http: HttpClient;
  constructor(){
   
  }
  
   intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
    this.baseUrl = localStorage.getItem('baseUrl');
    this.ssourl =localStorage.getItem('ssourl');
    this.uiurl =localStorage.getItem('uiurl');
    this.clientId =localStorage.getItem('clientId');
    var isbackend = false;
    this.http = new HttpClient(next);
     if(this.baseUrl!==null){
      isbackend = req.url.indexOf(this.baseUrl)!==-1?true:false;
     }
  
   // var tokenval = localStorage.getItem("access_token");
   var tokenval = localStorage.getItem("id_token");
    if (req!==null &&  tokenval!==null && (isbackend)) {
      req = req.clone({
        headers: req.headers.set("ssotoken", ''+tokenval)
        
      });
      const expirationDate = localStorage.getItem("expirationDate");
      let newDate = new Date(''+expirationDate);
      var now = new Date();
      if(newDate>now){
        var diff = newDate.getTime() - now.getTime();
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
       
        if(minutes>0 && minutes<5){
        
        //  this.visitservice = this.injector.get(VisitService);
          this.getOAuth2AccessToken().subscribe((resp)=>{
            var stringifiedData = JSON.stringify(resp);
            var parsedJson = JSON.parse(stringifiedData);
            localStorage.setItem('access_token',parsedJson['access_token']);
            localStorage.setItem('refresh_token',parsedJson['refresh_token']);
            const helper = new JwtHelperService();
            const expirationDate = helper.getTokenExpirationDate(parsedJson['id_token']);

          
                
            localStorage.setItem("expirationDate",''+expirationDate);
            tokenval = localStorage.getItem("access_token");
            req = req.clone({
              headers: req.headers.set("ssotoken", ''+tokenval)
            });

            if(isbackend){
             return next.handle(req).pipe(catchError(this.erroHandler));
            } else{
              return next.handle(req);
            }

          },err=>{
           
            console.log(err);
            window.location.href=this.ssourl+'/logout?goto='+this.uiurl+'/oauth2/logout';
          });
        }
        else{
          if(isbackend){
           return next.handle(req).pipe(catchError(this.erroHandler));
          }
          else{
            return next.handle(req);
          }
        }
      }else{
        //token expired already
        window.location.href=this.ssourl+'/logout?goto='+this.uiurl+'/oauth2/logout';
      }
    }
    return next.handle(req);
  }

  erroHandler(error: HttpErrorResponse) {
    this.ssourl =localStorage.getItem('ssourl');
    this.uiurl =localStorage.getItem('uiurl');
   
    console.log("error"+JSON.stringify(error));
   
    if(error!==null && error.error.status===400){
      window.location.href=this.ssourl+'/logout?goto='+this.uiurl+'/oauth2/logout';
    }
    return throwError(error.message || 'server Error');
  }

  getOAuth2AccessToken (): Observable<JSON> {
    this.ssourl =localStorage.getItem('ssourl');
    // tslint:disable-next-line:max-line-length
    const refresh_token = localStorage.getItem("refresh_token");
   // const ssosecret = localStorage.getItem("ssosecret");
   const verifier = localStorage.getItem('verifier')
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
      })
    };
    // const responseType = { response: new } responseType = 'json';
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
     //.set('client_id', 'eschedule')
     .set('client_id',  this.clientId )
      .set('refresh_token', ''+refresh_token)
     // .set('client_secret', '0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0')
    // .set('client_secret', ''+ssosecret)
    .set('code_verifier', ''+verifier)
         

    //return this.http.post<any>(this.ssourl+'/oauth2/access_token', body.toString(), httpOptions);
    return this.http.post<any>(this.ssourl+'/token', body.toString(), httpOptions);
    
  }

 

 
}