import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {targettypes} from '../domain/targettypes';
import { AppConfigService } from './AppConfigService';
import { Observable } from 'rxjs';

const getUrl = '/api/scheduletargettypes';

@Injectable()
export class targettypesservice {
    
    baseUrl : any;
    constructor( private http: HttpClient,private config: AppConfigService) {
     this.baseUrl = this.config.getConfig().baseUrl;
    }

    getTargetTypes() : Observable<any>{
         return this.http.get<targettypes[]>(this.baseUrl+getUrl+"/nameasc");  
    }
    create(data:any): Observable<any> {
        return this.http.post(this.baseUrl+getUrl, data);
    }
    getTargetbyId(targetid:any) : Observable<any>{
        return this.http.get<targettypes>(this.baseUrl+getUrl+'/'+ targetid);
    }

    update(data:any): Observable<any> {   
        return this.http.put(this.baseUrl+getUrl, data);
    }

}