import { DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { bulkemail } from '../../domain/bulkemail';
import { flexDom, flexperiods } from '../../domain/flexperiods';
import { scheduleevent } from '../../domain/scheduleevent';
import { BulkoperationService } from '../../service/bulkoperationservice';
import { DateUtilService } from '../../service/date-util.service';
import { flexperiodsservice } from '../../service/flexperiodsservice';
import { hubservice } from '../../service/hubservice';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { VisitService } from '../../service/visitservice';
const datePipe = new DatePipe("en-AU");


@Component({
  selector: 'app-bulkoperations',
  templateUrl: './bulkoperations.component.html',
  styleUrls: ['./bulkoperations.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class BulkoperationsComponent implements OnInit {

  flexperiods: flexperiods[];
  loggedInUserId : any;
  releasePeriodType:any;
  hubManagerRemType: any;
  yearPeriod: string;
  periodType: string;
  bulkType: string;
  eventType: string;
  progressbar:boolean;
  selectedCity1: any;
  flexPeriodDom:flexDom[]=[];
  todaydate:Date;
  type = [
    { name: 'Email', value: 'E' },
  ];
  eventType_ = [
    { name: 'Choose event', value: '' },
    { name: 'AR Visit', value: 'V' },
    { name: 'Compliance', value: 'COM' },
    { name: 'Change Of Ownership', value: 'Cofo' },
    { name: 'Post Approval', value: 'PA' },

  ];
  remindertype =[
    {name:'Leave Due Date', value:'D'},
    {name:'Services awaiting without date', value:'S'}
  ]
  events_Hub :any[]=[];
  
  eventsEventType = [
    {name:'Conference', value:'CF'},
    {name:'Team Meeting', value:'M'},
    {name:'Training', value:'T'},
  ]

  constructor(private dateutil:DateUtilService,private flexperiodService:flexperiodsservice,private visitservice: VisitService,
    private hubservice: hubservice,private scheduleeventservice: scheduleeventservice,private confirmationService: ConfirmationService,
    public fb: FormBuilder,  private bulkoperationService: BulkoperationService, private messageService:MessageService, private userdetailsservice: UserDetailsService) { }

  bulkemailform: bulkemail = {};
  bulkEvents:bulkemail={};
  bulkVisitOfficer:bulkemail={};
  bulkEmailReminders:bulkemail={};
  hubs: any;

  ngOnInit(): void {
    this.todaydate=new Date();
    this.events_Hub.push({name:'All Hubs',value:'all'});
    
    this.flexperiodService.getActiveFlexPeriods().subscribe(flexdata => {
        this.flexperiods = flexdata;
        flexdata=flexdata.sort( (a,b)=> a.perStartDate > b.perStartDate ? -1:1 );
        flexdata.forEach((element,i) => {
          if (i==1) {
            this.bulkemailform.yearPeriod=this.flexPeriodDom[0];
            this.bulkEmailReminders.yearPeriod = this.flexPeriodDom[0];
            this.bulkVisitOfficer.yearPeriod = this.flexPeriodDom[0];
          }
          var flexData = new flexDom();
          flexData.name=element.perName + " ("+this.dateutil.getCorrectedDate(element.perStartDate) + "-" + this.dateutil.getCorrectedDate(element.perEndDate)+")";
          flexData.value = element.perName;
          this.flexPeriodDom.push(flexData);
        });
      }
    );

     /* added this code for default select */
     this.bulkemailform.bulkType={ "name": "Email", "value": "E" };
     this.bulkemailform.evenTtype={ "name": "AR Visit", "value": "V" };
     this.bulkemailform.periodType="fp";

     this.bulkEmailReminders.reminderType={name:'Leave Due Date', value:'D'};
     this.gethubs();
     
  }
  sendbulkemail=false;
  sendbulkEmail(): void {

    this.confirmationService.confirm({
      message: 'Are you sure you want to send bulk email for Flex Period '+ this.bulkemailform.yearPeriod.value+'?',
      accept: () => {
        let hubselections = this.bulkemailform.hub.map(item=>item.name).join(",");

    this.progressbar=true;
    this.sendbulkemail=true;
    const bulkemail = {
      loggedInUserId : localStorage.getItem("loginuserid"),
      yearPeriod: this.bulkemailform.yearPeriod.value, 
      periodType:this.bulkemailform.periodType,
      bulkType: this.bulkemailform.bulkType.value,
      eventType : this.bulkemailform.evenTtype.value,
      hub:hubselections
    };

    console.log("bulkemail..."+JSON.stringify(bulkemail))

    this.bulkoperationService.sendBulkemail(bulkemail).subscribe(
      res=>{
        this.progressbar=false;
        this.sendbulkemail=false;
        this.messageService.add({ severity: 'success', summary: 'success', detail: res + ' emails sent.'});
        console.log("-- Bulk email sent --");      
      },err=>{
        this.progressbar=false;
        this.sendbulkemail=false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'No emails sent.'});
        console.log("-- Bulk email  failed--");      
      }
    );
      }
    });

  }


  archiveAllForPeriod()
  {
    this.confirmationService.confirm({
      message: 'Are you sure you want to archive events for Flex Period '+ this.bulkEmailReminders.yearPeriod.value+'?',
      accept: () => {
    this.progressbar=true;
   // alert(""+ this.bulkEmailReminders.yearPeriod.value);
 // this.scheduleeventservice.getscheduleforperiodId(this.bulkEmailReminders.yearPeriod.value).subscribe(
    this.visitservice.getVisitsPreferredServices().subscribe(
    ret =>{
      let removearchived = ret.filter(ell=> ell.archived==null && ell.periodId===this.bulkEmailReminders.yearPeriod.value)
      //POSTPONED, SPARE, SURRENDERED, SUSPENDED, TRANSFER - excluded
      let filteredlist = removearchived.filter(el=> (el.startDate==null && el.firstOfficerFirstName !==null && el.firstOfficerFirstName.trim() !== 'POSTPONED' && el.firstOfficerFirstName.trim() !== 'SPARE' &&  el.firstOfficerFirstName.trim() !== 'SUSPENDED' &&  el.firstOfficerFirstName.trim() !== 'TRANSFER' && el.firstOfficerFirstName.trim() !== 'SURRENDERED'))

      if(filteredlist.length==0)
      {
          this.scheduleeventservice.updatescheduleForFlexPeriod(this.bulkEmailReminders.yearPeriod.value).subscribe(res=>{
                this.progressbar=false;
                this.messageService.add({ severity: 'success', summary: 'success', detail: 'Archive successful'});
              },err=>{
                console.log("-- Archieve failed--");      
                this.progressbar=false;
          
              })

      }
      else{
        let servicelist=""; 
         console.log("filteredlist"+filteredlist)

        filteredlist.forEach(element=>{
       
            servicelist=servicelist+","+element.serviceId;
        
        })
        this.progressbar=false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'update Dates for all visits in this Flex Period for services '+ servicelist});


      }


   
    },err=>{
     
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'update failed'});

    }
   )

  }
})

  }


  gethubs(){
    this.hubservice.getHubs().subscribe(hubData=>{
      if(hubData!=null){
     //  console.log("hubData"+JSON.stringify(hubData))
       
        hubData.forEach(eledata=>{
          if(eledata.status =='Active'){
            this.events_Hub.push({name:eledata.hubName, value:eledata.hubId});            
          }
        }); 
      }
    });
  }
  startdate: Date;
  enddate: Date;
  sse :scheduleevent;
  bulkEventType:any;
  bulkHub:any;
  //stoperrorrepeat:boolean=false;
  addBulkEvent(){
   
    this.messageService.clear();
    console.log(this.startdate);
    console.log(this.enddate);
   // console.log(this.bulkEventType.value)
   // console.log(this.bulkHub.value)
    console.log("update events");

    if(this.startdate==undefined || this.startdate==null || this.enddate==undefined || this.enddate==null 
      || this.bulkEventType==undefined || this.bulkEventType==null || this.bulkHub==undefined || this.bulkHub==null)
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All fields are required' });
      return;
    }
    if(this.dateLessThan(new Date(this.startdate),new Date(this.enddate))){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {
    
            
        this.confirmationService.confirm({
        message: 'Do you wish to add bulk event?',
        accept: () => {
       this.sse={}; 
      var startDate = new Date(this.startdate);
      var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
      var endDate = new Date(this.enddate);
      var endOfDayDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
        this.sse.startDate=startOfDayDate;
        this.sse.endDate=endOfDayDate;
     
       this.sse.eventType=this.bulkEventType.value;
       if(this.bulkEventType.value=='CF')
       {
        this.sse.title="Conference";
        this.sse.description="Conference";

       }
       else if(this.bulkEventType.value=='M')
       {
        this.sse.title="Meeting";
        this.sse.description="Meeting";
       }
       else if(this.bulkEventType.value=='T')
       {
        this.sse.title="Training";
        this.sse.description="Training";
       }
        this.sse.createdBy = this.loggedInUserId;
        this.sse.createdDate = new Date();

        if(this.bulkHub.value!='All')
        {
          //get all users for this Hub
          this.userdetailsservice.getActiveUsersByRegions(this.bulkHub.value).subscribe(userData=>{
         //   this.stoperrorrepeat=true;
     
            console.log("userdata"+this.bulkHub.value+"data... "+userData);
            
            // section = userData;
            userData=userData.filter(e=>(e.status=='Active'));
            if(userData!=null)
            {
              this.progressbar=true;
            }
            userData.forEach((u,index,array)=>{
              this.sse.userId=u.userId;
              console.log(u.userFirstName+"  "+ u.userId+"   "+JSON.stringify(this.sse))
              this.scheduleeventservice.create(this.sse).subscribe(res=>{

                if(index===array.length-1)
                {
               
                  this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event created.'});
                }
             
              
                console.log("--update success--");  
                this.progressbar=false;    
               
              },err=>{
                this.messageService.add({ severity: 'error', summary: 'success', detail: 'Bulk event creation failed.'});
                console.log("-- updates failed --");
                this.progressbar=false;    
              });


            })

          })
        }

        if(this.bulkHub.value=='All')
        {
          //get all users for this Hub
          this.userdetailsservice.getActiveUsers().subscribe(userData=>{
     
            console.log("userdata"+this.bulkHub.value+"data... "+userData);
            
            // section = userData;
            userData=userData.filter(e=>(e.status=='Active'));
            if(userData!=null)
            {
              this.progressbar=true;
            }
            userData.forEach((u,index,array)=>{
              this.sse.userId=u.userId;
              console.log(u.userFirstName+"  "+ u.userId+"   "+JSON.stringify(this.sse))
              this.scheduleeventservice.create(this.sse).subscribe(res=>{
                if(index===array.length-1)
                {
               
                  this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event created.'});
                }
              //  this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event created.'});
                console.log("--update success--");   
                this.progressbar=false;       
               
              },err=>{
                this.messageService.add({ severity: 'error', summary: 'success', detail: 'Bulk event creation failed.'});
                console.log("-- updates failed --");
                this.progressbar=false;    
              });


            })

          })
        }
      
      
      }
    })
   
    }
  
  }

  deleteBulkEvent()
  {
      
    console.log("delete events");

    if(this.startdate==undefined || this.startdate==null || this.enddate==undefined || this.enddate==null 
      || this.bulkEventType==undefined || this.bulkEventType==null || this.bulkHub==undefined || this.bulkHub==null)
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'All fields are required' });
      return;
    }  
    if(this.dateLessThan(new Date(this.startdate),new Date(this.enddate))){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
      return;
    } else {
      this.confirmationService.confirm({
        message: 'Do you wish to delete bulk event?',
        accept: () => {

      if(this.bulkHub.value!='All')
      {
        //get all users for this Hub
      
        this.userdetailsservice.getActiveUsersByRegions(this.bulkHub.value).subscribe(userData=>{
   
          console.log("userdata"+this.bulkHub.value+"data... "+userData);
          
          // section = userData;
          if(userData==null || userData==undefined || userData.length==0)
          {
            this.progressbar=false;
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'No events found to be deleted.'});
            return;
          }
          userData=userData.filter(e=>(e.status=='Active'));
          if(userData!=null)
          {
            this.progressbar=true;
          }
          else if(userData==null || userData==undefined || userData.length==0)
          {
            this.progressbar=false;
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'No events found to be deleted.'});
            return;
          }
        
          userData.forEach((u,index,array)=>{
           
           
            this.scheduleeventservice.getUserEventsById(u.userId).subscribe(userevents=>{
          
              var startOfDayDate = new Date(this.startdate.getFullYear(),this.startdate.getMonth(),this.startdate.getDate(),0,0,1);
              var endOfDayDate = new Date(this.enddate.getFullYear(),this.enddate.getMonth(),this.enddate.getDate(),23, 59, 59);
             // console.log("userevents before delete"+this.bulkEventType.value);
              userevents=userevents.filter(el=>(((new Date(el.startDate)).getTime()==startOfDayDate.getTime()) && (((new Date(el.endDate)).getTime()==endOfDayDate.getTime())) &&  el.eventType === this.bulkEventType.value))
              console.log("userevents filtered"+JSON.stringify(userevents));
              if(userevents==undefined || userevents==null || userevents.length==0)
              {
                this.progressbar=false;
                if(index===array.length-1)
                {
                this.messageService.add({ severity: 'error', summary: 'error', detail: 'No events found to be deleted.'});
                return;
                }
              }
              userevents.forEach(eve=>{
                this.scheduleeventservice.delete(eve.eventId).subscribe(res2=>{
              
                  if(index===array.length-1)
                  {
               
                    this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event deleted.'});
                  }
                  
               
                  console.log("--delete success--");    
                  this.progressbar=false;
                },err=>{
                  this.messageService.add({ severity: 'error', summary: 'success', detail: 'Bulk event deletion failed.'});
                  console.log("-- delete failed --");
                  this.progressbar=false;
                })
              })
            

            })
           


          })

        })
      }
      if(this.bulkHub.value=='All')
      {
        //get all users for this Hub
        this.userdetailsservice.getActiveUsers().subscribe(userData=>{
   
          console.log("userdata"+this.bulkHub.value+"data... "+userData);
          
          // section = userData;
          userData=userData.filter(e=>(e.status=='Active'));
          if(userData!=null)
          {
            this.progressbar=true;
          }
          userData.forEach((u,index,array)=>{
           
           
            this.scheduleeventservice.getUserEventsById(u.userId).subscribe(userevents=>{
          
              var startOfDayDate = new Date(this.startdate.getFullYear(),this.startdate.getMonth(),this.startdate.getDate(),0,0,1);
              var endOfDayDate = new Date(this.enddate.getFullYear(),this.enddate.getMonth(),this.enddate.getDate(),23, 59, 59);
           
              userevents=userevents.filter(el=>(((new Date(el.startDate)).getTime()==startOfDayDate.getTime()) && (((new Date(el.endDate)).getTime()==endOfDayDate.getTime())) && el.eventType === this.bulkEventType.value))
              console.log("userevents"+JSON.stringify(userevents));
              if(userevents==undefined || userevents==null || userevents.length==0)
              {
                this.progressbar=false;
              }
              userevents.forEach(eve=>{
                this.scheduleeventservice.delete(eve.eventId).subscribe(res2=>{
                  if(index===array.length-1)
                  {
                  
                    this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event deleted.'});
                  }
              //    this.messageService.add({ severity: 'success', summary: 'success', detail: 'Bulk event deleted.'});
                  console.log("--delete success--");    
                  this.progressbar=false;
                },err=>{
                  this.messageService.add({ severity: 'error', summary: 'success', detail: 'Bulk event deletion failed.'});
                  console.log("-- delete failed --");
                  this.progressbar=false;
                })
              })
            

            })
           


          })

        })
      }
    }
  })
    }
 // }
//})
  }

  dateLessThan(fstartdate: Date, fenddate: Date) {
    let startdate = new Date(fstartdate.getFullYear(), fstartdate.getMonth(), fstartdate.getDate(), 0, 0, 0);
   let enddate = new Date(fenddate.getFullYear(), fenddate.getMonth(), fenddate.getDate(), 0, 0, 0);
   if (enddate.getTime() < startdate.getTime()) {    
    return true;
   }   
   return false;  
  }
  commsdueDate:Date=null;
  releaseVisitsToHubs(){
    this.confirmationService.confirm({
      message: 'Are you sure you want to release visits to Hubs for Flex Period '+ this.bulkVisitOfficer.yearPeriod.value+'?',
      accept: () => {
    this.progressbar=true;
    const bulkemailVisitAO = {
     loggedInUserId : localStorage.getItem("loginuserid"),
      releasePeriodType: this.bulkVisitOfficer.yearPeriod.value,
    };
    
    //console.log("HubName for bulk release"+this.bulkHub.value)

    if(this.commsdueDate!=null &&  this.bulkHub!=null && this.bulkHub!=undefined && this.commsdueDate!=undefined)
    {


      var endOfDaycommsDate = new Date(this.commsdueDate.getFullYear(), this.commsdueDate.getMonth(), this.commsdueDate.getDate(), 23, 59, 59);

   
    this.bulkoperationService.releaseVisitsToHubs(bulkemailVisitAO,this.bulkHub.name,endOfDaycommsDate.toString()).subscribe(
      res=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Visits released to Hub Coordinators'});
        console.log("-- Visits released to Officers --");      
      },err=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Visits released to Hub Coordinators failed.'});
        console.log("-- Visits released to Officers failed--");      
      }
      );
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Due Date, FlexPeriod and Hub Name are mandatory'});

    }
  }
})
  }

  releaseVisitsToOfficers(){
    this.confirmationService.confirm({
      message: 'Are you sure you want to release visits to all officers for Flex Period '+ this.bulkVisitOfficer.yearPeriod.value+'?',
      accept: () => {
    this.progressbar=true;
    const bulkemailVisitAO = {
     loggedInUserId : localStorage.getItem("loginuserid"),
      releasePeriodType: this.bulkVisitOfficer.yearPeriod.value,
    };
    
    

    this.bulkoperationService.releaseVisitsToOfficers(bulkemailVisitAO).subscribe(
      res=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Visits released to Officers.'});
        console.log("-- Visits released to Officers --");      
      },err=>{
        this.progressbar=false;
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Visits released to Officers failed.'});
        console.log("-- Visits released to Officers failed--");      
      }
      );
    }
  });
  }

  sendemailremindersclk=false;
  sendEmailReminders(){
      this.confirmationService.confirm({
      message: 'Are you sure you want to send reminder email for Flex Period '+ this.bulkEmailReminders.yearPeriod.value+'?',
      accept: () => {
    this.progressbar=true;
    this.sendemailremindersclk=true;

    
    let hubselections = this.bulkEmailReminders.hub.map(item=>item.name).join(",");
    console.log("-- Email reminder to Hub Managers. --"); 
   
    const bulkemailReminder = {
      remPeriodType: this.bulkEmailReminders.yearPeriod.value,
      reminderType: this.bulkEmailReminders.reminderType.value,
      hub:hubselections
    };
    this.bulkoperationService.sendHubManagerReminderEmail(bulkemailReminder).subscribe(
      res=>{
        this.progressbar=false;
        this.sendemailremindersclk=false;
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Email reminders sent successfully.'});
        console.log("-- Email reminder sent to Hub Managers. --");      
      },err=>{
        this.progressbar=false;
        this.sendemailremindersclk=false;
        this.messageService.add({ severity: 'error', summary: 'success', detail: 'Email reminders failed to send.'});
        console.log("-- Email reminder to Hub Managers failed--");      
      } );

    }
  })

  }

}
