import { Component, OnInit } from '@angular/core';
import { termholidays } from '../../domain/termholidays';
import { publicholidays } from '../../domain/publicholidays';
import { holidaysservices } from '../../service/holidaysservices';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HoliDay } from '../../domain/holiday';
import { environment } from 'src/environments/environment';
import { DateUtilService } from '../../service/date-util.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss']
})
export class HolidaysComponent implements OnInit {

  termholidays: termholidays[];
  publicholidays: publicholidays[];
  publicholidaysmodal: boolean;
  registerForm: FormGroup;
  publicHoliday: HoliDay;
  publicHolidaysForm = this.formBuilder.group({
    holiDesc: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });

  termHolidaysForm = this.formBuilder.group({
    termDesc: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });
  isSubmitted: boolean;
  termholidaysmodal: boolean;
  termholiday: HoliDay;
  editPublicHolidaysModal: boolean;
  editTermHolidaysModal: boolean;
  selectedpublicHoliday: publicholidays;
  selectedTermHoliday: termholidays;
  constructor(private dateutil:DateUtilService,private holidaysservices: holidaysservices, private formBuilder: FormBuilder, private messageService: MessageService) { }

  ngOnInit(): void {
    this.holidaysservices.getTermHolidays().subscribe(termholidaysdata => {
      this.termholidays = termholidaysdata;
      this.termholidays = this.termholidays.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));

      
    });

    this.holidaysservices.getPublicHolidays().subscribe(publicholidaydata => {
      this.publicholidays = publicholidaydata;
      this.publicholidays = this.publicholidays.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));

    });

    // register all forms groups in formbuilder 
    this.registerForm = this.formBuilder.group({

      updateholDescgroup: this.formBuilder.group({
        fholDesc: ['', [Validators.required]]
      }),

      updatestartDategroup: this.formBuilder.group({
        fStartDate: ['', [Validators.required]]
      }),

      updateendDategroup: this.formBuilder.group({
        fEndDate: ['', [Validators.required]]
      }),

    });
  }

  exportExcelth() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.termholidays);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "termholidays");
    });
  }
  exportExcelph() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.publicholidays);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "publicholidays");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  showpublicholidaysmodal() {
    this.isSubmitted=false;
    this.publicHolidaysForm.reset();
    this.publicholidaysmodal = true;
  }

  editpublicHoliday_modal(holiday) {
    this.selectedpublicHoliday = holiday;
    this.publicHolidaysForm.patchValue({
      holiDesc: this.selectedpublicHoliday.holDesc,
      startDate: new Date(this.selectedpublicHoliday.startDate),
      endDate: new Date(this.selectedpublicHoliday.endDate),
    })
    this.editPublicHolidaysModal = true;
  }
  editTermHoliday_modal(holiday) {
    this.selectedTermHoliday = holiday;
    this.termHolidaysForm.patchValue({
      termDesc: this.selectedTermHoliday.termDesc,
      startDate: new Date(this.selectedTermHoliday.startDate),
      endDate: new Date(this.selectedTermHoliday.endDate),
    })
    this.editTermHolidaysModal = true;
  }

  addpublicHolidays() {
    this.isSubmitted = true;
    if (!this.publicHolidaysForm.valid) {
      return false;
    } else {
      var stDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("startDate").value), 'yyyy-MM-dd');
      var eDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("endDate").value), 'yyyy-MM-dd');
      if (this.dateLessThan(new Date(stDate), new Date(eDate))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      }
      this.publicHoliday = new HoliDay;
      this.publicHoliday.holDesc = this.publicHolidaysForm.get("holiDesc").value;
      this.publicHoliday.startDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("startDate").value), 'yyyy-MM-dd');
      this.publicHoliday.endDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("endDate").value), 'yyyy-MM-dd');

      this.holidaysservices.createPublicHoliDays(this.publicHoliday).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Public Holiday Created' });
        this.ngOnInit();
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Public Holiday failed.' });
      });
      this.publicholidaysmodal = false;
    }
  }

  editpublicHolidays() {
    this.isSubmitted = true;
    if (!this.publicHolidaysForm.valid) {
      var re = /00:00:00/;
      return false;
    } 

    else {
      var stDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("startDate").value), 'yyyy-MM-dd'); //.toString().replace(re, "8:30:00")
      var eDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("endDate").value), 'yyyy-MM-dd');
      if (this.dateLessThan(new Date(stDate), new Date(eDate))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      }
      this.publicHoliday = new HoliDay;
      this.publicHoliday.holDesc = this.publicHolidaysForm.get("holiDesc").value;
      this.publicHoliday.startDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("startDate").value), 'yyyy-MM-dd');
      this.publicHoliday.endDate = environment.datePipe.transform(new Date(this.publicHolidaysForm.get("endDate").value), 'yyyy-MM-dd');
      this.publicHoliday.pubHolId = this.selectedpublicHoliday.pubHolId;
      console.log(this.publicHoliday);
      this.holidaysservices.editPublicHoliDays(this.publicHoliday).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Public Holiday Updated' });
        this.ngOnInit();
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Public Holiday failed.' });
      });
      this.editPublicHolidaysModal = false;
    }
  }
  deletePublicHolidays() {
    this.holidaysservices.deletePublicHoliDays(this.selectedpublicHoliday.pubHolId).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'success', detail: 'Public Holiday Deleted' });
      this.ngOnInit();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Public Holiday Delete Failed.' });
    });
    this.editPublicHolidaysModal = false;
  }

  deleteTermHolidays() {
    this.holidaysservices.deleteTermHoliDays(this.selectedTermHoliday.termHolId).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'success', detail: 'Term Holiday Deleted' });
      this.ngOnInit();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Public Holiday Delete Failed.' });
    });
    this.editTermHolidaysModal = false;
  }
  showtermholidaysmodal() {
    this.isSubmitted=false;
    this.termHolidaysForm.reset();
    this.termholidaysmodal = true;
  }

  addTermHolidays() {
    this.isSubmitted = true;
    if (!this.termHolidaysForm.valid) {
      return false;
    } else {
      var stDate =environment.datePipe.transform(new Date(this.termHolidaysForm.get("startDate").value), 'yyyy-MM-dd');
      var eDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("endDate").value), 'yyyy-MM-dd');
      if (this.dateLessThan(new Date(stDate), new Date(eDate))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      }
      this.termholiday = new HoliDay;
      this.termholiday.termDesc = this.termHolidaysForm.get("termDesc").value;
      this.termholiday.startDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("startDate").value), 'yyyy-MM-dd'); //this.termHolidaysForm.get("startDate").value;
      this.termholiday.endDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("endDate").value), 'yyyy-MM-dd'); //this.termHolidaysForm.get("endDate").value;

      this.holidaysservices.createTermHoliDays(this.termholiday).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Term Holiday Created' });
        this.ngOnInit();
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Term Holiday failed.' });
      });
      this.termholidaysmodal = false;
    }
  }
  editTermHolidays() {
    const re = /00:00:00/;
    this.isSubmitted = true;
    if (!this.termHolidaysForm.valid) {
      return false;
    } else {
      var stDate =environment.datePipe.transform(new Date(this.termHolidaysForm.get("startDate").value), 'yyyy-MM-dd');
      var eDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("endDate").value), 'yyyy-MM-dd');
      if (this.dateLessThan(new Date(stDate), new Date(eDate))) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'End date should be greater than Start date' });
        return;
      }
      this.termholiday = new HoliDay;
      this.termholiday.termDesc = this.termHolidaysForm.get("termDesc").value;
      this.termholiday.startDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("startDate").value), 'yyyy-MM-dd'); // new Date(stDate);
      this.termholiday.endDate = environment.datePipe.transform(new Date(this.termHolidaysForm.get("endDate").value), 'yyyy-MM-dd'); //new Date(eDate);
      this.termholiday.termHolId = this.selectedTermHoliday.termHolId;
      console.log(this.termholiday);
      this.holidaysservices.editTermHoliDays(this.termholiday).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Term Holiday Updated' });
        this.ngOnInit();
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'error', detail: 'Term Holiday Updated failed.' });
      });
      this.editTermHolidaysModal = false;
    }
  }

  dateLessThan(fstartdate: Date, fenddate: Date) {
    if (fenddate < fstartdate) {
      return true;
    }
    return false;
  }
  get holiday_valid() { return this.publicHolidaysForm.controls; };
  get termholiday_valid() { return this.termHolidaysForm.controls; }
  getCorrectedDate(d: string) {
    return this.dateutil.getCorrectedDate(d);
  }

}
