import { ChangeDetectorRef, Component, OnInit,ViewChild } from '@angular/core';
import { Visit } from '../../domain/visit';
import { VisitService } from '../../service/visitservice';
import { Table } from 'primeng/table';
import { User } from '../../domain/user';
import { UserDetailsService } from '../../service/userDetails.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-hubcohome',
  templateUrl: './hubcohome.component.html',
  styleUrls: ['./hubcohome.component.scss']
})
export class HubcohomeComponent implements OnInit {
  visits: Visit[];
  visitstemp: Visit[];
  loginuserId: any;
  loginusername : any;
  selecteduserdetails:User;
  visitsoutsidehub: Visit[];
  visitsoutsidehubtemp: Visit[];
  @ViewChild('dt') table: Table;
  searchedData: any;
  visitssearchedData: any;
  visitssearched_Data: any;
  progressbar: boolean = false;
  constructor(private visitservice: VisitService, private UserDetailsService: UserDetailsService,private ref:ChangeDetectorRef,) { 
    ref.detach();
      setInterval(() => { this.ref.detectChanges(); }, 1000);
  }

  ngOnInit(): void {
    this.progressbar=true;
    this.loginuserId =localStorage.getItem("loginuserid");
    this.loginusername =localStorage.getItem("username");

    this.UserDetailsService.getUserforId(this.loginuserId).subscribe(userdata =>{   
      this.selecteduserdetails = userdata;
    });   
    this.visitservice.getVisits().subscribe(data =>{  
     
      if(data!=null){
        this.visitstemp =data;
        this.visits = [];
        //filter all these 
        //Transfer,Suspended, Surrendered,Cancelled, FULL TO PARTIAL, SPARE, Postponed, Postpone Before Letter, Do not Schedule, QAT Prod, Transfer Before Letter
        this.visitstemp = this.visitstemp.filter(el=>(el.firstOfficerFirstName!=null && el.firstOfficerFirstName.trim().toLowerCase()!=('Transfer'.toLowerCase()) && 
        el.firstOfficerFirstName.trim().toLowerCase()!=('Suspended'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Surrendered'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Cancelled'.toLowerCase()) &&
        el.firstOfficerFirstName.trim().toLowerCase()!=('FULL TO PARTIAL'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('SPARE'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Postponed'.toLowerCase())
        && el.firstOfficerFirstName.trim().toLowerCase()!=('Postpone Before Letter'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Do not Schedule'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('QAT Prod'.toLowerCase())
        && el.firstOfficerFirstName.trim().toLowerCase()!=('Transfer Before Letter'.toLowerCase())));
        
        this.visitstemp.forEach(eledata=>{
          //Added to filter both firstname and lastname 
          eledata.firstOfficerName = eledata.firstOfficerFirstName + " " + eledata.firstOfficerLastName + " " + eledata.firstOfficerHub;
          eledata.secondOfficerName = eledata.secondOfficerFirstName + " " + eledata.secondOfficerLastName;
          // show only active users
          if(eledata.firstOfficerHub == this.selecteduserdetails.region && eledata.futurVisit=='N' && eledata.arstatus!='Completed'){
            this.visits.push(eledata);
          }
        }); 
      }      
      this.table.reset();
      this.progressbar=false;

    },err=>{
      this.progressbar=false;
    }); 

    //outsidehubdata
    this.visitservice.getVisits().subscribe(data =>{  
      this.progressbar=true;
      if(data!=null){
        this.visitsoutsidehubtemp =data;
        this.visitsoutsidehub = [];
        this.visitsoutsidehubtemp = this.visitsoutsidehubtemp.filter(el=>(el.firstOfficerFirstName!=null && el.firstOfficerFirstName.trim().toLowerCase()!=('Transfer'.toLowerCase()) && 
        el.firstOfficerFirstName.trim().toLowerCase()!=('Suspended'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Surrendered'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Cancelled'.toLowerCase()) &&
        el.firstOfficerFirstName.trim().toLowerCase()!=('FULL TO PARTIAL'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('SPARE'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Postponed'.toLowerCase())
        && el.firstOfficerFirstName.trim().toLowerCase()!=('Postpone Before Letter'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('Do not Schedule'.toLowerCase()) && el.firstOfficerFirstName.trim().toLowerCase()!=('QAT Prod'.toLowerCase())
        && el.firstOfficerFirstName.trim().toLowerCase()!=('Transfer Before Letter'.toLowerCase())));

        this.visitsoutsidehubtemp.forEach(eledata=>{
           //Added to filter both firstname and lastname 
           eledata.firstOfficerName = eledata.firstOfficerFirstName + " " + eledata.firstOfficerLastName + " " + eledata.firstOfficerHub;
           eledata.secondOfficerName = eledata.secondOfficerFirstName + " " + eledata.secondOfficerLastName;
          // show only active users
       //   if(eledata.futurVisit=='N' && eledata.startDate == null && eledata.managingRegion ==  this.selecteduserdetails.region && eledata.userID !=null && eledata.firstOfficerHub !=  this.selecteduserdetails.region){
        if(eledata.futurVisit=='N'  && eledata.managingRegion ==  this.selecteduserdetails.region && eledata.userID !=null && eledata.firstOfficerHub !=  this.selecteduserdetails.region){
             this.visitsoutsidehub.push(eledata);
          }
        }); 
      }      
      this.table.reset();
      this.progressbar=false;
    },err=>{
      this.progressbar=false;
    });
  }
  onVisitsFiltered(event,data){
    this.visitssearchedData=data;
  }
  exportExcel() {
    let filtereddata;
    if(!this.visitssearchedData){
      filtereddata = this.visits;
    }else{
      filtereddata = this.visitssearchedData;
    }
    let expHubcoVisits=filtereddata.map(function(aVisit){
    return {
      serviceId: aVisit.serviceId, 
      VisitID: aVisit.visitId,
     // VisitType: aVisit.eventName,
      ServiceName: aVisit.serviceName,
      Risk_Profile: aVisit.riskFactor,
      Service_Type: aVisit.serviceType,
      Hub: aVisit.managingRegion,
      Status: aVisit.serviceStatus,
      Officer_1_Name: aVisit.firstOfficerFirstName + " " + aVisit.firstOfficerLastName + "-" +aVisit.firstOfficerHub,
      Officer_2_Name: aVisit.secondOfficerFirstName + " " + aVisit.secondOfficerLastName + "-" +aVisit.secondOfficerHub,
      Visit_Date: ((formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')) + " " + ((formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')),
      PeriodId:aVisit.periodId,
      Target: aVisit.targetName,
      }
    });
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(expHubcoVisits);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "visits_assigned_within_hub");
    });
}
outsidehubExceFiltered(event,data){
  this.visitssearched_Data=data;
}
exportoutsidehubExcel() {
  let filtereddata;
    if(!this.visitssearched_Data){
      filtereddata = this.visitsoutsidehub;
    }else{
      filtereddata = this.visitssearched_Data;
    }
    console.log(filtereddata);
    
  let expHubcoVisits=filtereddata.map(function(aVisit){
    return {
      serviceId: aVisit.serviceId, 
      VisitID: aVisit.visitId,
      VisitType: aVisit.eventName,
      ServiceName: aVisit.serviceName,
      Risk_Profile: aVisit.riskFactor,
      Service_Type: aVisit.serviceType,
      Hub: aVisit.managingRegion,
      Status: aVisit.serviceStatus,
      Officer_1_Name: aVisit.firstOfficerFirstName + " " + aVisit.firstOfficerLastName + "-" +aVisit.firstOfficerHub,
      Officer_2_Name: aVisit.secondOfficerFirstName + " " + aVisit.secondOfficerLastName + "-" +aVisit.secondOfficerHub,
      Visit_Date: ((formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')) + " " + ((formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')),
      PeriodId:aVisit.periodId,
      Target: aVisit.targetName,
      }
    });
  import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(expHubcoVisits);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "visits_Assigned_outside_hub");
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}

}
