import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ButtonModule} from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChangeDetectionStrategy, Component, OnInit,ViewChild } from '@angular/core';
import { Validators,FormBuilder,FormGroup} from '@angular/forms';
import { Table } from 'primeng/table';
import { concat } from 'rxjs';

import { Visit } from '../../domain/visit';
import { User } from '../../domain/user';
import { events } from '../../domain/events';
import { flexperiods } from '../../domain/flexperiods';
import { common } from '../../domain/common';
import { scheduleevent } from '../../domain/scheduleevent';

import {schedulepreferredservices} from '../../domain/schedulepreferredservices';
import { VisitService } from '../../service/visitservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { flexperiodsservice } from '../../service/flexperiodsservice';
import { eventservice } from '../../service/eventservice';
import { perferredservice } from '../../service/perferredservice';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { commonservice } from '../../service/commonservice';
import { ServiceDetailsService } from '../../service/serviceDetails.service';
import { DatePipe } from '@angular/common'
import { formatDate } from '@angular/common'
import { schedulepreferredservice } from '../../service/schedulepreferredservice';
import { DateUtilService } from '../../service/date-util.service';
import * as moment from 'moment';

@Component({
  selector: 'app-schedulelisthubco',
  templateUrl: './schedulelisthubco.component.html',
  styleUrls: ['./schedulelisthubco.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScheduleListHubCoComponent implements OnInit {
  visits: Visit[];
  visit:Visit;
  options: any;
  overlays: any[];
  marker: any;
  preferredservices: boolean=true;
  visitstemp: Visit[];
  addscheduleModal: boolean;
  servicelocationModal : boolean;
  updateflexperiodOneModal : boolean;
  changeOfficerOneModal : boolean;
  changeOfficerTwoModal : boolean;
  changeofficerwithdatesOneModal : boolean;
  visitIdModal : boolean;

  officers: User[];
  officerstemp: User[];

  flexperiods : flexperiods[];
  flexperiodstemp : flexperiods [];

  events: events[];
  eventstemp: events[];

  common: common[];
  commontemp: common[];

  updateofficerSubmitted: boolean = false;
  updateofficerOneSubmitted: boolean = false;
  updateflexperiodSubmitted: boolean = false;
  updatevisitSubmitted: boolean = false;
  addscheduleSubmitted: boolean = false;
  
  selectedVisit: Visit;
  sse :scheduleevent;
  ssetemp : any;
  sps :schedulepreferredservices;
  ivisit: any;
  @ViewChild('dt') table: Table;
  summaryVisits: any;
  isUpdateOfficer: boolean;
  buttonClicked: string;
  isRemoveOfficer: boolean;
  isOfficerOne: boolean;
  isOfficerTwo: boolean;
  progressbar:boolean=false;
  progressSpin:boolean=false;
  summaryMessages: { severity: string; summary: string; detail: string; }[];
  newFlex: flexperiods[];
  yelloEmail:boolean=false;
  existEventId: any;

  inputData:any={
    serviceId:'',
    visitId:'',
    managingRegion:'',
    serviceType:'',
    eventName:'',
    periodId:'',
  };
  searchedData: any;
  loginuserrole:string='';
  loginuserId:string='';
  
  constructor(private dateUtil:DateUtilService,private datepipe:DatePipe, private serviceDetails : ServiceDetailsService, private visitservice: VisitService,private commonservice: commonservice,private perferredservice:perferredservice,private userdetailsservice :UserDetailsService,private eventservice:eventservice,
    private messageService: MessageService, private confirmationService:ConfirmationService, private scheduleeventservice:scheduleeventservice,private flexperiodsservice:flexperiodsservice,private formBuilder: FormBuilder,private schedulePreferredservice: schedulepreferredservice
    ) { }
    registerForm: FormGroup;
    ngOnInit(): void {

      this.loginuserrole =localStorage.getItem("loginuserrole");
      this.loginuserId = localStorage.getItem("loginuserid");
    
      

      this.summaryData();
    
    // register all forms groups in formbuilder 
    this.registerForm = this.formBuilder.group({
      updateflexperiodgroup: this.formBuilder.group({
        fperiodid: ['', [Validators.required]]
      }),
      updatevisitgroup : this.formBuilder.group({
        fvisitid: ['', [Validators.required]]
      }),
      addschedulegroup : this.formBuilder.group({
        officername: ['', [Validators.required]],
        flexperiod: ['', [Validators.required]],
        visittype: ['', [Validators.required]]
      }),
      addcommentsgroup : this.formBuilder.group({
        comments: ['', [Validators.required]],
      
      }),
      updateofficeronegroup  : this.formBuilder.group({
        fofficerid: ['', [Validators.required]]
      }),
      updateofficerdatesgroup : this.formBuilder.group({
        freason: ['', [Validators.required]],
        fofficeroneid:['', [Validators.required]]
      }),
    });
  }


    summaryData(){
    this.progressbar=true;

    //get the hub for loggedinuser
    this.userdetailsservice.getUserforId( this.loginuserId).subscribe(data=>{

      console.log(JSON.stringify(data));
      let hubname = data.region;
  
     console.log("getting all visits for future visits as P ")
    this.visitservice.getPendingVisits().subscribe(data =>{     
      if(data!=null){

        this.visitstemp =data;
        this.visitstemp=this.visitstemp.filter(el=>el.emailSent==null);

     //   console.log("with emailsent null"+this.visitstemp.length)
    
        this.visits = [];
        this.visitstemp.forEach(eledata=>{
     //     console.log("due date comm"+eledata.commduedate)


          const date = new Date(eledata.commduedate);
          const offset = date.getTimezoneOffset();
          if (offset < 0) {
              date.setHours(12,0,0);
          }
          console.log("updated"+date.toISOString().substring(0,10));

          

          //Added to filter both firstname and lastname 
          eledata.firstOfficerName = eledata.firstOfficerFirstName + " " + eledata.firstOfficerLastName+" "+eledata.firstOfficerHub;
          eledata.secondOfficerName = eledata.secondOfficerFirstName + " " + eledata.secondOfficerLastName +" "+eledata.secondOfficerHub;
          eledata.serviceType = eledata.serviceType;
         // eledata.suburbs=eledata.suburb;
        //  eledata.post=eledata.suburb;
          eledata.suburbAndPost = eledata.suburb+" "+eledata.postcode;
	//for isLongDayCare
          if(eledata.isLongDayCare== "0"){
            eledata.isLongDayCare = "No";
          }else{
            eledata.isLongDayCare="Yes";
          }
          //for OutHoursCareAfter
          if(eledata.outHoursCareAfter=="0"){
            eledata.outHoursCareAfter="No";
          }else{
            eledata.outHoursCareAfter="Yes";
          }

          //for OutHoursCareAfter
          if(eledata.outHoursCareBefore=="0"){
            eledata.outHoursCareBefore="No";
          }else{
            eledata.outHoursCareBefore="Yes";
          }
          //for outHoursVacationCare
          if(eledata.outHoursVacationCare=="0"){
            eledata.outHoursVacationCare="No";
          }else{
            eledata.outHoursVacationCare="Yes";
          }

          //for preSchlKindrPartOfSchl
          if(eledata.preSchlKindrPartOfSchl=="0"){
            eledata.preSchlKindrPartOfSchl="No";
          }else{
            eledata.preSchlKindrPartOfSchl="Yes";
          }

          //for preSchlKindrStandAlone
          if(eledata.preSchlKindrStandAlone=="0"){
            eledata.preSchlKindrStandAlone="No";
          }else{
            eledata.preSchlKindrStandAlone="Yes";
          }
       //   console.log("eledata"+JSON.stringify(eledata))

          let today = new Date();
          console.log("todays date"+today)

          
          // show only active users
          if((eledata.firstOfficerHub == hubname || eledata.secondOfficerHub == hubname)  && today<date){
          this.visits.push(eledata);
          }
        }); 
      }
      this.table.reset();
      this.progressbar=false;
    },err=>{
      console.log("error while getting summary data")
      this.progressbar=false;
    });

  })
  
  }

  get ufp() { return (<FormGroup>this.registerForm.get('updateflexperiodgroup')).controls; }
  get uv() { return (<FormGroup>this.registerForm.get('updatevisitgroup')).controls; }
  get asg() { return (<FormGroup>this.registerForm.get('addschedulegroup')).controls; }
  get uo() { return (<FormGroup>this.registerForm.get('updateofficeronegroup')).controls; }
  get uodates() { return (<FormGroup>this.registerForm.get('updateofficerdatesgroup')).controls; }
  get comms() { return (<FormGroup>this.registerForm.get('addcommentsgroup')).controls; }

  get updateflexperiodgroup() {
    return this.registerForm.get('updateflexperiodgroup');
  }
  get updatevisitgroup() {
    return this.registerForm.get('updatevisitgroup');
  }
  get addschedulegroup() {
    return this.registerForm.get('addschedulegroup');
  }
  get addcommentsgroup()
  {
    return this.registerForm.get('addcommentsgroup');

  }
  get updateofficeronegroup() {
    return this.registerForm.get('updateofficeronegroup');
  }
  get updateofficerdatesgroup() {
    return this.registerForm.get('updateofficerdatesgroup');
  }
  updateofficerdates(){
    this.updateofficerOneSubmitted = true;   
    if (!this.updateofficerdatesgroup.valid) {
      return false;
    } else { 
      this.scheduleeventservice.get(this.selectedVisit.eventId).subscribe(sedata=>{
        this.sse = sedata;
          this.sse.userId = this.updateofficerdatesgroup.get("fofficeroneid").value;     
          this.sse.changeReason =this.updateofficerdatesgroup.get("freason").value 
          this.scheduleeventservice.update(this.sse).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Updated officer for '+this.selectedVisit.serviceId});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'Updated officer  for '+this.selectedVisit.serviceId});
            console.log("-- updates failed --");
          });        
      });
      console.log("success");
    }
    this.updateofficerOneSubmitted = false;   
    this.changeofficerwithdatesOneModal = false;

  }

  
  
  ///update Officer One and officer two
  updateRemoveOfficer(ofc:string){
    this.progressbar=true;
    //choosing button update/remove
    if(this.buttonClicked == 'update'){
      this.isUpdateOfficer= true;
    }else{
      this.isUpdateOfficer=false;
    }
    if(this.buttonClicked == 'remove'){
      this.isRemoveOfficer= true;
    }else {
      this.isRemoveOfficer=false;
    }
    //choosing button officerOne/officerTwo
    if(ofc == 'officerOne'){
      this.isOfficerOne= true;
      }else{
        this.isOfficerOne=false;
      }
    if(ofc == 'officerTwo'){
      this.isOfficerTwo= true;
    }else{
      this.isOfficerTwo=false;
    }
      this.updateofficerSubmitted = true;
        this.scheduleeventservice.get(this.selectedVisit.eventId).subscribe(sedata=>{
          this.sse = sedata;
          var officer:string;
      
          if(this.isUpdateOfficer){
            if(!this.updateofficeronegroup.valid){
              return false;
            }
          
            if(this.isOfficerOne){
              this.sse.userId = this.updateofficeronegroup.get("fofficerid").value;
              this.sse.startDate = null;
              this.sse.endDate = null;
              this.sse.emailSent=null;
              this.sse.emailSentToFirstOff=null;
              this.sse.futureVisit=null;
              officer=this.selectedVisit.userID;
  
            }else if(ofc == 'officerTwo'){
              this.sse.secondUserId = this.updateofficeronegroup.get("fofficerid").value;
              this.sse.startDate = null;
              this.sse.endDate = null;
              this.sse.futureVisit=null;
              this.sse.emailSent=null;
              this.sse.emailSentToSecOff=null;
              officer=this.selectedVisit.secondUserID;
            }
            console.log(officer);
           
            this.scheduleeventservice.updateOfficer(this.sse,officer,ofc).subscribe(res=>{
              if(ofc == 'officerOne'){
                this.progressbar=false;
                  this.messageService.add({ severity: 'success', summary: 'success', detail: 'First Officer updated for '+this.selectedVisit.serviceId});
                }else{
                  this.progressbar=false;
                  this.messageService.add({ severity: 'success', summary: 'success', detail: 'Second Officer updated for '+this.selectedVisit.serviceId});
                }
            
              this.ngOnInit();
            },err=>{
              this.progressbar=false;
              /* this.summaryMessages = [
                {severity:'error', summary:'Error', detail:'Updated officer for '+this.selectedVisit.serviceId}
            ]; */
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Update failed officer for '+this.selectedVisit.serviceId});
            });
          }else if(this.isRemoveOfficer){
           
            if(this.isOfficerOne){
              this.sse.userId = " ";
              this.sse.startDate = null;
              this.sse.endDate = null;
              this.sse.futureVisit=null;
              this.sse.emailSent=null;
              this.sse.emailSentToSecOff=null;
  
            }else if(this.isOfficerTwo){
              this.sse.secondUserId = null;
              this.sse.startDate = null;
              this.sse.endDate = null;
              this.sse.futureVisit=null;
              this.sse.emailSent=null;
            }
         
            this.scheduleeventservice.updateOfficer(this.sse,null,ofc).subscribe(res=>{
              /* this.summaryMessages = [
                {severity:'success', summary:'Success', detail:'Removed officer for '+this.selectedVisit.serviceId}
            ]; */
            if(ofc == 'officerOne'){
              this.messageService.add({ severity: 'success', summary: 'success', detail: 'Removed Officer One for '+this.selectedVisit.serviceId});
            }else{
              this.messageService.add({ severity: 'success', summary: 'success', detail: 'Removed Second Officer for '+this.selectedVisit.serviceId});
            }
           
              this.ngOnInit();
            },err=>{
              /* this.summaryMessages = [
                {severity:'error', summary:'Error', detail:'Removed officer for '+this.selectedVisit.serviceId}
            ]; */
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error for Removing officer for '+this.selectedVisit.serviceId});
            });
          }
        });
      this.updateofficerSubmitted = false;
      this.changeOfficerOneModal = false;
      this.changeOfficerTwoModal = false;
  }

  updatevisit(){
    this.progressbar=true;
    this.updatevisitSubmitted = true;   
    if (!this.updatevisitgroup.valid) {
      this.progressbar=false;
      return false;
    } else {            
      this.scheduleeventservice.get(this.selectedVisit.eventId).subscribe(sedata=>{
        this.sse = sedata;
          this.sse.visitId = this.updatevisitgroup.get("fvisitid").value;      
          this.scheduleeventservice.updateEventsOnSchedule(this.sse).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Visit Id updated for '+this.selectedVisit.serviceId});
            console.log("--update success--");      
            this.ngOnInit();
          },err=>{
            this.messageService.add({ severity: 'error', summary: 'success', detail: 'Visit Id update failed for '+this.selectedVisit.serviceId});
            console.log("-- updates failed --");
          });        
      });
    }
    this.updatevisitSubmitted = false;   
    this.visitIdModal = false;
  }

  updateflexperiod(){
    this.progressbar=true;
    this.updateflexperiodSubmitted = true;
    console.log(this.selectedVisit.serviceId);
    if (!this.updateflexperiodgroup.valid) {
      this.progressbar=false;
      return false;
    } else {
      console.log(JSON.stringify(this.updateflexperiodgroup.value));
      this.scheduleeventservice.get(this.selectedVisit.eventId).subscribe(sedata=>{
        this.sse = sedata;
          this.sse.periodId = this.updateflexperiodgroup.get("fperiodid").value;
          this.sse.startDate = null;
          this.sse.endDate = null;
          this.sse.emailSent=null;
          this.sse.futureVisit=null;
          this.sse.emailSentToFirstOff=null;
          this.sse.emailSentToSecOff=null;

          this.scheduleeventservice.update(this.sse).subscribe(res=>{
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Flex period updated for '+this.selectedVisit.serviceId});
            console.log("--update success--");
            this.progressbar=false;    
            this.ngOnInit();
          },err=>{
            this.progressbar=false;
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'Flex period update failed for '+this.selectedVisit.serviceId});
            console.log("-- updates failed --");
          });        
      });
    }
   
    this.updateflexperiodOneModal = false;
    this.updateflexperiodSubmitted = false;
  }

  getEventID(sId,vId){
    this.scheduleeventservice.getEventId(sId,vId).subscribe(eventId=>{
     this.existEventId=eventId;
    })
  }
  updateAddSchedule(){
    this.updateSchedule(this.selectedVisit.eventId);
    this.progressbar=true;
    if(this.selectedVisit.eventId !==null){
      this.updateSchedule(this.selectedVisit.eventId);
    }else{      
      this.onSubmit();
    }
  }
  updatecommentsSubmitted=false;
  updateComments(comments:any)
  {

    let eventId = this.selectedVisit.eventId;

    this.scheduleeventservice.get(eventId).subscribe(sedata=>{
      this.sse = sedata;
   
      this.sse.updatedBy = "ITD";

      this.sse.comments = comments;
      console.log("comments updated")
        this.scheduleeventservice.update(this.sse).subscribe(res=>{
          this.addschedulegroup.reset();
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Service updated successfully'});
          console.log("--update success--");     
          this.updatecommentsSubmitted=true; 
          this.commentsdialog=false;
          this.summaryData();
        },err=>{
       //   this.addschedulegroup.reset();
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service removal failed'});
          console.log("-- updates failed --");
          this.updatecommentsSubmitted=false;
        });        
    });

  }

  updateSchedule(eventId:any){
    this.messageService.clear();
    this.addscheduleSubmitted = true;
    console.log(this.selectedVisit.serviceId);

    if (!this.addschedulegroup.valid) {
      this.progressbar=false;
      return false;
    } else {
      this.scheduleeventservice.get(eventId).subscribe(sedata=>{
        this.sse = sedata;
        this.sse.userId = this.addschedulegroup.get("officername").value;
        if(this.addschedulegroup.get("visittype").value=="V"){
          this.sse.title = "AR"+"-"+this.selectedVisit.serviceId;
        }else{
          this.sse.title = this.addschedulegroup.get("visittype").value+"-"+this.selectedVisit.serviceId;
        }
        this.sse.eventType = this.addschedulegroup.get("visittype").value;
        this.sse.periodId = this.addschedulegroup.get("flexperiod").value;
        this.sse.futureVisit = 'Y';
        this.sse.createdBy = "ITD";
  
        
          this.scheduleeventservice.update(this.sse).subscribe(res=>{
            this.addschedulegroup.reset();
            this.messageService.add({ severity: 'success', summary: 'success', detail: 'Service updated successfully'});
            console.log("--update success--");      
            this.summaryData();
          },err=>{
            this.addschedulegroup.reset();
            this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service removal failed'});
            console.log("-- updates failed --");
          });        
      });
    }
    
    this.addscheduleModal = false;
  }
  onSubmit(){
  this.messageService.clear();
    this.addscheduleSubmitted = true;
    console.log(this.selectedVisit.serviceId);
    if (!this.addschedulegroup.valid) {
      this.progressbar=false;
      return false;
    } else {
      console.log(JSON.stringify(this.addschedulegroup.value));
      this.sse = new scheduleevent;
      this.sse.userId = this.addschedulegroup.get("officername").value;
      if(this.addschedulegroup.get("visittype").value=="V"){
        this.sse.title = "AR"+"-"+this.selectedVisit.serviceId;
      }else{
        this.sse.title = this.addschedulegroup.get("visittype").value+"-"+this.selectedVisit.serviceId;
      }
      this.sse.eventType = this.addschedulegroup.get("visittype").value;          
      this.sse.periodId = this.addschedulegroup.get("flexperiod").value;
      this.sse.serviceId = this.selectedVisit.serviceId;
      this.sse.futureVisit = 'Y';
      this.sse.createdBy = "ITD";
      if (this.selectedVisit.preSerId !=null){
        this.sse.perSerId = this.selectedVisit.preSerId;
      }
      this.sse.createdDate = new Date();
      
      // call service
      this.scheduleeventservice.create(this.sse).subscribe(res=>{
        this.addschedulegroup.reset();
        this.messageService.add({ severity: 'success', summary: 'success', detail: 'Added schedule for '+this.selectedVisit.serviceId });
        console.log("-- new Sechedule event created --" + res);      
        if (this.selectedVisit.preSerId !=null){
          /* Once created Get EVENT_ID from SCHEDULE_SERVICE_EVENT table and update it back to colum EVENT_ID in SCHEDULE_PREFERRED_SERVICES.
              1) Get EVENT_ID from SCHEDULE_SERVICE_EVENT pass  PER_SER_ID
              2) get PER_SER_ID from SCHEDULE_PREFERRED_SERVICES pass
              3) Update the SCHEDULE_PREFERRED_SERVICES object from step 2 with the EVENT_ID retrived from Step 1

          */
              this.scheduleeventservice.getSSEByPerSerId(this.selectedVisit.preSerId).subscribe(sseData =>{      
                this.sse  = sseData; 
             
                  this.perferredservice.getSchedulePerferredService(this.selectedVisit.preSerId).subscribe(spsData =>{      
                    this.sps  = spsData;                       
                    this.sps.eventId = this.sse.eventId;
                    this.perferredservice.update(this.sps).subscribe(psData =>{
                      this.summaryData();
                      console.log("success !!!!!!!!!!!!!" + psData);
                      },err=>{
                        console.log("failed !!!!!!!!!!!!!");
                  }); 
                });
             });  
        }
      },err=>{
        this.addschedulegroup.reset();
        this.messageService.add({ severity: 'error', summary: 'success', detail: 'Add schedule failed '+this.selectedVisit.serviceId });
        console.log("failed !!!!!!!!!!!!!");
      });   
    }
    this.addscheduleSubmitted = false;    
    this.addscheduleModal = false;
    // this.ngOnInit();
  }
  resendsendACOScheduleEmail(visit:Visit){
    if (visit.visitId ==null){
      this.messageService.add({ severity: 'Warning!', summary: 'Warning!', detail: 'Visit not scheduled' });
      return false;
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to send an email? ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.progressbar = true;

        this.scheduleeventservice.resendACOEmailandUpdateStatus(visit.visitId, visit.eventId).subscribe(data => {
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Email sent sucessfully' });
          console.log("--update success--");
          this.progressbar = false;
          this.ngOnInit();
        }, err => {
          this.progressbar = false;
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service failed to send email' });
          console.log("-- updates failed --");
        });
      }
    });
  }
  sendACOScheduleEmail(visit: Visit) {
    console.log("calling this email notification visit_id = " + visit.visitId);
    if (visit.visitId == null) {
      this.messageService.add({ severity: 'Warning!', summary: 'Warning!', detail: 'Visit not scheduled' });
      return false;
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to send an email? ',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.progressbar = true;

        this.scheduleeventservice.sendACOEmailandUpdateStatus(visit.visitId, visit.eventId).subscribe(data => {
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Email sent sucessfully' });
          console.log("--update success--");
          this.progressbar = false;
          this.ngOnInit();
        }, err => {
          this.progressbar = false;
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service failed to send email' });
          console.log("-- updates failed --");
        });
      }
    });
  }
  commentsdialog=false;
  comments:string=''
  shownCommentsDialog(visit:Visit)
  {
    this.selectedVisit = visit;
    this.comments=visit.comments;
    this.commentsdialog=true;
    this.updatecommentsSubmitted=false;
  }

addScheduleDialog(visit:Visit) {
  this.addscheduleSubmitted = false;
  this.addscheduleModal = true;
  this.selectedVisit = visit;
  console.log(this.selectedVisit);
  
  this.userdetailsservice.getActiveUsers().subscribe(userData=>{
    if(userData!=null){
      this.officerstemp =userData;
      this.officers = [];
      this.officerstemp.forEach(eledata=>{
        // show only active users
        if(eledata.status =='Active'){
          this.officers.push(eledata);
        }
      }); 
    }
  });
  //replace with this method
  this.getflexperioddata();
  this.eventservice.getEvents().subscribe(eventdata =>{     
    if(eventdata!=null){
      this.eventstemp = eventdata;  
      this.events = [];
      this.eventstemp.forEach(eledata=>{
        // show only Visit='Y' events 
        if(eledata.visit =='Y'){
          this.events.push(eledata);
        }
      }); 
    }
  });
}

addVisitIdDialog (visit:Visit){
  this.visitIdModal = true;
  this.selectedVisit = visit;
  console.log(this.selectedVisit.serviceId);
}

changeOfficerOneDialog (visit:Visit,ofc:string){
  this.selectedVisit = visit;
  this.userdetailsservice.getActiveUsers().subscribe(userData=>{
    if(userData!=null){
      this.officerstemp =userData;
      this.officers = [];
      this.officerstemp.forEach(eledata=>{
        // show only active users
        if(eledata.status =='Active'){
          this.officers.push(eledata);
        }
      }); 
    }
  });
  this.commonservice.getAll().subscribe(userData=>{
    if(userData!=null){
      this.commontemp =userData;
      this.common = [];
      this.commontemp.forEach(eledata=>{
        // show only active users
        if(eledata.active =='Y' && eledata.type =='CHA'){
          this.common.push(eledata);
        }
      }); 
    }
  });
  if(ofc == 'officerOne') {
    this.changeOfficerOneModal = true;
  }else if(ofc == 'officerTwo'){
    this.changeOfficerTwoModal = true;
  }

}

updateflexperiodDialog (visit:Visit){
  this.getflexperioddata();
  this.updateflexperiodOneModal = true;
  this.selectedVisit = visit;
}

closeservicelocationDialog(event){
  this.servicelocationModal = false;
}
servicelocationDialog (visit:Visit){
  this.servicelocationModal = true;
  this.selectedVisit = visit;
  this.overlays = [];
  this.options = [];
  this.marker = [];
  this.options = {
    center: {lat: Number(this.selectedVisit.latitude) ,lng: Number(this.selectedVisit.longitude)},
    zoom: 12
  };
  this.overlays.push(new google.maps.Marker({ position: { lat: Number(this.selectedVisit.latitude), lng: Number(this.selectedVisit.longitude) }, 
                title: this.selectedVisit.serviceName, icon: '../../../assets/layout/images/cbs.png' }));
}

getflexperioddata(){
  this.flexperiodsservice.getFlexPeriods().subscribe(flexdata =>{      
    if(flexdata!=null){
      this.flexperiodstemp = flexdata.reverse();
      this.flexperiods = [];
      this.flexperiodstemp.forEach(eledata=>{
        // show only active flex periods
        if(eledata.status =='Active'){
          this.flexperiods.push(eledata);
        }
      });
      this.flexperiods=this.flexperiods.sort( (a,b)=> a.perName > b.perName ? -1:1 );
    }
  });
}

onTableFiltered(event,data){
  this.searchedData=data;
}
exportExcel() {
  let filtereddata;
  if(!this.searchedData){
    filtereddata = this.visits;
  }else{
    filtereddata = this.searchedData;
  }
 let expVisits=filtereddata.map(aVisit=>{
  return {
        ServiceId: aVisit.serviceId,
          VisitID: aVisit.visitId,
          VisitType: aVisit.eventName,
          ServiceName: aVisit.serviceName,
          Risk_Profile: aVisit.riskFactor,
          Service_Type: aVisit.serviceType,
          Hub: aVisit.managingRegion,
          Status: aVisit.serviceStatus,
          Officer_1_Name: aVisit.firstOfficerFirstName + " " + aVisit.firstOfficerLastName + "-" +aVisit.firstOfficerHub,
          Officer_2_Name: aVisit.secondOfficerFirstName + " " + aVisit.secondOfficerLastName + "-" +aVisit.secondOfficerHub,
          //Visit_Date: aVisit.startDate?this.getCorrectedDateString(aVisit.startDate):''+ '',//((formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')) + " " + ((formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')),
          Visit_Date:this.getCorrectedDate(aVisit.startDate)+ ' ' +this.getCorrectedDate(aVisit.endDate),
          PeriodId:aVisit.periodId,
          Target: aVisit.targetName,
  }
 })
 import("xlsx").then(xlsx => {
     const worksheet = xlsx.utils.json_to_sheet(expVisits);
     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
     this.saveAsExcelFile(excelBuffer, "visits");
 });

  return;
 
 
}


summaryvisits(){
  this.visitservice.getVisits().subscribe(data =>{      
    this.summaryVisits = data;
  });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}

//tested with update by id
deleteServiceDetails(serviceId:any){
    this.serviceDetails.getServicesById(serviceId).subscribe(sedata=>{
      this.visit = sedata;
      this.visit.visible = false;
        this.serviceDetails.updateService(this.visit.serviceId,this.visit).subscribe(res=>{
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Updated officer for '});
          console.log("--update success--");      
          this.ngOnInit();
        },err=>{
          this.messageService.add({ severity: 'error', summary: 'success', detail: 'Updated officer  for '});
          console.log("-- updates failed --");
        });        
    });
}

//added this for update
updateServiceDetails(serviceId:any){
  this.updateofficerOneSubmitted = true;
    this.serviceDetails.getServicesById(serviceId).subscribe(sedata=>{
      this.visit = sedata;
      this.visit.visible = false;
      //this.visit.serviceApprovalDate=null;
      //this.visit.dateLastVisited=null;
      this.visit.dateLastVisited=this.datepipe.transform(sedata.dateLastVisited, 'dd-MMM-yyy');
        this.serviceDetails.updateServices(this.visit).subscribe(res=>{
          this.messageService.add({ severity: 'success', summary: 'success', detail: 'Service removed successfully '});
          console.log("--update success--");      
          this.ngOnInit();
        },err=>{
          this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service removal failed '});
          console.log("-- updates failed --");
        });        
    });
}

///updated this one only . preSerId:any-->update this one in html.

confirm(preSerId:any,eventId:any) {
  this.confirmationService.confirm({
      message: 'Are you sure that you want to delete ?',
      accept: () => {
              this.progressbar=true;
              this.scheduleeventservice.updateByEventId(eventId).subscribe(res=>{

                this.messageService.add({ severity: 'success', summary: 'success', detail: 'Service Removed successfully'});
                console.log("--update success--"); 
                this.progressbar=false;

                this.ngOnInit();
              },err=>{
                this.progressbar=false;
                this.messageService.add({ severity: 'error', summary: 'error', detail: 'Service removal failed'});
                console.log("-- updates failed --");
              });
      }
  });
}

getCorrectedDate(d: string) {
  return this.dateUtil.getCorrectedDate(d);
}


}
