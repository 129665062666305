import { Component, OnInit } from '@angular/core';
import {pastschedules} from '../../domain/pastschedules';
import { DateUtilService } from '../../service/date-util.service';
import { pastschedulesservices } from '../../service/pastschedulesservices';

@Component({
  selector: 'app-pastschedules',
  templateUrl: './pastschedules.component.html',
  styleUrls: ['./pastschedules.component.scss']
})
export class PastschedulesComponent implements OnInit {
  progressbar=false;
  pastschedules: pastschedules[]=[];
   constructor(private dateutil:DateUtilService,private pastschedulesservices: pastschedulesservices) { }

  ngOnInit(): void {
    this.progressbar=true;
    this.pastschedulesservices.getPastSchedules().subscribe(pastscheduledata =>{      
      this.pastschedules = pastscheduledata;  
      this.pastschedules = this.pastschedules.filter(e=>e!=null)
      this.pastschedules = this.pastschedules.filter(e=>e.serviceId!=null && e.periodId!=null && e.visitType!=null && e.firstOfficerId!=null && e.archived=='Y')
      this.progressbar=false;
     
    },err=>{
      
      this.progressbar=false;
    });
  }
/*  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.pastschedules);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "pastschedules");
      });
  }
*/
  exportExcel() {
    let filtereddata;
  
      filtereddata = this.pastschedules;
   
   let expVisits=filtereddata.map(aVisit=>{
    return {
          ServiceId: aVisit.serviceId,
            VisitID: aVisit.visitId,
            VisitType: aVisit.eventName,
            PeriodId:aVisit.periodId,
            ServiceName: aVisit.serviceName,
            Service_Type: aVisit.serviceType,
            Officer_1_Name: aVisit.firstOfficerFirstName + " " + aVisit.firstOfficerLastName + "-" +aVisit.firstOfficerHub,
            Officer_2_Name: aVisit.secondOfficerFirstName + " " + aVisit.secondOfficerLastName + "-" +aVisit.secondOfficerHub,
            //Visit_Date: aVisit.startDate?this.getCorrectedDateString(aVisit.startDate):''+ '',//((formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.startDate, 'dd-MM-yyyy', 'en_US')) + " " + ((formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')).indexOf("1970")!==-1?" ":formatDate( aVisit.endDate, 'dd-MM-yyyy', 'en_US')),
            Visit_Date:this.getCorrectedDate(aVisit.startDate)+ ' ' +this.getCorrectedDate(aVisit.endDate),

            Target: aVisit.targetName,
    }
   })
   import("xlsx").then(xlsx => {
       const worksheet = xlsx.utils.json_to_sheet(expVisits);
       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
       const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
       this.saveAsExcelFile(excelBuffer, "pastschedules");
   });
  
    return;
   
   
  }
  

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  getCorrectedDate(d: string) {
    return this.dateutil.getCorrectedDate(d);
  }
}
