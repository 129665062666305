import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginDataService } from '../app/admin/service/loginData.service';
import { SSOLogonService } from '../app/admin/service/ssoLogon.service';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './admin/service/AppConfigService';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
    userId:string;
    loggedinUser:string;
    logoutURL:string;
    clientId:any;
    constructor(public app: AppMainComponent,
        private ssologonservice:SSOLogonService,private route: Router,private cookieService: CookieService,private config: AppConfigService,
        private activatedRoute: ActivatedRoute,private loginService:LoginDataService) {
          this.logoutURL = this.config.getConfig().logoutURL;
          this.clientId = this.config.getConfig().clientId;
        }

        async ngOnInit() {
          

            if(this.loginService.getData() == null || this.loginService.getData() == undefined){

               console.log(localStorage.getItem('displayLoginName'));
              //this.loginService.setAccessList([]);
             this.loginService.setData({firstName:localStorage.getItem('firstName'),
               surname:localStorage.getItem('surname'),
               displayLoginName:localStorage.getItem('displayLoginName'),
               email:localStorage.getItem('email'),
             //  status:localStorage.getItem('status'),
              // roleId:localStorage.getItem('roleId'),
               uid:localStorage.getItem('uid')
              
               //roleId: localStorage.getItem('roleId')
          
             });
          
             this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
             this.loggedinUser=this.loginService.getData().uid;
             //this.role = this.loginService.getData().roleId;
           
            }else{
            
             this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
             this.loggedinUser=this.loginService.getData().uid;
             //this.role = this.loginService.getData().roleId;
           
            }
           
           // await this.setAccess();
          }
          getLogout() {
         //  this.ssologonservice.getLogout();
            localStorage.clear();
            this.cookieService.deleteAll();
            const clientId = this.clientId;
            window.location.href=this.logoutURL+"&client_id="+clientId;
          //  window.location.href="https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fssoaddonsext.test.det.nsw.edu.au%2Fssoaddon%2Flogout%2F%3F&client_id=6317501a-9651-453a-b336-d41b6612734a"
            // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
            // console.log('COOKIE' + this.cookieValue);
          }
        
          getlink()
          {
              if(localStorage.getItem('isextlanding')!=undefined && localStorage.getItem('isextlanding')!=null)
                return false;
              else
              return true;
          }

    }