import { Component, OnInit,ViewEncapsulation,ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { Route } from '@angular/compiler/src/core';
import { Router, UrlSegment } from '@angular/router';
import { eventservice } from '../../service/eventservice';
import { UserDetailsService } from '../../service/userDetails.service';
import { scheduleeventservice } from '../../service/scheduleeventservice';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../domain/user';
import { scheduleevent } from '../../domain/scheduleevent';
import { Visit} from '../../domain/visit';
import { hubs } from '../../domain/hubs';
import { hubservice } from '../../service/hubservice';
import { VisitService } from '../../service/visitservice';
import { Item, Period, Section, Events } from 'src/app/library/ngx-time-scheduler/src/lib/ngx-time-scheduler.model';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxTimeSchedulerService } from 'src/app/library/ngx-time-scheduler/src/lib/ngx-time-scheduler.service';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import { MessageService, SelectItem } from 'primeng/api';
import * as xlsx from "xlsx";



@Component({
  selector: 'app-hubuserevents',
  templateUrl: './hubuserevents.component.html',
  styleUrls: ['./hubuserevents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HubusereventsComponent implements OnInit {
  date3:Date=new Date();
  events: Events = new Events();
  periods: Period[];
  sections: Section[]=[];
  users:any[]=[];
  sec: any;
  item: Item[];
  officers: User[];
  officerstemp: User[];
  loginuserId: any;
  loginusername : any;
  selecteduserdetails:User;
  backupsections: Section[];
  alluserevents: scheduleevent[];
  userevents: scheduleevent[];
  usereventstemp: scheduleevent[];
  selectedEvent: any;
  selectedEvtVisit : Visit;
  uploading = false;
  eventdetailsModal:boolean;
  startdate: Date;
  enddate: Date;
  event_displayModal: boolean;
  eventOutput = '';
  items: Item[];
  itemUsers;
  currentPeriod: Period;
  start = moment().startOf('day');
  end = moment().endOf('day');
  clicked:any='mat-button-toggle-checked';
  headerFormat = 'Do MMM YYYY';
  locale = 'en-AU';
  exportExcelData: any=[];
  startdateexport:Date;
  enddateexport:Date;
  userdatatemp:any=[];
  months: SelectItem[];
  constructor(private hubservice:hubservice,public fb: FormBuilder,private visitservice:VisitService,private router: Router,private messageService: MessageService,private userdetailsservice :UserDetailsService,private http: HttpClient,private service: NgxTimeSchedulerService,private scheduleeventservice:scheduleeventservice) {
    this.events.SectionClickEvent = (section) => {
      this.eventOutput += '\n' + JSON.stringify(section);
      var secId=section.id.toString().substring(1);
      localStorage.setItem("USERID","USR"+secId);
      this.router.navigate(['/usercalendar']);
    };
    this.events.SectionContextMenuEvent = (section, {x, y}: MouseEvent) => {
      this.eventOutput += '\n' + JSON.stringify(section) + ',' + JSON.stringify({x, y});
    };
    this.events.ItemContextMenu = (item, {x, y}: MouseEvent) => {
      this.eventOutput += '\n' + JSON.stringify(item) + ',' + JSON.stringify({x, y});
    };
    this.events.ItemDropped = (item) => {
      this.eventOutput += '\n' + JSON.stringify(item);
    };
    this.events.PeriodChange = (start, end) => {
      this.eventOutput += '\n' + JSON.stringify(start) + ',' + JSON.stringify(end);
    };

    this.periods = [
      {
        name: '1 week',
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        timeFrameHeadersTooltip: ['MMM yyyy', 'dd(EEE)'],
        classes: 'mat-button-toggle-checked',
        timeFrameOverall: 1440 * 7,
        timeFramePeriod: 1440,
      },
      {
        name: '2 week',
        timeFramePeriod: 1440,
        timeFrameOverall: 1440*14,
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        classes: 'period-3day',
      }, {
        name: '1 month',
        timeFrameHeaders: ['MMM yyyy', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 43200,
        timeFramePeriod: 1440,
      }];
      this.sections = [
      ];

    this.itemUsers = [{
      id: 1,
      sectionID: 1,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      resource: 'Resource 1',
      supplier: 'Supplier 2',
      client: '',
      venue: '',
      classes: ''
    }, {
      id: 2,
      sectionID: 3,
      name: 'Item 2',
      start: moment().startOf('day').toDate(),
      end: moment().add(4, 'days').endOf('day').toDate(),
      classes: '',
      resource: '',
      supplier: 'Supplier 2',
      client: '',
      venue: 'Venue 1',
    }, {
      id: 3,
      sectionID: 1,
      name: 'Item 3',
      start: moment().add(1, 'days').startOf('day').toDate(),
      end: moment().add(3, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: '',
      venue: '',
    }, {
      id: 4,
      sectionID: 3,
      name: 'Item 4',
      start: moment().add(1, 'days').startOf('day').toDate(),
      end: moment().add(3, 'days').endOf('day').toDate(),
      classes: '',
      resource: '',
      supplier: 'Supplier 2',
      client: '',
      venue: 'Venue 2',
    }, {
      id: 5,
      sectionID: 1,
      name: 'Item 5',
      start: moment().add(7, 'days').startOf('day').toDate(),
      end: moment().add(8, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: 'Supplier 2',
      client: '',
      venue: '',
    }, {
      id: 6,
      sectionID: 1,
      name: 'Item 6',
      start: moment().subtract(3, 'days').startOf('day').toDate(),
      end: moment().subtract(1, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 3',
      supplier: '',
      client: '',
      venue: 'Venue 1',
    }, {
      id: 7,
      sectionID: 1,
      name: 'Item 7',
      start: moment().subtract(2, 'days').startOf('day').toDate(),
      end: moment().add(2, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 3',
      supplier: '',
      client: 'Client 1',
      venue: '',
    }, {
      id: 8,
      sectionID: 1,
      name: 'Item 8',
      start: moment().add(3, 'days').startOf('day').toDate(),
      end: moment().add(7, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: 'Client 3',
      venue: '',
    }, {
      id: 9,
      sectionID: 1,
      name: 'Item 9',
      start: moment().subtract(2, 'days').startOf('day').toDate(),
      end: moment().add(7, 'days').endOf('day').toDate(),
      classes: '',
      resource: 'Resource 1',
      supplier: '',
      client: '',
      venue: '',
    }];

    this.items = [{
      id: 1,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 1,
      sectionID: 100100,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    },{
      id: 2,
      sectionID: 100074600,
      name: 'Item 1',
      start: moment().startOf('day').toDate(),
      end: moment().add(5, 'days').endOf('day').toDate(),
      classes: ''
    }];
    this.currentPeriod = this.periods[0];
    this.resetEnd();
  }
  resetEnd() {
    this.end = moment(this.start).add(this.currentPeriod.timeFrameOverall, 'minutes').endOf('day');
  }

  ngOnInit(): void {

   
    
    this.loginuserId =localStorage.getItem("loginuserid");
    this.loginusername =localStorage.getItem("username");

    this.userdetailsservice.getUserforId(this.loginuserId).subscribe(userdata =>{   
      this.selecteduserdetails = userdata;
      console.log(JSON.stringify(this.selecteduserdetails));
      this.getuserdata();
    });
    this.item = [];
    this.sections = [];

    


    this.events.SectionClickEvent = (section) => {
      var secId=section.id.toString().substring(1);
      localStorage.setItem("USERID","USR"+secId);
      this.router.navigate(['/home']);      
      
    };
    // onclick event
    this.events.ItemClicked = (item) => {
      console.log(item);
      
      var itemId=item.id.toString().substring(1);
      console.log(itemId);
      
      var eventID="EVT"+itemId;
      this.visitservice.getEventsById(eventID).subscribe(sedata=>{
        if(sedata){
          this.eventdetailsModal=true;
          this.selectedEvent = sedata;
        }else{
          this.selectedEvent = item;
          this.event_displayModal=true;
        }
      })
        
    };
    

    this.events.ItemDropped = (item) => { console.log(item); };

    this.periods = [
     
       {
        name: '1 week',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 7,
        timeFramePeriod: 1440,
      }, {
        name: '2 week',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 14,
        timeFramePeriod: 1440,
      },{
        name: '1 Month',
        timeFrameHeaders: ['MMM YYYY', 'DD(ddd)'],
        classes: '',
        timeFrameOverall: 1440 * 30,
        timeFramePeriod: 1440,
      }
    ];
  }
  progressbar:boolean =false;
  listofevents:any[]=[];
  gotoDate() {
    this.start = moment(this.date3).startOf('day');
    this.resetEnd();
  }
  changePeriod(period: any) {
    this.currentPeriod = period;
    this.resetEnd();
  }

  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }
  displayeventdetails(){
    this.eventdetailsModal = true;
  }
  myhubId:"";
  getuserdata(){
  
 
      this.hubservice.getHubs().subscribe(hubData=>{
        if(hubData!=null){
          console.log("hubData....."+JSON.stringify(hubData))
         let myhub =  hubData.find((hub:any)=> hub.hubName== this.selecteduserdetails.region)
         console.log("myhub....."+JSON.stringify(myhub))
         this.myhubId = myhub.hubId 

         
        }
    
    this.userdetailsservice.getActiveUsersByRegions( this.myhubId ).subscribe(userData=>{
      if(userData!=null){
        this.officerstemp =userData;        
      
        let mylist = this.officerstemp.filter(el=>e=>(e.status=='Active' && (e.roleId=='ACO' || e.roleId=='SCHD_ACO_READONLY')) );    
        let romlist = mylist.filter(el=> (el.roleId=='ROM'|| el.roleId=='SCHD_ROM_READONLY')).sort((a,b)=>a.userFirstName < b.userFirstName ? -1:1)
      let acolist = mylist.filter(el=> (el.roleId=='ACO'|| el.roleId=='SCHD_ACO_READONLY')).sort((a,b)=>a.userFirstName < b.userFirstName ? -1:1)
       // console.log("romlist........................"+JSON.stringify(romlist))
      //  console.log("acolist........................"+JSON.stringify(acolist))
        mylist=romlist.concat(acolist);

        if(mylist!=undefined && mylist!=null && mylist.length!=0)
        {
          this.progressbar=true;
        }
       
        let mypromise = new Promise((resolve, reject) => { mylist.forEach((e,index,array)=>{
          let ele = {...e};
          delete ele.notes;
          this.exportExcelData.push(ele);
          let title=e.title;
          if(title == "SFORD" || title == "FORD" || title == "FSORD" || title === "HUBMRD" || title === "HUBCRD") 
          {
            title=title.replace("RD","")
          }
       //   this.sections.push({name:e.userFirstName+' '+e.userLastName+' - '+e.region,id: Number(e.userId.replace("USR", "1"))});           
          let varname = e.userFirstName+' '+e.userLastName+'-'+e.region +'\n'+ title ;
       //   console.log("e.userId"+e.userId +"........index.."+index)
          this.sections.push({name:varname,id: Number(e.userId.replace("USR", "1")),tooltip:e.userFirstName+' '+e.userLastName+'-'+e.region});
          this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{     
            if(ssedata!=null){
              this.userevents = ssedata;  
              let d = new Date();
              d.setMonth(d.getMonth() - 6);
  
           //   console.log("this.userevents size before"+ this.userevents.length + "date ="+d.getMonth())   
          
              this.userevents = this.userevents.filter(el=>((new Date(el.endDate)).getTime()>=d.getTime()))        
         //     console.log("this.userevents size"+ this.userevents.length +"..index.."+index+"...e.userId  "+e.userId)                                        
              this.userevents.forEach(eledata=>{
                
                if (eledata.startDate!=null){
                  var startDate = new Date(eledata.startDate);
                  var startOfDayDate = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,1);
                  var endDate = new Date(eledata.endDate);
                  var endOfDayDate = new Date(endDate.getFullYear(),endDate.getMonth(),endDate.getDate(),23,59,59);
                  var bgcolor=this.getbgcolor(eledata.eventType);
                  
                if(eledata.daysofweek!=null)
                {
                  const dates = this.getDates(new Date(eledata.startDateRecur), new Date(eledata.endDateRecur))
                  dates.forEach(date => {
                    let day  = ""+date.getDay();

               //   console.log("date..."+eledata.daysofweek.includes(day))
                  if(eledata.daysofweek.includes(day))
                  {
                    startOfDayDate = new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,1);
                    endOfDayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                 //   console.log("startOfDayDate"+startOfDayDate)
                   // console.log("endOfDayDate"+endOfDayDate)
                    if(startOfDayDate!=null && endOfDayDate!=null && startOfDayDate!=undefined && endOfDayDate!=undefined)
                    {
                       this.service.itemPush({
                      id: Number(eledata.eventId.replace("EVT", "1")),
                      sectionID: Number(e.userId.replace("USR", "1")),
                      name: eledata.title,
                      start: moment(startOfDayDate).toDate(),
                      end: moment(endOfDayDate).toDate(),
                      classes: '',
                      cstyles: this.getbgcolor(eledata.eventType),

                      tooltip:eledata.title + ' '+ environment.datePipe.transform(new Date(eledata.startDate), 'dd-MM-yyyy')+'/'+  environment.datePipe.transform(new Date(eledata.endDate), 'dd-MM-yyyy'),
                    });
                    }
  
                  }

                 
                })
               }
               else{
                  this.service.itemPush({
                    id: Number(eledata.eventId.replace("EVT", "1")),
                    sectionID: Number(e.userId.replace("USR", "1")),
                    name: eledata.title,
                    start: moment(startOfDayDate).toDate(),
                    end: moment(endOfDayDate).toDate(),
                    classes: '',
                    cstyles: this.getbgcolor(eledata.eventType),
                    tooltip:eledata.title + ' '+ environment.datePipe.transform(new Date(eledata.startDate), 'dd-MM-yyyy')+'/'+  environment.datePipe.transform(new Date(eledata.endDate), 'dd-MM-yyyy'),
                  });
                }
                
                  // this.item.push({id:Number(eledata.eventId.replace("EVT", "1")),sectionID:Number(e.userId.replace("USR", "1")),name:eledata.title,start: moment(new Date(startOfDayDate), 'YYYY-MM-DD HH:mm'),end:moment(new Date(endOfDayDate), 'YYYY-MM-DD HH:mm'),classes: bgcolor});  
                }

            //    console.log("array.length"+array.length +"index"+ index)

               

              }); 

              if(index==(array.length-1))
              {
             
                 resolve(null);
              }
            }
          },err=>{
            console.log(err)
            resolve(null);

          });
        })
        }); 

        mypromise.then(() => {
          this.progressbar=false;
          this.backupsections=this.sections;
          console.log('All done!');

          this.getAllusers();

      });
        
      }

    });
  });
}
  gotoToday() {
    this.start = moment().startOf('day');
    this.resetEnd();
  }
  nextPeriod() {
    this.start = moment(this.start).add(this.currentPeriod.timeFrameOverall, 'minutes').startOf('day');
    this.resetEnd();
  }
  preDisable=false
  previousPeriod() {
  //  this.start = moment(this.start).subtract(this.currentPeriod.timeFrameOverall, 'minutes');
  if(moment().diff(moment(this.start),'months')>=6)
  {
   
    this.preDisable=true;
  
  }
  else{
    this.start = moment(this.start).subtract(this.currentPeriod.timeFrameOverall, 'minutes');

  }
    this.resetEnd();
  }
  exportExcel() {
   
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.exportExcelData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "hub user events");
    });
}

loadCalendar(searchval:string)
{
 if(searchval.trim()=='')
 {
  this.sections=[...this.backupsections]
 }
 else
 {
 this.sections= this.sections.filter(el=>el.name.includes(searchval));
 
 }

}


saveAsExcelFile(buffer: any, fileName: string): void {
  import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}
getbgcolor(eventType: any) {
  /*
    leave and Flex #F03030
    Training and Conference - #3361FF
    Team meeting and Admin Day - #A569BD
    Report Writing and Desktop Audit - #ABEBC6
    Compliance and Investigation - #FCE32E
    Pre,Post approval and change of ownership - #C10AC3
    Travel - #F06630 
    Assessment and visit -  #1AA11A
    Secondment Visit - #EBEEEE
    Multiple -#9B9E9C
    others - #26C8F3
    School holidays watermark colorMultiple events - #D6F9F1
  */
  var bgcolor={'background-color':''};
  if (eventType == 'L' || eventType == 'F') {
    bgcolor = bgcolor = {'background-color':'red'};
  } else if (eventType == 'T' || eventType == 'CF') {
    bgcolor = bgcolor = {'background-color':'#6699CC'};
  } else if (eventType == 'M' || eventType == 'A') {
    bgcolor = bgcolor = {'background-color':'lightpurple'};
  } else if (eventType == 'D' || eventType == 'R') {
    bgcolor = bgcolor = {'background-color':'lightgreen'};
  } else if (eventType == 'COM' || eventType == 'I' || eventType == 'MAT') {
    bgcolor = bgcolor = {'background-color':'yellow'};
  } else if (eventType == 'PA' || eventType == 'PR' || eventType == 'CofO') {
    bgcolor = bgcolor = {'background-color':'purple'};
  } else if (eventType == 'H') {
    bgcolor = bgcolor = {'background-color':'#F06630'};
  } else if (eventType == 'V') {
    bgcolor = bgcolor = {'background-color':'green'};
  } else if (eventType == 'ME') {
    bgcolor = bgcolor = {'background-color':'gray'};
  } else if (eventType == 'O') {
    bgcolor = bgcolor = {'background-color':'lightblue'};
  } else if (eventType == 'SV') {
    bgcolor = {'background-color':'lightskyblue'};
  } else if (eventType == 'W') {
    bgcolor = {'background-color':'lightgray'};
  }else if(eventType == 'termHoliday'){
    bgcolor = {'background-color':'#D3D3D3'};
  }else if(eventType == 'publicHoliday'){ 
    bgcolor = {'background-color':'#ffcccb'};
  }else if(eventType == 'LP'){ 
    bgcolor = {'background-color':'#F4C2C2'};
  }else if(eventType == 'LWOP'){ 
    bgcolor = {'background-color':'#de7c7c'};
  }else {
    bgcolor = {'background-color':''};
  }
  return bgcolor;
}

getAllusers()
  {
    this.userdetailsservice.getActiveUsersByRegions(this.myhubId).subscribe(userData=>{
     
      //console.log("All users"+userData);
      
      // section = userData;
      userData=userData.filter(e=>(e.status=='Active'));
      userData=userData.sort( (a,b)=> a.userFirstName < b.userFirstName ? -1:1 );
    

     userData.forEach((e)=>{
        let offname = e.userFirstName+' '+e.userLastName
        let region = e.region
        let role = e.title?e.title.replace("RD",""):'';
       
        this.scheduleeventservice.getUserCalendarById(e.userId).subscribe(ssedata =>{
          if(ssedata!=null){
            this.alluserevents = ssedata; 
            this.alluserevents.forEach(ev=>{
              ev.name=offname
              ev.role=role
              ev.region=region
            })

            this.listofevents.push(  this.alluserevents ) 
          }
        })
      })

    })
  }
  getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }
  exportExcelforDates() {
    let eventsDetailsExcelarr: any[]=[];
  
     if(this.startdateexport ==null || this.startdateexport ==undefined || this.enddateexport==null || this.enddateexport==undefined)
     {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Start Date and End Date must be selected'});
      return;
   
     }
     if(this.enddateexport<this.startdateexport)
     {
      this.messageService.add({ severity: 'error', summary: 'error', detail: 'Start Date cannot be after the End Date'});
      return;
     }
     

     var myevents =  [].concat.apply([], this.listofevents);
     myevents = myevents.filter(el=>(new Date(el.startDate) >= this.startdateexport && new Date(el.endDate) <= this.enddateexport))
    
     for (let eve of myevents)
     {
      let evestartDate = environment.datePipe.transform(new Date(eve.startDate), 'dd-MM-yyyy');
     
      let eveendDate = environment.datePipe.transform(new Date(eve.endDate), 'dd-MM-yyyy');
      eventsDetailsExcelarr.push({'Name':eve.name,'Region':eve.region,'Role':eve.role,'Event Name':eve.eventName, 'Event Type':eve.eventType, 'Start Date':evestartDate,'End Date':eveendDate});
     }
  
    
     if(eventsDetailsExcelarr!=null && eventsDetailsExcelarr.length>0)
     {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(eventsDetailsExcelarr);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Events");
    });
   }else{
    this.messageService.add({ severity: 'error', summary: 'error', detail: ' No data found'});
        
   }
  }
}
