export class scheduleevent {
    eventId?:string;
    serviceId?:string;
    visitId?:string;
    title?:string;
    description?:string;
    eventType?:string;
    startDate?:any;
    endDate?:any;
    startDateRecur?:any;
    endDateRecur?:any;
    daysofweek?:string;
    userId?:string;
    emailSent?:string;
    emailSentToFirstOff?:string;
    emailSentToSecOff?:string;
    targetName?:any;
    editFlag?:string;
    secondUserId?:string;
    secondUserName?:string;
    changeReason?:string;
    travelType?:string;
    changeReasonText?:string;
    archived?:string;
    futureVisit?:string;
    meetingSent?:string;
    periodId?:string;
    perSerId?:string;
    createdBy?:string;
    createdDate?:Date;
    updatedBy?:string;
    updatedDate?:Date; 
    name?:string;
    region?:string;
    role?:string;
    comments?:string;
    commentsold?:string;
    review_flag?:string;
    markit?:boolean;
    providerId?:string;
    largeProviderName?:string;
    flexperiodChangeReason?:string;
    flexperiodChangeReasonText?:string;
}