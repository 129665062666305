import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';
import { serviceprovider } from '../domain/serviceprovider';

const serviceUrl =  '/api/serviceproviders';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

    
  baseUrl:any
  constructor(private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  }
  getAllSPDetails(): Observable<any> {
    return this.http.get<serviceprovider[]>(this.baseUrl+serviceUrl);
  }


  getSPDetail(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  createSPDetail(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  updateSPDetail(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }

}
