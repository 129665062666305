import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './AppConfigService';
import {events} from '../domain/events';
import { Observable } from 'rxjs';

const getUrl = '/api/scheduleevents';
@Injectable()
export class eventservice {
    baseUrl : any;
    constructor(private http: HttpClient,private config: AppConfigService) { 
      this.baseUrl = this.config.getConfig().baseUrl;
    }

    getEvents(): Observable<any> {
      return this.http.get<events[]>(this.baseUrl+getUrl);  
    }

}
